import React from "react"

type Props = {
    handleSortClick: (name: string, type_sort: string) => void
    name: string,
    type_sort: string,
    sort_column: string,
    children: React.ReactNode
}

const THSort: React.FC<Props> = ({handleSortClick, name, type_sort, sort_column, children}) => {
    return (
        <th className={(sort_column === name) ? 'sorting actived table-sort-'+ type_sort : 'sorting table-sort-desc'} key={name+"1"} onClick={() => handleSortClick(name, type_sort)}
        >{children}<span className="sort-icon ms-1">
            <i className="fas fa-angle-down"></i>
            <i className="fas fa-angle-up"></i>
        </span>
        </th>
    )
  }

export { THSort }
