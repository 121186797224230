import React from 'react';
import {MarketingModel} from "../../models/MarketingModel";
import {Link} from "react-router-dom";
import {MARKETING_UPDATE_ROUTE} from "../../constants/routes";

type Props = {
  data: MarketingModel[],
  handleClickAll: (e: any) => void,
  isCheckedAll: boolean,
  handleClick: (e: any) => void,
  isChecked: Array<number>,
  toggleStatus: (id: number, status: boolean) => void,
  onDelete: (id: number) => void,

};


const MarketingTable: React.FC<Props> = ({
                                           data,
                                           onDelete,
                                           handleClickAll,
                                           isCheckedAll,
                                           handleClick,
                                           isChecked,
                                           toggleStatus
                                         }) => {


  return (
    <div className='table-responsive'>
      <table className="table table-hover table-rounded table-striped border gy-7 gs-7">
        <thead>
        <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
          <th>
            <div className='form-check form-check-sm form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                type='checkbox'
                value='1'
                onChange={handleClickAll}
                checked={isCheckedAll}
              />
            </div>
          </th>
          <th>Name</th>
          <th>Send/Failed/Totals</th>
          <th>Voucher</th>
          <th>Date send SMS</th>
          <th>Description</th>
          <th>Status</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {data.length > 0 && data.map((item: MarketingModel, index: number) => {
          const isActive = item.status;
          let status =  {
            class: 'primary',
            label: 'Pending'
          }
          switch(isActive){
            case 1:
              status =  {
                class: 'warning',
                label: 'Processing'
              };
              break;
            case 2: 
              status =  {
                class: 'success',
                label: 'Done'
              };
              break;
            default:
              break;
          }
          return (
            <tr key={item.id}>
              <td>
                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                  <input
                    className='form-check-input widget-9-check'
                    type='checkbox'
                    value='1'
                    disabled={item?.status == 0 ? false : true}
                    id={(item.id).toString()}
                    onChange={handleClick}
                    checked={isChecked.includes(item.id)}
                  />
                </div>
              </td>
              <td>
                {item.campaign_name}
              </td>
              <td>
                Totals : {item.total} <br/>
                Send : {item.send} <br/>
                Failed : {item.retry_count}
              </td>
              <td>{item.coupon_code}</td>
              <td>{item.date_start_send}</td>
              <td>{item.message}</td>
              <td>
                {/* <span className={`fw-boldest cursor-pointer badge badge-${isActive ? 'primary' : 'warning'}`}
                        onClick={() => toggleStatus(item.id, !Boolean(item.status))}>{isActive ? 'Running' : 'Pending'}</span> */}
                <span className={`fw-boldest cursor-pointer badge badge-${status?.class}`}>
                 {status?.label}
                </span>
              </td>
              <td>
                <div className="d-flex">
                {!isActive ?
                <>
                  <Link to={MARKETING_UPDATE_ROUTE + '/' + item.id}
                        className="btn btn-icon btn-warning w-30px h-30px ps-2 me-2">
                    <i className="bi bi-pencil-square fs-4 me-2"></i>
                  </Link>
                  <button className="btn btn-icon btn-danger w-30px h-30px ps-2 me-2" onClick={() => onDelete(item.id)}>
                    <i className="bi bi-trash fs-4 me-2"></i>
                  </button>
                  </> : ''}
                </div>
              </td>
            </tr>
          )
        })
        }
        </tbody>
      </table>
    </div>
  );
}

export default MarketingTable;