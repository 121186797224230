import React, { FC } from 'react'
import _ from 'lodash'
import { ErrorMessage, Field} from 'formik'
//import * as Yup from 'yup'
import { Appointment } from '../../types/Appointment'
import { AsyncSelect2 } from '../../../../../_metronic/partials'
import { ENDPOINT_STAFFS } from '../../../../constants/endpoints'

type Props = {
    data?: Appointment
    services: Array<any>
    isActive: true | false,
    handleChange: (e: any) => void,
}

const AppointmentStep1: FC<Props> = ({data, isActive, services, handleChange }) => {
    return (
        <>
        {isActive  && (
            <div className='w-100 mb-10'>
                <div className='fv-row'>
                    <label className='d-flex align-items-center fs-3 fw-bolder mb-4'>
                        <span className=''>Services:</span>
                        <i
                            className='fas fa-exclamation-circle ms-2 fs-7'
                            data-bs-toggle='tooltip'
                            title='Select your services'
                        ></i>
                    </label>
                    <div className='fv-row'>
                    {services.map((service, index) => {
                        let sv = _.find(data?.services, function (e: any) {
                            return parseInt(e) === parseInt(service.id)
                        })
                        return (
                            <label className='d-flex flex-stack mb-5 cursor-pointer' key={index}>
                                <span className='d-flex align-items-center me-2'>
                                    <span className='d-flex flex-column'>
                                        <span className='fw-bold fs-4'>{service.label}</span>
                                    </span>
                                </span>
                                <span className='form-check form-check-custom form-check-solid'>
                                    <Field
                                        className='form-check-input'
                                        type='checkbox'
                                        name='services'
                                        checked={(sv) ? true : false}
                                        value={service.id}
                                    />
                                </span>
                            </label>
                        )
                    })}
                    </div>
                    <div className='fv-plugins-message-container invalid-feedback'>
                        <ErrorMessage name='services' />
                    </div>
                </div>
                <div className='fv-row mb-0'>
                    <label className='required fs-3  fw-bolder mb-2'>Enter Technician's name:</label>
                    <Field
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        name='staff'
                        placeholder=''
                    />
                    <div className='fv-plugins-message-container invalid-feedback'>
                        <ErrorMessage name='staff' />
                    </div>
                </div>
                <div className="d-flex fv-row">
                    <div className="form-check form-check-custom form-check-solid">
                        <Field
                            className='form-check-input me-3'
                            type='radio'
                            name='staff'
                            id='anyone-ckb'
                            value='Anyone'
                        />
                        <label className="form-check-label" htmlFor="anyone-ckb">
                            <div className="fw-bolder fs-4">Anyone</div>
                        </label>
                    </div>
                </div>
            </div>
        )}
        </>
    )
}

export default AppointmentStep1
