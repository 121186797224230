/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import BookingTable from './BookingTable';
import { Paginate, PaginationLength, DATA_PAGINATION_LENGTH, DatePicker } from '../../../_metronic/partials'
import { PaginateModel } from '../../models/PaginateModel';
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { BookingModel, SearchModel, ModalModel } from '../../models/BookingModel';
import * as bookingCRUD from './BookingCRUD';
import Swal from 'sweetalert2'
import { Toast } from '../../modules/common/helper'
import { bookingStatus, CONFIRMED_SATUS_CODE } from '../../constants/commons';
import BookingModal from './BookingModal';

const Booking: React.FC = () => {
    const history = useHistory();

    const [dataPaginate, setDataPaginate] = useState<PaginateModel<BookingModel>>();
    const [validDate, setValidDate] = useState<string>();
    const [bookingModal, setModal] = useState<ModalModel>({
        show: false,
        mode: 'add',
    });

    const [search, setSearch] = useState<SearchModel>({
        page: 1,
        per_page: DATA_PAGINATION_LENGTH[0],
    })

    useEffect(() => {
        const initParams: SearchModel = parseParams(queryString.parse(history.location.search))
        getData({ ...search, ...initParams });
    }, []);

    const parseParams = (params: SearchModel) => {
        if (params.page) {
            params.page = +params.page
        }
        if (params.per_page) {
            params.per_page = +params.per_page
        }
        return params
    }

    const getData = async (params: SearchModel) => {
        const { data: dataPaginate } = await bookingCRUD.getBookings(params);
        const objQuery = { ...params }
        if (objQuery.page === 1) {
            delete objQuery.page
        }
        if (objQuery.per_page === DATA_PAGINATION_LENGTH[0]) {
            delete objQuery.per_page
        }
        history.push({ search: `?${queryString.stringify(objQuery)}` })
        setDataPaginate(dataPaginate);
        setSearch(params);
    }

    const handleSearch = () => {
        getData({...search, page: 1})
    }

    const handlePageChange = (data: any) => {
        getData({ ...search, page: data.selected + 1 })
    }

    const handlePageLengthChange = (e: any) => {
        getData({ ...search, page: 1, per_page: +e.target.value })
    }

    const handleEdit = (item: BookingModel) => {
        setModal({
            show: true,
            mode: 'edit',
            data: item
        });
    }

    const handleDelete = (item: BookingModel) => {
        Swal.fire({
            title: 'Do you want to delete this booking?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Delete',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await bookingCRUD.destroy(item.id);
                Toast.fire({
                    icon: 'success',
                    title: 'Success'
                })
                setModal({ ...bookingModal, show: false })
                getData({ ...search, page: 1 })
            }
        })
    }

    const handleConfirm = (item: BookingModel) => {
        let result = bookingCRUD.update({ ...item, status: CONFIRMED_SATUS_CODE, is_comfirming: true });
        result.then(res => {
            if (res.data.status) {
                Toast.fire({
                    icon: 'success',
                    title: 'Success'
                })
                getData({ ...search, page: 1 })
            } else if (res.data.valid_date) {
                Toast.fire({
                    icon: 'error',
                    title: res.data.message
                })
            }
        }).catch(error => {
            const response = error.response
            let message: string = '';
            if (response?.status === 422) {
                if (response?.data?.errors) {
                    const arrMess = [];
                    for (const [key, mess] of Object.entries(response?.data?.errors)) {
                        arrMess.push(mess);
                    }
                    message = arrMess.join("\n")
                }
            } else {
                message = 'Server error'
            }
            Toast.fire({
                icon: 'error',
                title: message
            })
        });
    }

    const handleSubmit = async (values: any, actions: any) => {
        let result;
        if (bookingModal.mode === 'edit') {
            result = bookingCRUD.update(values);
        } else {
            result = bookingCRUD.store(values);
        }
        result.then(res => {
            if (res.data.status) {
                Toast.fire({
                    icon: 'success',
                    title: 'Success'
                })
                setModal({ ...bookingModal, show: false })
                getData({ ...search, page: 1 })
            } else {
                if (res.data.valid_date) {
                    setValidDate(res.data.valid_date);
                }
                Toast.fire({
                    icon: 'error',
                    title: 'Server error'
                })
            }
        }).catch(error => {
            const response = error.response
            let message: string = '';
            if (response?.status === 422) {
                if (response?.data?.errors) {
                    const arrMess = [];
                    for (const [key, mess] of Object.entries(response?.data?.errors)) {
                        arrMess.push(mess);
                    }
                    message = arrMess.join("\n")
                }
            } else {
                message = 'Server error'
            }
            Toast.fire({
                icon: 'error',
                title: message
            })
        });
    };

    const handleExport = async (event: any) => {
        const dataSearch = {...search}
        if (dataSearch.page === 1) {
          delete dataSearch.page
        }
        if (dataSearch.per_page === DATA_PAGINATION_LENGTH[0]) {
          delete dataSearch.per_page
        }
        bookingCRUD.exportCSV(dataSearch)
    }

    return (
        <>
            <div className="card">
                <div className="card-header py-4">
                    <div className="col-md-12 d-flex justify-content-start">
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md-3">
                                    <label className="form-label">Keyword</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        name="keyword"
                                        autoComplete='nope'
                                        value={search.keyword || ''}
                                        onChange={(e) => setSearch({...search, keyword: e.target.value})}
                                        />
                                </div>
                                <div className="col-md-3">
                                    <label className="form-label">Date</label>
                                    <DatePicker
                                        value={search.booking_date || ''}
                                        onChange={(date) => setSearch({...search, booking_date: date})}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <label className="form-label">Status</label>
                                    <select
                                        className="form-select"
                                        name="status"
                                        value={search.status || ''}
                                        onChange={(e) => setSearch({...search, status: e.target.value})}
                                    >
                                        <option value="all">All</option>
                                        {Object.keys(bookingStatus).map(function(key, index) {
                                            return <option key={"status"+key} value={key}>{bookingStatus[key]}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="col-md-3">
                                    <button
                                        className="btn btn-primary mt-8"
                                        onClick={handleSearch}
                                    >
                                        <i className="bi bi bi-search fs-4"></i> Search
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4  d-flex justify-content-end'>
                            <div
                                className="btn btn-warning mt-8 mx-2"
                                onClick={handleExport}
                            >
                                <i className="bi bi bi-cloud-arrow-down-fill fs-4"></i><span>CSV Export</span>
                            </div>
                            <button
                                className="btn btn-success mt-8"
                                onClick={() => {
                                    setModal({
                                        show: true,
                                        mode: 'add'
                                    })
                                }}
                            >
                                <i className="bi bi-plus-lg"></i> Add New
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col">
                            <BookingTable
                                data={dataPaginate?.data || []}
                                onConfirm={handleConfirm}
                                onEdit={handleEdit}
                                onDelete={handleDelete}
                            />
                        </div>
                    </div>
                </div>
                <div className="card-footer d-flex justify-content-between">
                    <PaginationLength selected={search.per_page || DATA_PAGINATION_LENGTH[0]} onChange={handlePageLengthChange} />
                    <Paginate
                        pageCount={dataPaginate?.meta?.last_page || 0}
                        onPageChange={handlePageChange}
                        forcePage={(search.page || 1) - 1}
                    />
                </div>
            </div>
            <BookingModal
                {...bookingModal}
                validDate={validDate}
                onClose={() => { setModal({ ...bookingModal, show: false }) }}
                onSubmit={handleSubmit}
            />
        </>
    )
}

export { Booking }
