import React, { FC, useState } from 'react'
import Background from './Background';
import Taskboard from './Taskboard';
import TopNavigation from './components/TopNavigation';
import _ from 'lodash'
import './asset/home.scss';
import "react-sliding-pane/dist/react-sliding-pane.css";
import MakeAppointment from './components/MakeAppointment';
const HomeScreen: FC = () => {
    return (
        <>
            <Background /> 
            <div className='home-page content'>
                <TopNavigation />
                <div className="Mui-taskbar">
                    <Taskboard /> 
                </div>
                <MakeAppointment/>
            </div>
        </>
    )
}

export default HomeScreen
