import React, { FC,useState } from 'react';
import AboutUs from './components/AboutUs';
import Service from './components/Service';
import Gallery from './components/Gallery';
import Infomation from './components/Infomation';
import Facebook from './components/Facebook';
import Instagram from './components/Instagram';
import Gplus from './components/Gplus';


const Taskboard: FC = () => {
    
  return (
    <>
    <div><Infomation /></div>
    <div>
        <AboutUs />
        <Service />
        <Gallery />
        {/* <Facebook />
        <Instagram /> */}
    </div>
    <div><Gplus /></div>
    </>
  );
};


export default Taskboard;
