import queryString  from 'query-string';
import axios from 'axios'
import { PaginateModel } from '../../models/PaginateModel';
import { BookingModel, SearchModel } from '../../models/BookingModel';
import { ResponseModel } from '../../models/ResponseModel';
import { ADMIN_ENDPOINT_BOOKINGS } from '../../constants/endpoints';

export function getBookings(search: SearchModel) {
  return axios.get<PaginateModel<BookingModel>>(`${ADMIN_ENDPOINT_BOOKINGS}?${queryString.stringify(search, {arrayFormat: 'bracket'})}`)
}

export function store(booking: BookingModel) {
  return axios.post<ResponseModel>(ADMIN_ENDPOINT_BOOKINGS, booking);
}

export function update(booking: BookingModel) {
  return axios.put<ResponseModel>(ADMIN_ENDPOINT_BOOKINGS, booking);
}

export function destroy(id: number) {
  return axios.delete<ResponseModel>(`${ADMIN_ENDPOINT_BOOKINGS}/${id}`);
}

export function exportCSV(search: SearchModel) {
  return axios.get(`${ADMIN_ENDPOINT_BOOKINGS}/export-csv?${queryString.stringify(search, { arrayFormat: 'bracket' })}`, { headers: { "Content-Type": "application/vnd.ms-excel" }, responseType: 'blob' })
      .then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `booking.xlsx`);
          document.body.appendChild(link);
          link.click();
      });
}