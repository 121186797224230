import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {Checkin} from './Checkin'

const CheckinWrapper: FC = () => {
  const breadcrumbs = [
    {
      title: 'Dashboard',
      path: '/',
      isActive: true,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs} description={'aaaa'}>Customer Check-In List</PageTitle>
      <Checkin />
    </>
  )
}

export default CheckinWrapper
