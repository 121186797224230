import React, { FC } from 'react'
import _ from 'lodash'
//import { ErrorMessage, Field, Form, Formik } from 'formik'
//import * as Yup from 'yup'
import { Appointment } from '../../types/Appointment'
//import Flatpickr from 'react-flatpickr'
//import moment from 'moment'

type Props = {
    data?: Appointment
    services:  Array<any>
    isActive: boolean
}

const AppointmentStep4: FC<Props> = ({data , isActive, services}) => {
    return (
        <>
        { isActive && (
            <div className='w-100 mb-10'>
                <div className='fv-row'>
                    <div className="form-group">
                        <label className='d-flex align-items-center fs-3 fw-bolder mb-0 mt-2'>
                            <span className=''>Your info:</span>
                        </label>
                        <span className='fs-4 fw-normal '>Name: { data?.name}</span><br/>
                        <span className='fs-4 fw-normal '>Phone: { data?.phone}</span>
                    </div>
                    <div className="form-group">
                        <label className='d-flex align-items-center fs-3 fw-bolder mb-0 mt-2'>
                            <span className=''>Selected services:</span>
                        </label>
                        {data?.services.map((sv, index) => {
                            let sltedSv = _.find(services, function (e) {
                                return e.id == sv
                            })
                            return (
                                <>
                                    <span className='fs-4 fw-normal '>{ sltedSv.label }</span><br/>
                                </>
                            )
                        })}
                    </div>
                    <div className="form-group">
                        <label className='d-flex align-items-center fs-3 fw-bolder mb-0 mt-2'>
                            <span className=''>Staff:</span>
                        </label>
                        <span className='fs-4 fw-normal '>{ data?.staff?.label ?? data?.staff}</span>
                    </div>
                    <div className="form-group">
                        <label className='d-flex align-items-center fs-3 fw-bolder mb-0 mt-2'>
                            <span className=''>Booking date:</span>
                        </label>
                        {data?.date && data?.time && (
                            <span className='fs-4 fw-normal '>{data?.date + ' ' + data?.time}</span>
                        )}
                    </div>
                    <div className="form-group">
                        <label className='d-flex align-items-center fs-3 fw-bolder mb-0 mt-2'>
                            <span className=''>Note:</span>
                        </label>
                        <span className='fs-4 fw-normal '>{ data?.note }</span><br/>
                    </div>
                </div>
            </div>
        )}
        </>
    )
}

export default AppointmentStep4
