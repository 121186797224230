import React, {useState, useEffect} from 'react'
import queryString from 'query-string'
import {DATA_PAGINATION_LENGTH, Paginate, PaginationLength} from '../../../_metronic/partials';
import MarketingTable from './MarketingTable';
import {Link, useHistory} from 'react-router-dom';
import {MarketingModel, SearchModel} from '../../models/MarketingModel';
import {PaginateModel} from '../../models/PaginateModel';
import * as marketingCRUD from "../marketing-management/MarketingCRUD";
import {Toast} from "../../modules/common/helper";
import _ from "lodash";
import Swal from "sweetalert2";
import {MARKETING_CREATE_ROUTE} from "../../constants/routes";


const Marketing: React.FC = () => {
  const history = useHistory();
  const [dataPaginate, setDataPaginate] = useState<PaginateModel<MarketingModel>>();

  const [search, setSearch] = useState<SearchModel>({
    page: 1,
    per_page: DATA_PAGINATION_LENGTH[0],
  })
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState<Array<number>>([]);

  useEffect(() => {
    const initParams: SearchModel = parseParams(queryString.parse(history.location.search))
    getData({...search, ...initParams});
  }, []);

  const parseParams = (params: SearchModel) => {
    if (params.page) {
      params.page = +params.page
    }
    if (params.per_page) {
      params.per_page = +params.per_page
    }
    return params
  }

  const getData = async (params: SearchModel) => {
    const {data: dataPaginate} = await marketingCRUD.getMarketingList(params);
    const objQuery = {...params}
    if (objQuery.page === 1) {
      delete objQuery.page
    }
    if (objQuery.per_page === DATA_PAGINATION_LENGTH[0]) {
      delete objQuery.per_page
    }
    history.push({search: `?${queryString.stringify(objQuery)}`})
    setDataPaginate(dataPaginate);
    setSearch(params);
  }

  const handlePageChange = (data: any) => {
    getData({...search, page: data.selected + 1})
  }

  const handlePageLengthChange = (e: any) => {
    getData({...search, page: 1, per_page: +e.target.value})
  }

  const handleCatch = (error: any) => {
    const response = error.response
    let message: string = '';
    if (response?.status === 422) {
      if (response?.data?.errors) {
        const arrMess = [];
        for (const [key, mess] of Object.entries(response?.data?.errors)) {
          arrMess.push(mess);
        }
        message = arrMess.join("\n")
      }
    } else {
      message = 'Server error'
    }
    Toast.fire({
      icon: 'error',
      title: message
    })
  }

  const handleDeleteAll = () => {
    if (!isCheck.length) {
      Toast.fire({
        icon: 'error',
        title: 'No item delete'
      })
      return;
    }
    Swal.fire({
      title: 'Do you want to delete all selected campaign?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.isConfirmed) {
        await marketingCRUD.destroy(isCheck);
        Toast.fire({
          icon: 'success',
          title: 'Success'
        })
        setIsCheckAll(false);
        getData({...search, page: 1})
      }
    });
  }

  const handleSelectAll = (e: any) => {
    let data: Array<MarketingModel> = dataPaginate?.data || [];
    setIsCheckAll(!isCheckAll);
    let dataCheck: Array<number> = data.filter((data) => data.status == 0).map(item => item.id);
    setIsCheck(dataCheck);
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e: any) => {
    let {id, checked} = e.target;
    id = parseInt(id);
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== id));
    }
  };

  const handleDelete = (id: number) => {
    Swal.fire({
      title: 'Do you want to delete this campaign?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.isConfirmed) {
        await marketingCRUD.destroy([id]);
        Toast.fire({
          icon: 'success',
          title: 'Success'
        })
        setIsCheck(isCheck.filter(itemChecked => itemChecked !== id));
        getData({...search, page: 1})
      }
    })
  }

  const toggleStatus = async (id: number, status: boolean) => {
    try {
      let res: any = await marketingCRUD.updateStatus({id, status});
      if (res.data.status) {
        Toast.fire({
          icon: 'success',
          title: 'Success'
        })
        getData({...search, page: 1})
      } else {
        Toast.fire({
          icon: 'error',
          title: 'Server error'
        })
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-header py-4">
          <div className="col-md-12 d-flex justify-content-start">
            <div className="col-md-6">
            </div>
            <div className='col-md-6 d-flex justify-content-end'>
              {!_.isEmpty(isCheck) &&
                <button
                  className="btn mt-8 btn-danger me-3"
                  onClick={handleDeleteAll}
                >
                  <i className="bi bi-trash fs-4"></i> Delete All
                </button>
              }

              <Link
                to={MARKETING_CREATE_ROUTE}
                className="btn btn-success mt-8"
              >
                <i className="bi bi-plus-lg"></i> Lên Chương Trình Chạy Quảng Cáo
              </Link>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col">
              <MarketingTable
                data={dataPaginate?.data || []}
                handleClickAll={handleSelectAll}
                isCheckedAll={isCheckAll}
                handleClick={handleClick}
                isChecked={isCheck}
                toggleStatus={toggleStatus}
                onDelete={handleDelete}
              />
            </div>
          </div>
        </div>
        <div className="card-footer d-flex justify-content-between">
          <PaginationLength selected={search.per_page || DATA_PAGINATION_LENGTH[0]} onChange={handlePageLengthChange}/>
          <Paginate
            pageCount={dataPaginate?.meta?.last_page || 0}
            onPageChange={handlePageChange}
            forcePage={(search.page || 1) - 1}
          />
        </div>
      </div>
    </>
  )
}

export {Marketing}
