/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, {FC} from 'react'
import {KTSVG,toAbsoluteUrl} from '../../../helpers'
import { HeaderUserMenu } from '../../../partials'
import {useLayout} from '../../core'
import {DefaultTitle} from '../header/page-title/DefaultTitle'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarToggle = 'w-40px h-40px w-md-60px h-md-60px pe-2 pe-md-4'

const Toolbar1: FC = () => {
  const {config, classes} = useLayout()
  const {header} = config

  return (
    <div className='toolbar' id='kt_toolbar'>
      {/* begin::Container */}
      <div
        id='kt_toolbar_container'
        className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack justify-content-end ')}
      >
        <DefaultTitle />

        

      {/* begin::Aside Toggler */}
      {header.left === 'menu' && (
        <div className='d-flex align-items-center d-block d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-40px h-40px w-md-50px h-md-50px'
            id='kt_aside_mobile_toggle'
            title="Show Menu"
          >
            <KTSVG
            path='/media/icons/duotune/layouts/lay002.svg'
            svgClassName={toolbarToggle}

            
          />

          
          </div>
        </div>
      )}

      {/* end::Aside Toggler */}

        {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <img src={toAbsoluteUrl('/media/avatars/150-2.jpg')} alt='metronic' />
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      </div>
      {/* end::Container */}
    </div>
  )
}

export {Toolbar1}
