import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import { Customer } from './Customer'

const CustomerWrapper: FC = () => {
  const breadcrumbs = [
    {
      title: 'Dashboard',
      path: '/',
      isActive: true,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs} description={'Customer'}>Customer Management</PageTitle>
      <Customer />
    </>
  )
}

export default CustomerWrapper
