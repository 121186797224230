import React, { FC, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../setup';
import * as homeRedux from '../HomeRedux'
import { Modal } from 'react-bootstrap-v5'
import * as Yup from 'yup'
import { Form, Formik, } from 'formik';
import "formik-stepper/dist/style.css";
import * as homeCRUD from '../HomeCRUD'

import moment from "moment";
import { Appointment } from '../types/Appointment';
import AppointmentStep1 from './MakeAppointmentSteps/AppointmentStep1';
import AppointmentStep2 from './MakeAppointmentSteps/AppointmentStep2';
import AppointmentStep3 from './MakeAppointmentSteps/AppointmentStep3';
import AppointmentStep4 from './MakeAppointmentSteps/AppointmentStep4';
import { Toast } from '../../common/helper';
import AppointmentStep5 from './MakeAppointmentSteps/AppointmentStep5';
const appointmentSchema = [
    Yup.object({
        staff: Yup.mixed().required().label('Staff'),
    }),
    Yup.object({
        date: Yup.date().required().label('Date').min(
            moment().format('YYYY-MM-DD'),
            "Date can't be before today"
        ),
        time: Yup.string().required().label('Time'),
    }),
    Yup.object({
        name: Yup.string().required().label('Name'),
        phone: Yup.number().required().label('Phone number'),
    })
]



const inits = {
    services: [],
    staff: "",
    date: "",
    name: "",
    phone: "",
    note: "",
    time: ""
}

const MakeAppointment: FC = (props) => {
  
    const [initValues] = useState<Appointment>(inits)
    const dispatch = useDispatch()
    const paneOpened = useSelector<RootState>(({ home }) => home.pane_name, shallowEqual)
    const [step, setStep] = useState(1);
    const prevStep = () => setStep(step => (step <= 1 ?step : step- 1));
    const nextStep = () => setStep(step => (step === 4 ? 4 : step + 1));
    const openPane = (name: string | null) => {
        if (paneOpened === name) {
            name = null;
        }
        dispatch(homeRedux.actions.changedPane(name));
    }

    const onSubmit = async (values: any) => {
        if (step < 4) {
            setStep(step => (step + 1));
        } else {
            let bDate =  values.date + ' ' + values.time;
            let result = homeCRUD.makeAppointMent({...values, date: bDate, platform: 'frontend'});
            result.then(res => {
                if(res?.data['message'] === 'Invalid date') {
                    Toast.fire({
                        icon: 'error',
                        title: 'Invalid date'
                    })
                } else {
                    setStep(5)
                }
            }).catch(error => {
                const response = error.response
                let message: string = '';
                if (response?.status === 422) {
                    if (response?.data?.errors) {
                        const arrMess = [];
                        for (const [key, mess] of Object.entries(response?.data?.errors)) {
                            arrMess.push(mess);
                        }
                        message = arrMess.join("\n")
                    }
                } else {
                    message = 'Server error'
                }
                Toast.fire({
                    icon: 'error',
                    title: message
                })
            });
        }
        
    };

	const nowTime = moment().valueOf();
	const morningPeriod = ['10:00','10:30','11:00','11:30','12:00'];
	const afPeriod      = ['12:30','13:00','13:30','14:00','14:30','15:00','15:30','16:00','16:30','17:00','17:30','18:00','18:30','19:00'];
    
    const services = [
        {
            id: 1,
            label: 'Pedicure'
        },
        {
            id: 2,
            label: 'Manicure'
        },
        {
            id: 3,
            label: 'Waxing'
        },
        {
            id: 4,
            label: 'Eyelashes'
        }
    ]
    return (
        <>
            <Modal
                id='kt_modal_create_booking'
                className="text-light"
                tabIndex={-1}
                aria-hidden='true'
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={paneOpened === 'booking'}
                backdrop="static"
                onHide={() => {
                    openPane(null);
                    setStep(1)
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter"  className="text-light">
                        {step < 5 ?"Make an appointment":"We received your booking request"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Formik validationSchema={appointmentSchema[step -1]} initialValues={initValues} onSubmit={onSubmit}>
                {({ values, handleChange }) => (
                    
                    <Form>
                        {console.log(values)}
                        <AppointmentStep1
                            data={values}
                            isActive={step === 1}
                            services={services}
                            handleChange={handleChange}
                        />
                        <AppointmentStep2
                            data={values}
                            morningPeriod={morningPeriod}
                            nowTime={nowTime}
                            afPeriod={afPeriod}
                            isActive={step === 2}
                            handleChange={handleChange}
                        />
                        <AppointmentStep3
                            data={values}
                            isActive={step === 3}
                        />
                        <AppointmentStep4
                            data={values}
                            services={services}
                            isActive={step === 4}
                        />

                        <AppointmentStep5
                            isActive={step === 5}
                        />
                        {(step < 5) && (
                            <div className='d-flex mb-4 justify-content-between'>   
                                <button onClick={prevStep} className="btn btn-bg-warning" type="button">Back</button>
                                {(step === 4) ? (
                                    <button className="btn btn-bg-success ms-auto" type="submit">Submit</button>
                                ) : (
                                    <button className="btn btn-bg-success ms-auto" type="submit">Next</button>
                                )}
                            </div>
                        )}
                        
                    </Form>
                )}
                </Formik>
                </Modal.Body>

            </Modal>

            <Modal
                id='kt_modal_promotion'
                className="text-dark"
                tabIndex={-1}
                aria-hidden='true'
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={false}
                // show={paneOpened === 'promotion'}
                backdrop="static"
                onHide={() => {
                    openPane(null);
                    setStep(1)
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter"  className="text-dark">
                        Today Sales promotion for you
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="fs-3  " style={{overflow: "auto", height: 'calc(17vh - 30px)'}}>
                        <h1 className='text-xl text-danger'>Purchase $45 get $50 Gift Card <br />or $90 get $100</h1>
                        {/* <ol>
                            <li>“Free Gift with Purchase” promotion&nbsp;</li>
                            <li>Pre-launch promotion campaign</li>
                            <li>Branded bundle offer</li>
                            <li>Discount for new customers</li>
                            <li>End-of-season sale</li>
                            <li>Limited-time promo offer</li>
                            <li>Clearance sale</li>
                            <li>Newsletter signup discount</li>
                            <li>Prized promotion (Spin the wheel)</li>
                            <li>Spin-the-wheel promotion</li>
                            <li>Limited-time sale</li>
                            <li>Holiday sale promotion (4th of July)</li>
                            <li>Gift card promotional deal</li>
                            <li>Flash sale</li>
                            <li>“Buy One, Get One Free” Sale&nbsp;</li>
                            <li>Limited-time “Buy Two Get Two” sale</li>
                            <li>Limited-time free shipping promotion</li>
                            <li>Membership reward promotion</li>
                            <li>Birthday sale</li>
                            <li>Sitewide sale</li>
                            <li>Free sample</li>
                        </ol> */}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default MakeAppointment


