import React from 'react';
import { BookingModel } from '../../models/BookingModel';
import { bookingStatus, NEW_SATUS_CODE } from '../../constants/commons';
import moment from 'moment';
import _ from 'lodash';

type Props = {
    data: Array<BookingModel>,
    onCheckOut: (booking: BookingModel) => void,
    onViewRating: (booking: BookingModel) => void,
    onEdit: (booking: BookingModel) => void,
};


const CheckinsTable: React.FC<Props> = ({ data, onEdit, onViewRating, onCheckOut}) => {
    const checkInBtn = (item: BookingModel) => {
        if (_.isEmpty(item.checkin)) {
            return (
                <button
                    className="btn btn-primary waiting-btn"
                    onClick={() => onEdit(item)}
                >
                    Waiting
                </button>
            )
        } else if (!_.isEmpty(item.checkin) && _.isEmpty(item.checkout)) {
            return (
                <button
                    className="btn btn-primary checkout-btn waiting-btn"
                    onClick={() => onCheckOut(item)}
                >
                    Checkout
                </button>
            )
        } else {
            <button
                className="btn btn-primary checkedout-btn waiting-btn"
            >
                <label>OUT</label> {item.checkout}
            </button>
        }
        
    }

    const renderCustomerType = (type: string) => {
        if (type) {
            let typeClass = "success";
            type = type.toLowerCase();
            switch (type){
                case 'new':
                    typeClass = "success"
                    break;
                case 'silver': 
                    typeClass = "primary"
                    break;
                case 'gold': 
                    typeClass = "info"
                    break;
                case 'platinum': 
                    typeClass = "warning"
                    break;
                case 'vip': 
                    typeClass = "danger"
                    break;
                default:
                    typeClass = "success"
                    
            }
            return <span className={"badge badge-light-" + typeClass}>{type}</span>
        }
    }
    return (
        <div className='table-responsive'>
            <table className="table table-hover table-rounded table-striped border gy-7 gs-7">
                <thead>
                    <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                        <th style={{minWidth: '150px'}} className="pl-7">
                            <span className="text-dark-75">Name</span>
                        </th>
                        <th style={{minWidth: '100px'}}>Check-in<br /> Time</th>
                        <th style={{minWidth: '70px'}}>Visit<br/> Count</th>
                        <th style={{minWidth: '100px'}}>Last<br />Visit</th>
                        <th style={{minWidth: '100px'}}>Current<br />Points</th>
                        <th style={{minWidth: '150px',textAlign: 'center'}}>rating</th>
                        <th style={{minWidth: '80px'}}></th>
                    </tr>
                </thead>
                <tbody>
                    {data.length > 0 && data.map((item: BookingModel, index: number) => (
                        <tr key={item.id}>
                            <td>
                                <div className="d-flex align-items-center">
                                    <div>
                                        <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{item.customer?.name} {renderCustomerType(item.customer?.type)}</a>
                                        <span className="text-muted font-weight-bold d-block"><i className="fas fa-phone-alt"></i>&nbsp;{item.customer?.phone}</span>
                                        <span className="text-muted font-weight-bold d-block"><i className="fas fa-envelope"></i>&nbsp;{item.customer?.email}</span>
                                    </div>
                                </div>
                            </td>
                            <td>{(_.isEmpty(item.checkin)) ? moment(item.date).format('MM/DD/YYYY h:m A') : moment(item.checkin).format('MM/DD/YYYY h:m A')}</td>
                            <td>
                                {item.customer?.visit_count}
                            </td>
                            <td>{(item.customer && !_.isEmpty(item.customer?.lastvisit)) ? moment(item.customer.lastvisit).format('MM/DD/YYYY h:m A') : ''}</td>
                            <td>{item.customer?.points ?? 0}</td>
                            <td>
                                <div 
                                    className='rating justify-content-center' 
                                    onClick={() => onViewRating(item)}>
                                    {[...Array(5)].map((star, index) => {        
                                        index += 1;
                                        return (         
                                            <div 
                                                key={index} 
                                                className={index <= item.rating_stars ? "rating-label me-2 checked" : "rating-label me-2"}
                                            >
                                                <i className='bi bi-star-fill fs-4'></i>
                                            </div> 
                                        );
                                    })}
                                </div>
                            </td>
                            <td>
                                <div className='checkin-btns'>
                                    {checkInBtn(item)}
                                </div>
                                
                            </td>
                        </tr>
                    ))
                    }
                </tbody>
            </table>
        </div>
    );
}

export default CheckinsTable;