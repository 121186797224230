/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Modal } from 'react-bootstrap-v5'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import Flatpickr from 'react-flatpickr'
import moment from 'moment';
import { CouponModel } from '../../models/CouponModel'
import { constants } from 'os'
import { BookingModel } from '../../models/BookingModel'

type Props = {
    show: boolean
    mode: string
    data?: BookingModel
    onClose: () => void
}

const RatingDetailsModal: React.FC<Props> = ({ show, data, onClose }) => {
    
    const datas = {
        customer: data?.customer?.name ?? null,
        rating_stars: data?.rating_stars ?? '',
        rating_note: data?.rating_note ?? ''
    };
    return (
        <Modal
            show={show}
            onHide={onClose}
            aria-labelledby='contained-modal-title-vcenter'
            centered
        >
            <Modal.Header className='d-flex justify-content-center'>
                <Modal.Title>{datas.customer}'s Feed Back</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-10">
                <div className="d-flex flex-column gap-5">   
                    <div className="fv-row flex-row-fluid fv-plugins-icon-container">
                        <div className='rating justify-content-center mb-3'>
                            {[...Array(5)].map((star, index) => {        
                                index += 1;
                                return (         
                                    <div 
                                        key={index} 
                                        className={index <= datas.rating_stars ? "rating-label me-2 checked" : "rating-label me-2"}
                                    >
                                        <i className='bi bi-star-fill fs-3'></i>
                                    </div> 
                                );
                            })}
                        </div>
                        <div className='text-gray-800 mb-5 text-center'>
                            {datas.rating_note}
                        </div>
                    </div> 
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default RatingDetailsModal
