import React, { FC, useState } from 'react'
import _ from 'lodash'
import { BookingModel } from '../../models/BookingModel'
import { CustomerModel } from '../../models/CustomerModel'

type Props = {
    handleSubmitRating: (rating: number, rating_note: string) => void,
    handlePreviousStep: () => void,
    customer?: CustomerModel,
    booking?: BookingModel,
    handleBack: () => void
}

const CheckinSuccessStep: FC<Props> = ({handleSubmitRating, handlePreviousStep, handleBack, customer, booking}) => {
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);
    const [rating_note, setRatingNote] = useState('');

    const submitRating = () => {
        handleSubmitRating(rating, rating_note)
    }
    return (
        <>
            <div className='text-center mb-10'>
                <h1 className='text-dark mb-3'>Thank you for your business! {customer?.name}</h1>
                {(booking && booking?.earning_points > 0) && (
                    <p>Congratulation! You have earned {booking.earning_points}. <br/>Now you have {customer?.points ?? 0} available points </p>
                )}
                
            </div>
            <div className='text-center mb-10'>
                <h3>Rate our's services</h3>
                <p className='text-dark mb-10 text-success'>
                    <div className='rating justify-content-center'>
                    {[...Array(5)].map((star, index) => {        
                        index += 1;
                        return (         
                            <div 
                                key={index} 
                                onMouseLeave={() => setHover(rating)}
                                className={index <= (hover || rating) ? "rating-label me-2 checked" : "rating-label me-2"}
                                onClick={() => setRating(index)}
                                onMouseEnter={() => setHover(index)}>
                                <i className='bi bi-star-fill fs-1'></i>
                            </div> 
                        );
                    })}
                    </div>
                </p>
                <div className="d-flex flex-column flex-md-row gap-5">       
                    <div className='fv-row flex-row-fluid fv-plugins-icon-container justify-content-center'>
                        <button onClick={submitRating} className="btn btn-bg-success" type="submit">Submit</button>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column flex-md-row gap-5">       
                <button onClick={handlePreviousStep} className="btn btn-bg-warning" type="submit">Back</button>
                <button onClick={handleBack} className="btn btn-bg-success ms-auto" type="submit">New checkin</button>
            </div>
        </>
    )
}

export default CheckinSuccessStep
