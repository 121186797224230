import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import { Coupon } from './Coupon'

const CouponWrapper: FC = () => {
  const breadcrumbs = [
    {
      title: 'Dashboard',
      path: '/',
      isActive: true,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs} description={'Coupon'}>Coupon Management</PageTitle>
      <Coupon />
    </>
  )
}

export default CouponWrapper
