import React, { FC } from 'react'
import _ from 'lodash'
import { ErrorMessage, Field } from 'formik'
//import * as Yup from 'yup'
import { Appointment } from '../../types/Appointment'
import Flatpickr from 'react-flatpickr'
import moment from 'moment'

type Props = {
    data?: Appointment
    handleChange: (e: any) => void,
    morningPeriod: any,
    afPeriod: any,
    nowTime:any ,
    isActive: boolean
}

const AppointmentStep2: FC<Props> = ({ morningPeriod, afPeriod ,nowTime, isActive, handleChange, data}) => {
    const isDisableBtn = (timeItem: string) => {
        let isDisable = true;
        if (data?.date) {
            let btnTime = moment(data.date + timeItem, 'YYYY-MM-DD H:m').valueOf();
            if (btnTime > nowTime) {
                isDisable = false;
            }
        }
        return isDisable;
    }

    const btnClass = (timeItem: string) => {
        return 'btn ' + ((timeItem === data?.time) ? 'btn-primary' : 'btn-outline-dark');
    }
    return (
        <>
        {isActive && (
            <div className='w-100 mb-2 '>
                <div className='fv-row'>

                    <div className="form-group mb-5">
                        <label className='d-flex align-items-center fs-5 fw-bolder mb-2 mt-4'>
                            <span className='required'>Select a date:</span>
                        </label>
                        <Flatpickr
                            className='form-control form-control-lg form-control-solid'
                            name='date'
                            onChange={date => {
                                let val = moment(date[0]).format('YYYY-MM-DD');
                                handleChange({
                                    target: { name: 'date', value: val },
                                })
                                if (data?.time && data?.time !== '' && val + data?.time) {
                                    let slTime = val + ' ' + data?.time;
                                    if (moment(slTime).valueOf() < moment().valueOf()) {
                                        handleChange({
                                            target: { name: 'time', value: '' },
                                        })
                                    }
                                }
                            }}
                            value={data?.date ? moment(data?.date).format('YYYY-MM-DD') : ''}
                            options={{
                                altInput: true,
                                dateFormat: 'Y-m-d',
                                altFormat: "m-d-Y",
                                enableTime: false
                            }}
                        />
                        <div className='text-danger mt-2'>
                            <ErrorMessage name='date' />
                        </div>
                    </div>

                    <div className="time-group">
                        <label className='d-flex align-items-center fs-5 fw-bolder mb-2 mt-4'>
                            <span className='required'>Select a time:</span>
                        </label>
                        <div className='text-danger mt-2'>
                            <ErrorMessage name='time' />
                        </div>
                        <div className="time-list">
                            <label className="fs-5">Morning</label>
                            <div className="time-items morning">
                                {morningPeriod.map((morItem: any, index: string) => {
                                    let isDisable = isDisableBtn(morItem);
                                    return (
                                        <div className="btn-group" key={morItem}>
                                            <Field
                                                className='btn-check'
                                                type='radio'
                                                name='time'
                                                id={morItem}
                                                value={morItem}
                                                disabled={isDisable}
                                            />
                                            <label className={ isDisable ? 'btn btn-secondary': btnClass(morItem)} htmlFor={morItem}>
                                                <div className="fw-normal text-light">{moment(morItem,'hh:mm').format('hh:mm A')}</div>
                                            </label>
                                        </div>
                                    )
                                })}
                            </div>

                            <label className="fs-5">Afternoon</label>
                            <div className="time-items afternoon">
                            {afPeriod.map((afItem: any, index: string) => {
                                let isDisable = isDisableBtn(afItem);
                                return (
                                    <div className="btn-group" key={afItem}>
                                        <Field
                                            className='btn-check'
                                            type='radio'
                                            name='time'
                                            id={afItem}
                                            value={afItem}
                                            disabled={isDisable}
                                        />
                                        <label className={isDisable ?'btn btn-secondary': btnClass(afItem)} htmlFor={afItem}>
                                            <div className="fw-normal text-light">{moment(afItem,'hh:mm').format('hh:mm A')}</div>
                                        </label>
                                    </div>
                                )
                            })}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )}
        </>
    )
}

export default AppointmentStep2
