import React, { FC,useState } from 'react';
import SlidingPane from "react-sliding-pane";
import GplusIcon from '../asset/icons/g-plus-64.png';


  const Gplus: FC = () => {
    
    return (
    <>
      <div className="icon-task" title="Follow Sapanails " >  
        <a target="_blank" title="Google plus" rel="nofollow noopener" href="https://www.google.com.vn/search?_ga=2.95051547.607984948.1628712118-555546334.1607017050&amp;q=Sapa+Nails&amp;ludocid=3973578817169807064&amp;lsig=AB86z5WLJc3ZnBUy2hWRy2zPCca-"> 
          <img src={GplusIcon} className="icon-label"/> 
        </a>
      </div> 
    </>
    );
  };
  
  
export default Gplus;


