import axios from 'axios'

import { ADMIN_ENDPOINT_CUSTOMERS } from '../../constants/endpoints';
import { PaginateModel } from '../../models/PaginateModel';
import queryString from 'query-string'
import { ResponseModel } from '../../models/ResponseModel';
import { CustomerModel, SearchModel } from '../../models/CustomerModel';

export function getCustomerList(search: SearchModel) {
  return axios.get<PaginateModel<CustomerModel>>(`${ADMIN_ENDPOINT_CUSTOMERS}?${queryString.stringify(search, {arrayFormat: 'bracket'})}`)
}

export function store(customer: CustomerModel) {
  return axios.post<ResponseModel>(ADMIN_ENDPOINT_CUSTOMERS, customer);
}

export function update(customer: CustomerModel) {
  return axios.put<ResponseModel>(ADMIN_ENDPOINT_CUSTOMERS, customer);
}

export function destroy(id: number) {
  return axios.delete<ResponseModel>(`${ADMIN_ENDPOINT_CUSTOMERS}/${id}`);
}

export function deleteAll(arrId: Array<number>) {
  return axios.post<ResponseModel>(`${ADMIN_ENDPOINT_CUSTOMERS}/delete-multi`, {id: arrId});
}
