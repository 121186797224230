import React, { FC } from 'react'
import _ from 'lodash'
import { ErrorMessage, Field} from 'formik'
//import * as Yup from 'yup'
import { Appointment } from '../../types/Appointment'
//import Flatpickr from 'react-flatpickr'
//import moment from 'moment'

type Props = {
    data?: Appointment
    isActive: boolean
}

const AppointmentStep3: FC<Props> = ({data , isActive}) => {
    return (
        <>
        { isActive && (
            <div className='w-100 mb-10'>
                <div className='fv-row'>
                    <div className="form-group">
                        <label className='d-flex align-items-center fs-5 fw-bolder mb-2 mt-4'>
                            <span className='required'>Your name</span>
                        </label>
                        <Field
                            type='text'
                            className='form-control form-control-lg form-control-solid'
                            name='name'
                            id="name" 
                            placeholder="Enter name"
                        />
                        <div className='text-danger mt-2'>
                            <ErrorMessage name='name' />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className='d-flex align-items-center fs-5 fw-bolder mb-2 mt-4'>
                            <span className='required'>Your phone</span>
                        </label>
                        <Field
                            type='text'
                            className='form-control form-control-lg form-control-solid'
                            name='phone'
                            id="phone" 
                            placeholder="Enter your contact phone"
                        />
                        <div className='text-danger mt-2'>
                            <ErrorMessage name='phone' />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className='d-flex align-items-center fs-5 fw-bolder mb-2 mt-4'>
                            <span className=''>Note</span>
                        </label>
                        <Field name="note" component='textarea' rows="4"  className='form-control form-control-solid'/>
                    </div>
                </div>
            </div>
        )}
        </>
    )
}

export default AppointmentStep3
