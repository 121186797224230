/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react'
import { useLayout } from '../core'
import { getMessaging, onMessage } from "firebase/messaging";
import { Toast } from '../../../app/modules/common/helper';
import * as checkinCRUD from '../../../app/pages/checkin-management/checkinCRUD';
import { VERSION } from '../../../app/constants/commons';

import { BookingModel } from '../../../app/models/BookingModel';
import _ from 'lodash';
import CheckOutModalFt from '../../../app/pages/checkin-management/CheckOutModalFt';
export interface ModalModel {
    show: boolean
    mode: string
    data?: any
}
interface Payload {
    data: {
        booking: string
    }
}
const Footer: FC = () => {
    const { classes } = useLayout()
    const [checkOutModal, setCheckOutModal] = useState<ModalModel>({
        show: false,
        mode: 'add',
    });
    const [discount, setDiscount] = useState<number>(0);
    const [discountMsg, setDiscountMsg] = useState<string>();
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
        let booking = JSON.parse(payload?.data?.booking ?? '');
        if (!_.isEmpty(booking)) {
            setCheckOutModal({
                show: true,
                mode: 'add',
                data: booking
            })
        }
    });
    const updateDiscount = (values: any) => {
        let data = {
            id: values.id,
            discount_type: values.discount_type,
            use_points: values.use_points,
            coupon: values.coupon
        }
        let result = checkinCRUD.updateDiscount(data);
        result.then(res => {
            setDiscountMsg(res.data.data?.error);
            setDiscount(res.data.data?.discount);
        }).catch(error => {
            const response = error.response
            let message: string = '';
            if (response?.status === 422) {
                if (response?.data?.errors) {
                    const arrMess = [];
                    for (const [key, mess] of Object.entries(response?.data?.errors)) {
                        arrMess.push(mess);
                    }
                    message = arrMess.join("\n")
                }
            } else {
                message = error.response.message
            }
            Toast.fire({
                icon: 'error',
                title: message
            })
        });
    }
    const handleSubmitCheckout = async (values: any, actions: any) => {
        let result = checkinCRUD.checkout({
            ...values,
            earning_points: values.earning_points.replace("$", "")
        });
        result.then(res => {
            if (res.data.status) {
                Toast.fire({
                    icon: 'success',
                    title: 'Success'
                })
                setCheckOutModal({ ...checkOutModal, show: false })
            } else {
                Toast.fire({
                    icon: 'error',
                    title: 'Server error'
                })
            }
        }).catch(error => {
            const response = error.response
            let message: string = '';
            if (response?.status === 422) {
                if (response?.data?.errors) {
                    const arrMess = [];
                    for (const [key, mess] of Object.entries(response?.data?.errors)) {
                        arrMess.push(mess);
                    }
                    message = arrMess.join("\n")
                }
            } else {
                message = 'Server error'
            }
            Toast.fire({
                icon: 'error',
                title: message
            })
        });
    };
    const handleUpdateServices = async (value: any) => {
        let result = checkinCRUD.updateServices(value);
        result.then(res => {
            let bookingData = res.data?.data;
            setCheckOutModal({ ...checkOutModal, data: bookingData })
        }).catch(error => {
            const response = error.response
            let message: string = '';
            if (response?.status === 422) {
                if (response?.data?.errors) {
                    const arrMess = [];
                    for (const [key, mess] of Object.entries(response?.data?.errors)) {
                        arrMess.push(mess);
                    }
                    message = arrMess.join("\n")
                }
            } else {
                message = 'Server error'
            }
            Toast.fire({
                icon: 'error',
                title: message
            })
        });
    };
    return (
        <>
            <div className='footer py-4 d-flex flex-lg-column' id='kt_footer'>
                {/* begin::Container */}
                <div
                    className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
                >
                    {/* begin::Copyright */}
                    <div className='text-dark order-2 order-md-1'>
                        <span className='text-muted fw-bold me-2'>{new Date().getFullYear()} &copy;</span>
                        <a href='https://www.sapanails.com' className='text-gray-800 text-hover-primary'>
                            Sapa Nails - Version {VERSION}
                        </a>
                    </div>
                    {/* end::Copyright */}
                </div>
                {/* end::Container */}
            </div>
            <CheckOutModalFt
                {...checkOutModal}
                discount={discount}
                discountMsg={discountMsg}
                updateDiscount={updateDiscount}
                onClose={() => {setCheckOutModal({ ...checkOutModal, show: false }) }}
                onSubmit={handleSubmitCheckout}
                handleUpdateServices={handleUpdateServices}
            />
           
        </>
        
    )
}

export { Footer }
