import {ErrorMessage, Field, useFormikContext} from "formik";
import {LIST_MONTH} from "../../../constants/commons";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import React, {FC, useCallback, useEffect, useState} from "react";
import _, {isEmpty} from "lodash";
import {CampaignModel, Customer} from "../../../models/MarketingModel";
import * as marketingCRUD from "../MarketingCRUD";
import {useParams} from "react-router";
import { Link } from "react-router-dom";

type Props = {
  handleSubmit: (values: any) => void

}
const CampaignDetail: FC<Props> = ({handleSubmit}) => {

  const {values: data, setErrors, validateForm, setTouched, setFieldValue, setValues} = useFormikContext();

  const [selectedInList, setSelectedInList] = useState<string[]>([]);
  const [selectedInCampaign, setSelectedInCampaign] = useState<string[]>([]);

  const [customers, setCustomers] = useState<any>({});

  const [customerInList, setCustomerInList] = useState<any>({});
  const [customerInCampaign, setCustomerInCampaign] = useState<any>({});
  const [master, setMaster] = useState<{ types: string[] }>();

  const params = useParams<{ id: string }>()

  useEffect(() => {
    customerMaster();
    if (params.id) {
      getCampaignDetail(params.id);
    }
  }, [])

  const getCampaignDetail = async (id: string) => {
    const res = await marketingCRUD.getDetail(id);
    const data: CampaignModel = res?.data ?? {};
    setValues(data);
    setCustomers(data.customers ?? {});
    setCustomerInCampaign(data.customers ?? {})
  }

  const customerMaster = async () => {
    const res = await marketingCRUD.getMaster();
    setMaster(res?.data ?? {})
  }

  const setStateBeforeSearch = ({allCustomers}: { allCustomers: Customer[] }) => {
    const list = _.keyBy(allCustomers, 'id');
    const ids = _.map(allCustomers, 'id');
    const customerExist = _.filter(customerInList, function (item) {
      return ids.includes(item.id);
    })
    const mapKeyId = _.keyBy(customerExist, 'id');
    setCustomers({...customers, ...list});
    // Keep old customer compaign
    setCustomerInList({...list, ...mapKeyId});
  };
  const handleSearch = async () => {
    try {
      const {store_id, customer_name, customer_birthday, customer_group, last_visit} = data as CampaignModel;
      const params = {
        store_id, customer_name, customer_birthday, customer_group, last_visit
      }
      const result: any = await marketingCRUD.getCustomer(params);
      const allCustomers = result?.data ?? [];
      setStateBeforeSearch({allCustomers});
    } catch (error) {
      setStateBeforeSearch({allCustomers: []});
    }
  }

  const getFormatOpt = useCallback((customer: Customer) => ({
    value: customer?.id,
    label: `${customer?.name} ${customer?.phone ? '(' + customer.phone + ')' : ''}`
  }), []);


  useEffect(() => {
    setFieldValue('customers_in_campaign', customerInCampaign);
  }, [customerInCampaign])


  const handleChange = (event: any, isList: boolean = false) => {
    const selectedOptions: any = Array.from(event.target.selectedOptions, (option: any) => option.value);
    if (isList) {
      setSelectedInList(selectedOptions);
      return;
    }
    setSelectedInCampaign(selectedOptions);
  };
  const handleClickAddCustomer = () => {
    if (selectedInList?.length) {
      let list: any = {};
      let campaign: any = {};
      const arrCustomerList: string[] = Object.keys(customerInList) ?? [];
      arrCustomerList.forEach((key: string | number) => {
        const customer = customerInList[key];
        const add = selectedInList.includes(customer.id?.toString());
        if (add) {
          campaign = {...campaign, [customer.id]: customer};
          return;
        }
        list = {...list, [customer.id]: customer}

      })
      setCustomerInList({...list});
      setSelectedInList([]);
      setCustomerInCampaign({...campaign, ...customerInCampaign});
    }
  }

  const handleClickRemoveCustomer = () => {
    if (selectedInCampaign?.length) {
      let list: any = {};
      let campaign: any = {};
      const arrCustomerCampaign: string[] = Object.keys(customerInCampaign) ?? [];
      arrCustomerCampaign.forEach((key: string | number) => {
        const customer = customerInCampaign[key];
        const remove = selectedInCampaign.includes(customer.id?.toString());
        if (remove) {
          list = {...list, [customer.id]: customer}
          return;
        }
        campaign = {...campaign, [customer.id]: customer};
      })

      setCustomerInList({...customerInList, ...list});
      setCustomerInCampaign(campaign);
      setSelectedInCampaign([]);
    }
  }

  const handleAddAllCustomer = () => {
    setCustomerInList({});
    setSelectedInList([]);
    setCustomerInCampaign({...customerInList, ...customerInCampaign})
  };

  const handleRemoveAllCustomer = () => {
    setCustomerInList({...customerInList, ...customerInCampaign})
    setCustomerInCampaign({});
    setSelectedInCampaign([]);
  };

  const handleValidate = async () => {
    const errors = await validateForm(data);
    if (!isEmpty(errors)) {
      let touched = {};
      Object.keys(errors).forEach((name: string) => {
        touched = {...touched, [name]: true}
      })
      setTouched(touched)
      setErrors(errors)
      return;
    }
    // @ts-ignore
    const params = {...data, send_test: true};
    handleSubmit(params);
  }

  const disableBtnAddAll = isEmpty(customerInList);
  const disableBtnAdd = isEmpty(selectedInList);

  const disableBtnRemoveAll = isEmpty(customerInCampaign);
  const disableBtnRemove = isEmpty(selectedInCampaign);

  const {date_start_send, last_visit} = data as CampaignModel;

  return <>
    <div className="card">
        <div className='card-header  py-4'>
          <div className="col-md-12 d-flex justify-content-start">
            <div className="col-md-8">
              <label className="form-label pt-4">Chi Tiết Quảng Cáo</label>
            </div>
            <div className='col-md-4 d-flex justify-content-end'>
              <Link to="/marketing" className="btn btn-primary">
                Quay lại
              </Link>
            </div>
          </div>
        </div>
    </div>
    <div className="card mt-10">
      
      <div className="container">
        <div className="row my-10">
        
        <div  className="col-12"><span className="title">Lọc danh sách khách hàng cho chương trình</span><br/><span>Chọn các điều kiện bên dưới lọc ra các khách hàng phù hợp để thêm vào chương trình quảng cáo</span></div>
          
          <div className="col-6">
            <div className="row align-items-center py-4">
              <div className="col-4">
                <label className="col-form-label">Store Name<br/>(Tên cửa hàng)</label>
              </div>
              <div className="col-8">
                <Field
                  as='select'
                  className='form-select'
                  placeholder=''
                  autoComplete='nope'
                  name='store_id'
                >
                  <option value='1'>Sapanails Store</option>
                  <option value='2'>Sapanails Store ( Import Sep 2023) </option>
                </Field>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row align-items-center py-4">
              <div className="col-4">
                <label className="form-label">Customer Name/Email</label>
              </div>
              <div className="col-8">
                <Field
                  type='text'
                  className='form-control'
                  placeholder=''
                  autoComplete='nope'
                  name='customer_name'
                />
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row align-items-center py-4">
              <div className="col-4">
                <label className="form-label">Customer Birthday<br/>(Tháng SN khách hàng)</label>
              </div>
              <div className="col-8">
                <Field
                  as='select'
                  className='form-select'
                  placeholder=''
                  autoComplete='nope'
                  name='customer_birthday'
                >
                  <option value=""></option>
                  {Object.keys(LIST_MONTH).map(num => <option value={num}
                                                              key={`month_${num}`}>{LIST_MONTH[num]} - {moment().year()}</option>)}
                </Field>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row align-items-center py-4">
              <div className="col-4">
                <label className="form-label">Customer Group<br/>(Nhóm khách hàng)</label>
              </div>
              <div className="col-8">
                <Field
                  as='select'
                  className='form-select'
                  placeholder=''
                  autoComplete='nope'
                  name='customer_group'
                >
                  <option value=""></option>
                  {Object.keys(master?.types ?? []).map((num: any) => {
                    return <option key={`group_${num}`} value={master?.types?.[num]}>{master?.types?.[num]}</option>
                  })}

                </Field>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row align-items-center py-4">
              <div className="col-4">
                <label className="form-label">Last visit<br/>Lần cuối đến cửa hàng</label>
              </div>
              <div className="col-8">
                <Flatpickr
                  className='form-control'
                  name='last_visit'
                  value={last_visit}
                  onChange={date => {
                    setFieldValue('last_visit', moment(date[0]).format('YYYY-MM-DD'))
                  }}
                  options={{
                    altInput: true,
                    dateFormat: 'Y-m-d',
                    altFormat: "m-d-Y",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-4 offset-2 d-flex align-items-center">
            <button className="btn btn-primary" type="button" onClick={handleSearch}>Search Add List (Lọc danh sách)
            </button>

          </div>
          <div className='card-header flex-nowrap border-0 pt-9'>
            <div className='card-title m-0'>
              Thêm khách hàng vào chương trình quảng cáo
            </div>
          </div>
          <div className="col-12">
            <div className="row align-items-center py-4">
              <div className="col-5">
                <div className="d-flex">
                  <label className="form-label">Customers List<br/>(Danh sách khách hàng)</label>
                  {
                    <div className="ms-auto">

                      <button className={`btn btn-primary me-auto btn-sm ${disableBtnAddAll ? 'disabled' : ''}`}
                              type="button"
                              onClick={handleAddAllCustomer}>
                        Add All ( Thêm tất cả )
                        <i className="fas fa-angle-double-right ms-3"/>
                      </button>
                    </div>
                  }
                </div>
              </div>
              <div className="col-5 offset-2">
                <label className="required form-label">Customers In Campaign<br/>(Danh sách khách hàng sẽ nhận tin nhắn quảng cáo)</label>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-5">
                <select className="form-select h-250px" multiple aria-label="multiple select"
                        name="customer_in_list"
                        onChange={(e) => handleChange(e, true)}>
                  {Object.keys(customerInList).map((key: string | number) => {
                    const id = customerInList[key]?.id ?? "";
                    const option = getFormatOpt(customers[id]);
                    return <option value={option.value} key={option.value}>{option.label}</option>
                  })}
                </select>
              </div>
              <div className="col-2 d-flex align-content-around flex-wrap justify-content-center">
                <div className="d-flex flex-column bd-highlight mb-3">
                  <div className="p-2 bd-highlight text-center">
                    <button className={`btn btn-primary btn-sm ${disableBtnAdd ? 'disabled' : ''}`} type="button"
                            onClick={handleClickAddCustomer}>
                      Add<i className="fas fa-angle-right ms-1"/>
                    </button>
                  </div>
                  <div className="p-2 bd-highlight text-center">
                    <button className={`btn btn-danger px-3 btn-sm ${disableBtnRemove ? 'disabled' : ''}`} type="button"
                            onClick={handleClickRemoveCustomer}>
                      <i className="fas fa-angle-left ms-1"/>Remove
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-5">
                <select className="form-select h-250px" multiple aria-label="multiple select"
                        name="customers_in_campaign"
                        onChange={handleChange}>
                  {Object.keys(customerInCampaign).map((key: string | number) => {
                    const id = customerInCampaign[key]?.id ?? "";
                    const option = getFormatOpt(customers[id]);
                    return <option value={option.value} key={option.value}>{option.label}</option>
                  })}
                </select>
                <div className="fv-plugins-message-container invalid-feedback">
                  <ErrorMessage name='customers_in_campaign'/>
                </div>
              </div>
              <div className="col-auto offset-7">
                <button className={`text-end btn btn-danger btn-sm ${disableBtnRemoveAll ? 'disabled' : ''}`}
                        type="button"
                        onClick={handleRemoveAllCustomer}><i
                  className="fas fa-angle-double-left ms-3"/>Remove All (Lấy ra hết danh sách nhân tin nhắn)
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="card mt-10">
      <div className='card-header flex-nowrap border-0 pt-9'>
          <div className='card-title m-0'>
            Chi tiết nội dung chương trình
          </div>
        </div>
      <div className="container py-5">
      
        <div className="row">
          <div className="col-6">
            <div className="row align-items-center py-4">
              <div className="col-4">
                <label className="required form-label">Campaing<br/>(Tên Chương Trình)</label>
              </div>
              <div className="col-8">
                <Field
                  type='text'
                  className='form-control'
                  placeholder=''
                  autoComplete='nope'
                  name='campaign_name'
                />

              </div>
              <div className="offset-4 fv-plugins-message-container invalid-feedback">
                <ErrorMessage name='campaign_name'/>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row align-items-center py-4">
              <div className="col-4">
                <label className="required form-label">Coupon code <br/>(Mã giảm giá)</label>
              </div>
              <div className="col-8">
                <Field
                  type='text'
                  className='form-control'
                  placeholder=''
                  autoComplete='nope'
                  name='coupon_code'
                />
              </div>
              <div className="offset-4 fv-plugins-message-container invalid-feedback">
                <ErrorMessage name='coupon_code'/>
              </div>
            </div>
          </div>

          <div className="col-6">
            <div className="row align-items-center py-4">
              <div className="col-4">
                <label className="required form-label">Schedule setting <br/>(Đặt lịch gởi tin nhắn)</label>
              </div>
              <div className="col-8">
                <Flatpickr
                  className='form-control'
                  name='date_start_send'
                  value={date_start_send}
                  onChange={date => {
                    setFieldValue('date_start_send', moment(date[0]).format('YYYY-MM-DD HH:mm'))
                  }}
                  options={{
                    altInput: true,
                    minDate: "today",
                    dateFormat: 'Y-m-d H:i',
                    altFormat: "m-d-Y H:i",
                    enableTime: true
                  }}
                />
              </div>
              <div className="offset-4 fv-plugins-message-container invalid-feedback">
                <ErrorMessage name='date_start_send'/>
              </div>
            </div>
          </div>

          <div className="col-6">
            <div className="row align-items-center py-4">
              <div className="col-4">
                <label className="form-label">Test phone number <br/>(Số điện thoại kiểm tra)</label>
              </div>
              <div className="col-8">
                <Field
                  type='text'
                  className='form-control'
                  placeholder=''
                  autoComplete='nope'
                  name='test_number'
                />
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row align-items-center py-4">
              <div className="col-4">
                <label className="form-label">Test email <br />(Email kiểm tra)</label>
              </div>
              <div className="col-8">
                <Field
                  type='text'
                  className='form-control'
                  placeholder=''
                  autoComplete='nope'
                  name='test_email'
                />
              </div>
              <div className="offset-4 fv-plugins-message-container invalid-feedback">
                <ErrorMessage name='test_email'/>
              </div>
            </div>
          </div>
          <div className="col-12">

            <div className="row align-items-center py-4">
              <div className="col-2">
                <label className="required form-label">SMS Message<br />(Nội dung tin nhắn)</label>
                <br />Chỉ cần điền nội dung muốn gởi , Tên khách hàng và mã giảm giá sẽ tự động được điền vào.
              </div>
              <div className="col-10">
                <Field
                  type='text'
                  as="textarea"
                  className='form-control'
                  placeholder=''
                  autoComplete='nope'
                  name='message'
                  rows={7}
                />
              </div>
              <div className="offset-2 fv-plugins-message-container invalid-feedback">
                <ErrorMessage name='message'/>
              </div>
            </div>
          </div>
          <div className="col-12 text-center">
            <button className="btn btn-bg-info me-3 text-white" type="button" onClick={handleValidate}>Send Test (gởi thử sms)
            </button>
            <button className="btn btn-bg-success" type="submit">Save</button>
          </div>
        </div>
      </div>
    </div>
  </>
}
export default CampaignDetail;