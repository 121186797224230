/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Modal } from 'react-bootstrap-v5'
import { BookingDetailModel, BookingModel } from '../../models/BookingModel'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { AsyncSelect2 } from '../../../_metronic/partials'
import { ADMIN_ENDPOINT_SERVICES, ADMIN_ENDPOINT_STAFFS } from '../../constants/endpoints'
import { bookingStatus } from '../../constants/commons'
import Flatpickr from 'react-flatpickr'
import moment from 'moment';

type Props = {
    show: boolean
    mode: string
    data?: BookingModel
    validDate?: string
    customer: any
    onClose: () => void
    onSubmit: (values: any, actions: any) => void
}
const addSchema = Yup.object().shape({
    //name: Yup.string().required().max(255).label('Name'),
    //email: Yup.string().nullable().email().label('Email'),
    phone: Yup.number().required().label('Phone number'),
    //staff: Yup.object().nullable().label('Staff'),
    date: Yup.date().nullable().label('Date').min(
        moment(),
        "Date can't be before today"
      ),
})

const editSchema = Yup.object().shape({
    //name: Yup.string().required().max(255).label('Name'),
    //email: Yup.string().nullable().email().label('Email'),
    phone: Yup.number().required().label('Phone number'),
    //staff: Yup.object().nullable().label('Staff').nullable(),
    date: Yup.date().nullable().label('Date')
})

const CheckinModal: React.FC<Props> = ({ show, mode, data, validDate, customer, onClose, onSubmit }) => {
    let initValues: any = {
        name: (data) ? data.customer?.name : (customer) ? customer.name : '',
        email: (data) ? data.customer?.email : (customer) ? customer.email : '',
        phone: (data) ? data.customer?.phone : (customer) ? customer.phone : '',
        services: [],
        staff: { value: 1, label: ' '},
        date: null,
        user_note: '',
        status: 1,
        statusOptions: { value: 1, label: 'New' }
    };
    if (mode === 'edit' && data) {
        let selectedServices: Array<{ value: number, label: string }> = [];
        if (data.details) {
            data.details.map((detail: BookingDetailModel) => (
                selectedServices = [...selectedServices, { value: detail.service_id, label: detail.service_name }]
            ))
        }
        initValues = {
            ...initValues,
            id: data.id,
            name: data.customer.name,
            email: data.customer.email,
            phone: data.customer.phone,
            services: selectedServices,
            date: data.date,
            user_note: data.user_note,
            staff: { value: data.staff?.id ?? '1', label: data.staff?.name ?? ' '},
            status: data.status,
            statusOptions: { value: data.status, label: bookingStatus[data.status] }
        }
    }
    return (
        <Modal
            show={show}
            onHide={onClose}
            aria-labelledby='contained-modal-title-vcenter'
            centered
            size="xl"
        >
            <Modal.Header className='d-flex'>
                <Modal.Title>{mode === 'edit' ? 'Edit Booking' : 'Add Booking'}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-10">
                <Formik validationSchema={mode === 'edit' ? editSchema : addSchema} initialValues={initValues} onSubmit={onSubmit}>
                    {({ values, handleChange }) => (
                        <Form>
                            <div className="d-flex flex-column flex-md-row gap-5">   

                                <div className="fv-row flex-row-fluid fv-plugins-icon-container">
                                    <label className="required form-label">Contact Phone</label>
                                    <Field
                                        type='text'
                                        className='form-control'
                                        placeholder=''
                                        autoComplete='nope'
                                        name='phone'
                                    />
                                    <div className="fv-plugins-message-container invalid-feedback"><ErrorMessage name='phone' /></div>
                                </div>   

                                <div className="fv-row flex-row-fluid fv-plugins-icon-container">
                                    <label className="required form-label">Date</label>
                                    <Flatpickr
                                        className='form-control'
                                        name='date'
                                        value={values.date}
                                        onChange={date => {
                                            handleChange({
                                                target: { name: 'date', value: moment(date[0]).format('YYYY-MM-DD H:m') },
                                            })
                                        }}
                                        options={{
                                            altInput: true,
                                            dateFormat: 'Y-m-d H:i',
                                            altFormat: "m-d-Y H:i",
                                            enableTime: true
                                        }}
                                    />
                                    <div className='text-danger mt-2'>
                                        <ErrorMessage name='checkin' />
                                        {(validDate) && (
                                            <div>Booking at: {validDate}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div>&nbsp;</div>
                            <div className="d-flex flex-column flex-md-row gap-5">                                 
                                <div className="fv-row flex-row-fluid">
                                    <label className="form-label">Name</label>
                                    <Field
                                        type='text'
                                        className='form-control'
                                        placeholder=''
                                        autoComplete='nope'
                                        name='name'
                                    />
                                    <div className="fv-plugins-message-container invalid-feedback"><ErrorMessage name='name' /></div>
                                </div>                                 
                                <div className="fv-row flex-row-fluid">
                                    <label className="form-label">Contact Email</label>
                                    <Field
                                        type='text'
                                        className='form-control'
                                        placeholder=''
                                        autoComplete='nope'
                                        name='email'
                                    />
                                    <div className="fv-plugins-message-container invalid-feedback"></div>
                                </div>  
                            </div>
                            
                            <div>&nbsp;</div>
                            <div className="d-flex flex-column flex-md-row gap-5">                                
                                <div className="fv-row flex-row-fluid">
                                    <label className="form-label">Services</label>
                                    <AsyncSelect2
                                        name="service"
                                        placeholder=""
                                        isGray
                                        sourceUrl={ADMIN_ENDPOINT_SERVICES}
                                        defaultOptions
                                        value={values.services}
                                        default
                                        isMulti
                                        onChange={(selectedOption: any) => {
                                            values.services = selectedOption
                                            handleChange({
                                                target: { name: 'service', value: selectedOption.value },
                                            })
                                        }}
                                    />
                                    <div className="fv-plugins-message-container invalid-feedback"></div>
                                </div>                                
                                <div className="fv-row flex-row-fluid">
                                    <label className="form-label">Staff</label>
                                    <AsyncSelect2
                                        name="staff_id"
                                        placeholder=""
                                        isGray
                                        sourceUrl={ADMIN_ENDPOINT_STAFFS}
                                        defaultOptions
                                        value={values.staff}
                                        default
                                        onChange={(selectedOption: any) => {
                                        values.staff = selectedOption
                                        handleChange({
                                            target: { name: 'staff_id', value: selectedOption.value },
                                        })
                                        }}
                                    />
                                    <div className="fv-plugins-message-container invalid-feedback"></div>
                                </div>                        
                                <div className="fv-row flex-row-fluid">
                                    <label className="form-label">Status</label>
                                    <Field as="select" name="status" className='form-control'>
                                        <option value="1">New</option>
                                        <option value="2">Confirmed</option>
                                        <option value="3">Canceled</option>
                                    </Field>
                                    <div className="fv-plugins-message-container invalid-feedback"></div>
                                </div>                      

                            </div>
                            
                            <div>&nbsp;</div>
                            <div className="d-flex flex-column flex-md-row gap-5">                                      
                                <div className="fv-row flex-row-fluid">
                                    <label className="form-label">Note</label>
                                    <Field
                                        type='text'
                                        as='textarea'
                                        className='form-control'
                                        placeholder=''
                                        autoComplete='nope'
                                        name='user_note'
                                    />
                                    <div className="fv-plugins-message-container invalid-feedback"></div>
                                </div>          

                            </div>
                            <div>&nbsp;</div>

                           
                            <div className='d-flex mb-4 justify-content-between'>
                                <button className="btn btn-bg-secondary" type="button" onClick={() => onClose()}>Close</button>
                                <button className="btn btn-bg-success" type="submit">{mode === 'edit' ? 'Checkin' : 'Add New'}</button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}

export default CheckinModal
