import React, {FC} from 'react'
import {PageTitle} from "../../../../_metronic/layout/core";
import UpsertMarketing from "./UpsearchMarketing";

const UpsertMarketingWrapper: FC = () => {
  const breadcrumbs = [
    {
      title: 'Dashboard',
      path: '/',
      isActive: true,
    },
    {
      title: 'Marketing',
      path: '/marketing',
      isActive: true,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs} description={'Coupon'}>Marketing detail</PageTitle>
      <UpsertMarketing/>
    </>
  )
}

export default UpsertMarketingWrapper
