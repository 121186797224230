/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import * as customerCRUD from './customerCRUD';
import { Toast } from '../../modules/common/helper'
import _ from 'lodash';
import moment from 'moment';
import queryString from 'query-string'
import CustomerModal from './CustomerModal';
import { DATA_PAGINATION_LENGTH, Paginate, PaginationLength } from '../../../_metronic/partials';
import CustomerTable from './CustomerTable';
import { useHistory } from 'react-router-dom';
import { PaginateModel } from '../../models/PaginateModel';
import Swal from 'sweetalert2';
import { CustomerModel, ModalModel, SearchModel } from '../../models/CustomerModel';
import { customerTypes, customerGroups } from '../../constants/commons';
import { Sort } from '../../models/SortInterface';

const Customer: React.FC = () => {
    const history = useHistory();
    const [dataPaginate, setDataPaginate] = useState<PaginateModel<CustomerModel>>();
    const [customerModal, setModal] = useState<ModalModel>({
        show: false,
        mode: 'add',
    });
    const [sort, setSort] = useState<Sort>({})
    const [search, setSearch] = useState<SearchModel>({
        page: 1,
        per_page: DATA_PAGINATION_LENGTH[0],
    })
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState<Array<number>>([]);
    useEffect(() => {
        const initParams: SearchModel = parseParams(queryString.parse(history.location.search))
        getData({ ...search, ...initParams });
    }, []);

    const parseParams = (params: SearchModel) => {
        if (params.page) {
            params.page = +params.page
        }
        if (params.per_page) {
            params.per_page = +params.per_page
        }
        return params
    }

    const getData = async (params: SearchModel) => {
        const { data: dataPaginate } = await customerCRUD.getCustomerList(params);
        const objQuery = { ...params }
        if (objQuery.page === 1) {
            delete objQuery.page
        }
        if (objQuery.per_page === DATA_PAGINATION_LENGTH[0]) {
            delete objQuery.per_page
        }
        history.push({ search: `?${queryString.stringify(objQuery)}` })
        setDataPaginate(dataPaginate);
        setSearch(params);
    }

    const handleSearch = () => {
        getData({ ...search, page: 1 })
    }

    const handlePageChange = (data: any) => {
        getData({ ...search, page: data.selected + 1 })
    }

    const handlePageLengthChange = (e: any) => {
        getData({ ...search, page: 1, per_page: +e.target.value })
    }

    const handleEdit = (item: CustomerModel) => {
        setModal({
            show: true,
            mode: 'edit',
            data: item
        });
    }

    const handleDelete = (item: CustomerModel) => {
        Swal.fire({
            title: 'Do you want to delete this customer?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Delete',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await customerCRUD.destroy(item.id);
                Toast.fire({
                    icon: 'success',
                    title: 'Success'
                })
                setModal({ ...customerModal, show: false })
                getData({ ...search, page: 1 })
            }
        })
    }

    const handleSubmit = async (values: any, actions: any) => {
        let result;
        if (customerModal.mode === 'edit') {
            result = customerCRUD.update(values);
        } else {
            result = customerCRUD.store(values);
        }
        result.then(res => {
            if (res.data.status) {
                Toast.fire({
                    icon: 'success',
                    title: 'Success'
                })
                setModal({ ...customerModal, show: false })
                getData({ ...search, page: 1 })
            } else {
                Toast.fire({
                    icon: 'error',
                    title: 'Server error'
                })
            }
        }).catch(error => {
            const response = error.response
            let message: string = '';
            if (response?.status === 422) {
                if (response?.data?.errors) {
                    const arrMess = [];
                    for (const [key, mess] of Object.entries(response?.data?.errors)) {
                        arrMess.push(mess);
                    }
                    message = arrMess.join("\n")
                }
            } else {
                message = 'Server error'
            }
            Toast.fire({
                icon: 'error',
                title: message
            })
        });
    };

    const handleSortClick = (name: string, sort_type: string) => {
        let next_sort = '';
        if (name == search.sort_column) {
            if (sort_type == '' || sort_type == 'desc') {
                next_sort = 'asc';
            } else {
                next_sort = 'desc';
            }
        } else {
            next_sort = 'asc';
        }
        setSort({ sort_column: name, sort_type: next_sort });
        getData({ ...search, page: 1, sort_column: name, sort_type: next_sort })
    }
    const handleDeleteAll = () => {
        if (isCheck.length) {
            Swal.fire({
                title: 'Do you want to delete all selected customer?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Delete',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await customerCRUD.deleteAll(isCheck);
                    Toast.fire({
                        icon: 'success',
                        title: 'Success'
                    })
                    setIsCheckAll(false);
                    getData({ ...search, page: 1 })
                }
            });
        } else {
            Toast.fire({
                icon: 'error',
                title: 'No item delete'
            })
        }

    }

    const handleSelectAll = (e: any) => {
        let data: Array<CustomerModel> = dataPaginate?.data || [];
        setIsCheckAll(!isCheckAll);
        let dataCheck: Array<number> = data.map(item => item.id);
        setIsCheck(dataCheck);
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    const handleClick = (e: any) => {
        let { id, checked } = e.target;
        id = parseInt(id);
        setIsCheck([...isCheck, id]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== id));
        }
    };

    const toggleStatus = (customer: CustomerModel) => {
        let result = customerCRUD.update(customer);
        result.then(res => {
            if (res.data.status) {
                Toast.fire({
                    icon: 'success',
                    title: 'Success'
                })
                getData({ ...search, page: 1 })
            } else {
                Toast.fire({
                    icon: 'error',
                    title: 'Server error'
                })
            }
        }).catch(error => {
            const response = error.response
            let message: string = '';
            if (response?.status === 422) {
                if (response?.data?.errors) {
                    const arrMess = [];
                    for (const [key, mess] of Object.entries(response?.data?.errors)) {
                        arrMess.push(mess);
                    }
                    message = arrMess.join("\n")
                }
            } else {
                message = 'Server error'
            }
            Toast.fire({
                icon: 'error',
                title: message
            })
        });
    };
    return (
        <>
            <div className="card">
                <div className="card-header py-4">
                    <div className="col-md-12 d-flex justify-content-start">
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md-3">
                                    <label className="form-label">Keyword</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Name, email, phone"
                                        name="keyword"
                                        autoComplete='nope'
                                        value={search.keyword || ''}
                                        onChange={(e) => setSearch({ ...search, keyword: e.target.value })}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <label className="form-label">Level</label>
                                    <select
                                        className="form-select"
                                        name="type"
                                        value={search.type || ''}
                                        onChange={(e) => setSearch({ ...search, type: e.target.value })}
                                    >
                                        <option value="all">All</option>
                                        {Object.keys(customerTypes).map(function (key, index) {
                                            return <option key={"type" + key} value={customerTypes[key]}>{customerTypes[key]}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="col-md-3">
                                    <label className="form-label">Group</label>
                                    <select
                                        className="form-select"
                                        name="group"
                                        value={search.group || ''}
                                        onChange={(e) => setSearch({ ...search, group: e.target.value })}
                                    >
                                        <option value="all">All</option>
                                        {Object.keys(customerGroups).map(function (key, index) {
                                            return <option key={"group" + key} value={customerGroups[key]}>{customerGroups[key]}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="col-md-3">
                                    <button
                                        className="btn btn-primary mt-8"
                                        onClick={handleSearch}
                                    >
                                        <i className="bi bi bi-search fs-4"></i> Search
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4  d-flex justify-content-end'>
                            {(isCheckAll || !_.isEmpty(isCheck)) && (
                                <button
                                    className="btn mt-8 btn-danger me-2"
                                    onClick={handleDeleteAll}
                                >
                                    <i className="bi bi-trash fs-4 me-2"></i> Delete selected
                                </button>
                            )}

                            <button
                                className="btn btn-success mt-8"
                                onClick={() => {
                                    setModal({
                                        show: true,
                                        mode: 'add'
                                    })
                                }}
                            >
                                <i className="bi bi-plus-lg"></i> Add New
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col">
                            <CustomerTable
                                data={dataPaginate?.data || []}
                                onEdit={handleEdit}
                                onDelete={handleDelete}
                                handleSortClick={handleSortClick}
                                type_sort={sort?.sort_type}
                                sort_column={sort?.sort_column}
                                handleClickAll={handleSelectAll}
                                isCheckedAll={isCheckAll}
                                handleClick={handleClick}
                                isChecked={isCheck}
                                toggleStatus={toggleStatus}
                            />
                        </div>
                    </div>
                </div>
                <div className="card-footer d-flex justify-content-between">
                    <PaginationLength selected={search.per_page || DATA_PAGINATION_LENGTH[0]} onChange={handlePageLengthChange} />
                    <Paginate
                        pageCount={dataPaginate?.meta?.last_page || 0}
                        onPageChange={handlePageChange}
                        forcePage={(search.page || 1) - 1}
                    />
                </div>
            </div>
            <CustomerModal
                {...customerModal}
                onClose={() => { setModal({ ...customerModal, show: false }) }}
                onSubmit={handleSubmit}
            />
        </>
    )
}

export { Customer }
