/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Modal } from 'react-bootstrap-v5'
import { BookingModel } from '../../models/BookingModel'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'

type Props = {
    show: boolean
    mode: string
    data?: BookingModel
    validDate?: string
    onClose: () => void
    onSubmit: (values: any, actions: any) => void
}
const schema = Yup.object().shape({
    phone: Yup.number().required().label('Phone number'),
})


const CheckCustomerModal: React.FC<Props> = ({ show, onClose, onSubmit }) => {
    let initValues: any = {
        phone: ''
    };
    return (
        <Modal
            show={show}
            onHide={onClose}
            aria-labelledby='contained-modal-title-vcenter'
            centered
        >
            <Modal.Header className='d-flex justify-content-center'>
                <Modal.Title>Create new checkin</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-10">
                <Formik validationSchema={schema} initialValues={initValues} onSubmit={onSubmit}>
                    {({ values, handleChange }) => (
                        <Form>
                            <div className='d-flex flex-column mb-7 fv-row'>
                                <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                    <span className='required'>Your phone number</span>
                                </label>
                                <Field
                                    type='text'
                                    className='form-control form-control-solid'
                                    placeholder=''
                                    autoComplete='nope'
                                    name='phone'
                                />
                                <div className='text-danger mt-2'>
                                    <ErrorMessage name='phone' />
                                </div>
                            </div>
                            <div className='d-flex mb-4 justify-content-between'>
                                <button className="btn btn-bg-secondary" type="button" onClick={() => onClose()}>Close</button>
                                <button className="btn btn-bg-success" type="submit">Submit</button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}

export default CheckCustomerModal
