import React, { FC, useState } from 'react'
import _ from 'lodash'
import { TablesWidget4 } from '../../../_metronic/partials/widgets'
import { CustomerModel } from '../../models/CustomerModel'

type Props = {
    handlePreviousStep: () => void,
    handleBack: () => void,
    customer?: CustomerModel
}

const RatingSuccessScreen: FC<Props> = ({handlePreviousStep, handleBack,customer}) => {
    const renderCustomerType = (type: string) => {
        let typeClass = "success";
        type = type.toLowerCase();
        switch (type){
            case 'new':
                typeClass = "success"
                break;
            case 'silver': 
                typeClass = "primary"
                break;
            case 'gold': 
                typeClass = "info"
                break;
            case 'platinum': 
                typeClass = "warning"
                break;
            case 'vip': 
                typeClass = "danger"
                break;
            default:
                typeClass = "success"
                
        }
        
        return <sup className={"badge badge-light-" + typeClass}>{type}</sup>
    }
    return (
        <>
            <div className='text-center mb-10'>
                <h2 className='text-dark mb-3'>Check-in completed!</h2>
            </div>
            <div className='text-center mb-10'>
                <p className='text-dark mb-10 text-success'>Your current points: <b>{customer?.points ?? 0} </b></p>
                <h2>
                    Thank you for your business, {customer?.name} {renderCustomerType(customer?.type || '')}
                </h2>
            </div>
            <div className="d-flex flex-column flex-md-row gap-5  text-center">      
                <div className='fv-row flex-row-fluid fv-plugins-icon-container justify-content-center'>
                {/* <button onClick={handlePreviousStep} className="btn btn-bg-warning" type="submit">Back</button> */}
                <button onClick={handleBack} className="btn btn-bg-success ms-auto" type="submit">New Check-In</button>
                </div>
            </div>
        </>
    )
}

export default RatingSuccessScreen
