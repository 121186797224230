import axios from 'axios'

import { ADMIN_ENDPOINT_COUPONS } from '../../constants/endpoints';
import { CouponModel, SearchModel } from '../../models/CouponModel';
import { PaginateModel } from '../../models/PaginateModel';
import queryString from 'query-string'
import { ResponseModel } from '../../models/ResponseModel';

export function getCoupon(search: SearchModel) {
  return axios.get<PaginateModel<CouponModel>>(`${ADMIN_ENDPOINT_COUPONS}?${queryString.stringify(search, {arrayFormat: 'bracket'})}`)
}

export function store(coupon: CouponModel) {
  return axios.post<ResponseModel>(ADMIN_ENDPOINT_COUPONS, coupon);
}

export function update(coupon: CouponModel) {
  return axios.put<ResponseModel>(ADMIN_ENDPOINT_COUPONS, coupon);
}

export function destroy(id: number) {
  return axios.delete<ResponseModel>(`${ADMIN_ENDPOINT_COUPONS}/${id}`);
}
