export const VERSION = `${process.env.REACT_APP_VERSION??'1.0.0'}`;

//Booking status code
export const NEW_SATUS_CODE = 1
export const CONFIRMED_SATUS_CODE = 2
export const CANCELED_SATUS_CODE = 3
export const FINISHED_SATUS_CODE = 4

//CHECKIN STEP
export const CHECK_CUSTOMER_STEP = 1
export const CREATE_ACCOUNT_STEP = 2
export const RATING_STEP = 3
export const RATING_SUCCESS_STEP = 4

//Booking status txt

export const NEW_SATUS = "New"
export const CONFIRMED_SATUS = "Confirmed"
export const CANCELED_SATUS = "Canceled"
export const FINISHED_SATUS = "Finished"

//Booking status list
interface bookingStatusInterface { [key: string]: string; }
export const bookingStatus:bookingStatusInterface = {
    1 : NEW_SATUS,
    2 : CONFIRMED_SATUS,
    3 : CANCELED_SATUS,
    4 : FINISHED_SATUS
}

//Customer type list
export const NEW_TYPE = "New"
export const SILVER_TYPE = "Silver"
export const GOLD_TYPE = "Gold"
export const PLATINUM_TYPE = "Platinum"
export const VIP_TYPE = "VIP"
export const ACTIVE = "Active"
export const INACTIVE = "In-Active"

interface customerTypeInterface { [key: string]: string; }
export const customerTypes:customerTypeInterface = {
    1 : NEW_TYPE,
    2 : SILVER_TYPE,
    3 : GOLD_TYPE,
    4 : PLATINUM_TYPE,
    5 : VIP_TYPE
}

export const customerGroups:customerTypeInterface = {
    1 : ACTIVE,
    2 : INACTIVE
}
interface monthInterface { [key: string]: string; }

export const LIST_MONTH:monthInterface = {
    1: 'Jan',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'May',
    6: 'Jun',
    7: 'Jul',
    8: 'Aug',
    9: 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec',
}
