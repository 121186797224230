/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import * as couponCRUD from './couponCRUD';
import { Toast } from '../../modules/common/helper'
import _ from 'lodash';
import moment from 'moment';
import queryString from 'query-string'
import CouponModal from './CouponModal';
import { DATA_PAGINATION_LENGTH, DatePicker, Paginate, PaginationLength } from '../../../_metronic/partials';
import CouponTable from './CouponTable';
import { useHistory } from 'react-router-dom';
import { CouponModel, ModalModel, SearchModel } from '../../models/CouponModel';
import { PaginateModel } from '../../models/PaginateModel';
import Swal from 'sweetalert2';

const Coupon: React.FC = () => {
    const history = useHistory();
    const [dataPaginate, setDataPaginate] = useState<PaginateModel<CouponModel>>();
    const [couponModal, setModal] = useState<ModalModel>({
        show: false,
        mode: 'add',
    });

    const [search, setSearch] = useState<SearchModel>({
        page: 1,
        per_page: DATA_PAGINATION_LENGTH[0],
    })
   
    useEffect(() => {
        const initParams: SearchModel = parseParams(queryString.parse(history.location.search))
        getData({ ...search, ...initParams });
    }, []);

    const parseParams = (params: SearchModel) => {
        if (params.page) {
            params.page = +params.page
        }
        if (params.per_page) {
            params.per_page = +params.per_page
        }
        return params
    }

    const getData = async (params: SearchModel) => {
        const { data: dataPaginate } = await couponCRUD.getCoupon(params);
        const objQuery = { ...params }
        if (objQuery.page === 1) {
            delete objQuery.page
        }
        if (objQuery.per_page === DATA_PAGINATION_LENGTH[0]) {
            delete objQuery.per_page
        }
        history.push({ search: `?${queryString.stringify(objQuery)}` })
        setDataPaginate(dataPaginate);
        setSearch(params);
    }

    const handleSearch = () => {
        getData({...search, page: 1})
    }

    const handlePageChange = (data: any) => {
        getData({ ...search, page: data.selected + 1 })
    }

    const handlePageLengthChange = (e: any) => {
        getData({ ...search, page: 1, per_page: +e.target.value })
    }

    const handleEdit = (item: CouponModel) => {
        setModal({
            show: true,
            mode: 'edit',
            data: item
        });
    }

    const handleDelete = (item: CouponModel) => {
        Swal.fire({
            title: 'Do you want to delete this coupon?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Delete',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await couponCRUD.destroy(item.id);
                Toast.fire({
                    icon: 'success',
                    title: 'Success'
                })
                setModal({ ...couponModal, show: false })
                getData({ ...search, page: 1 })
            }
        })
    }
    
    const handleSubmit = async (values: any, actions: any) => {
        let result;
        if (couponModal.mode === 'edit') {
            result = couponCRUD.update(values);
        } else {
            result = couponCRUD.store(values);
        }
        result.then(res => {
            if (res.data.status) {
                Toast.fire({
                    icon: 'success',
                    title: 'Success'
                })
                setModal({ ...couponModal, show: false })
                getData({ ...search, page: 1 })
            } else {
                Toast.fire({
                    icon: 'error',
                    title: 'Server error'
                })
            }
        }).catch(error => {
            const response = error.response
            let message: string = '';
            if (response?.status === 422) {
                if (response?.data?.errors) {
                    const arrMess = [];
                    for (const [key, mess] of Object.entries(response?.data?.errors)) {
                        arrMess.push(mess);
                    }
                    message = arrMess.join("\n")
                }
            } else {
                message = 'Server error'
            }
            Toast.fire({
                icon: 'error',
                title: message
            })
        });
    };

    return (
        <>
            <div className="card">
                <div className="card-header py-4">
                    <div className="col-md-12 d-flex justify-content-start">
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md-3">
                                    <label className="form-label">Code</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        name="code"
                                        autoComplete='nope'
                                        value={search.keyword || ''}
                                        onChange={(e) => setSearch({...search, keyword: e.target.value})}
                                        />
                                </div>
                                <div className="col-md-3">
                                    <button
                                        className="btn btn-primary mt-8"
                                        onClick={handleSearch}
                                    >
                                        <i className="bi bi bi-search fs-4"></i> Search
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4  d-flex justify-content-end'>
                            <button
                                className="btn btn-success mt-8"
                                onClick={() => {
                                    setModal({
                                        show: true,
                                        mode: 'add'
                                    })
                                }}
                            >
                                <i className="bi bi-plus-lg"></i> Add New
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col">
                            <CouponTable
                                data={dataPaginate?.data || []}
                                onEdit={handleEdit}
                                onDelete={handleDelete}
                            />
                        </div>
                    </div>
                </div>
                <div className="card-footer d-flex justify-content-between">
                    <PaginationLength selected={search.per_page || DATA_PAGINATION_LENGTH[0]} onChange={handlePageLengthChange} />
                    <Paginate
                        pageCount={dataPaginate?.meta?.last_page || 0}
                        onPageChange={handlePageChange}
                        forcePage={(search.page || 1) - 1}
                    />
                </div>
            </div>
            <CouponModal
                {...couponModal}
                onClose={() => { setModal({ ...couponModal, show: false }) }}
                onSubmit={handleSubmit}
            />
        </>
    )
}

export { Coupon }
