import React from "react"
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import moment from "moment";

type Props = {
    onChange: (date: any) => void
    value?: any
    options?: object
}

const DatePicker: React.FC<Props> = ({value, options, onChange }) => {
    return (
        <Flatpickr
            className="form-control"
            value={value ? moment(value).format("YYYY-MM-DD") : ""}
            onChange={date => {
                onChange(date.length > 0 ? moment(date[0]).format("YYYY-MM-DD") : "")
            }}
            options={options ? options : {}}
            placeholder="YYYY-MM-DD"
        />
    )
  }

export { DatePicker }
