import React, { FC, useState } from 'react'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { Toast } from '../common/helper'
import * as checkinCRUD from '../../pages/checkin-management/checkinCRUD';
import _ from 'lodash'
import {FooterClient} from '../../../_metronic/layout/components/FooterClient';
import { Field } from 'formik';
import { RouteComponentProps } from 'react-router-dom';

interface RouterProps { 
    id: string;
}
interface RatingScreenProps extends RouteComponentProps<RouterProps> {
  
}
const RatingScreen: FC<RatingScreenProps> = ({ match }) => {
    const [loading, setLoading] = useState(false)
    const [rating, setRating] = useState(0);
    const [rating_note, setRatingNote] = useState('');
    const bookingId = parseInt(match.params.id);
    const handleSubmitRating = () => {
        let result = checkinCRUD.postRating(bookingId, rating, rating_note);
        result.then(res => {
            setLoading(false);
        }).catch(error => {
            const response = error.response
            let message: string = '';
            if (response?.status === 422) {
                if (response?.data?.errors) {
                    const arrMess = [];
                    for (const [key, mess] of Object.entries(response?.data?.errors)) {
                        arrMess.push(mess);
                    }
                    message = arrMess.join("\n")
                }
            } else {
                message = 'Server error'
            }
            setLoading(false);
            Toast.fire({
                icon: 'error',
                title: message
            })
        });
    }
    return (
        <div
            className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
            style={{
                backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
            }}
        >
            {/* begin::Content */}
            <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
                {/* begin::Logo */}
                <a href='#' className='mb-12'>
                    <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo.png')} className='h-45px' />
                </a>
                {/* end::Logo */}
                {/* begin::Wrapper */}
                <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
                    <>
                        <div className='text-center mb-10'>
                            <h1 className='text-dark mb-3'>Thank you for your business!</h1>
                        </div>
                        <div className='text-center mb-10'>
                            <h3>Rate our's services</h3>
                            <p className='text-dark mb-10 text-success'>
                                <div className='rating justify-content-center'>
                                {[...Array(5)].map((star, index) => {        
                                    index += 1;
                                    return (         
                                        <div 
                                            key={index} 
                                            onMouseLeave={() => setRating(rating)}
                                            className={index <= rating ? "rating-label me-2 checked" : "rating-label me-2"}
                                            onClick={() => setRating(index)}
                                            onMouseEnter={() => setRating(index)}>
                                            <i className='bi bi-star-fill fs-1'></i>
                                        </div> 
                                    );
                                })}
                                </div>
                            </p>
                            <textarea 
                                className='form-control mb-5' 
                                placeholder='Review note' 
                                onChange={(e) => setRatingNote(e.target.value)}
                                rows={4}></textarea>
                           
                            <div className="d-flex flex-column flex-md-row gap-5">       
                                <div className='fv-row flex-row-fluid fv-plugins-icon-container justify-content-center'>
                                    <button onClick={handleSubmitRating} className="btn btn-bg-success" type="submit">Submit</button>
                                </div>
                            </div>
                        </div>
                    </>
                </div>
                {/* end::Wrapper */}
                <FooterClient />
            </div>
            {/* end::Content */}
        </div>
    )
}

export default RatingScreen
