import React, { FC } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import SlidingPane from "react-sliding-pane";
import { RootState } from '../../../../setup';
import aboutIcon from '../asset/icons/about-64.png';
import * as homeRedux from '../HomeRedux'

const AboutUs: FC = (props) => {
    const dispatch = useDispatch()
    const paneOpened = useSelector<RootState>(({ home }) => home.pane_name, shallowEqual)
    const openPane = (name: string | null) => {
        if (paneOpened === name) {
            name = null;
        }
        dispatch(homeRedux.actions.changedPane(name));
    }

    return (
        <>
            <div className="icon-task" title="About Us" onClick={() => openPane('aboutus')}>
                <img src={aboutIcon} className="icon-label" alt='About Us' /> <span className="d-none d-sm-block">About Us </span>
            </div>
            <SlidingPane
                className="sapa-panel-sliding  about-panel-sliding"
                overlayClassName="sapa-overlay-panel-sliding"
                isOpen={paneOpened === 'aboutus'}
                width="840px"
                hideHeader
                shouldCloseOnEsc
                from="bottom"
                onRequestClose={() => {
                    // triggered on "<" on left top click or on outside click
                    openPane(null);
                }}
            >
                <div className="row vertical-center">
                    <div className='col-md-6'>
                        <p>At&nbsp;<strong>Sapa Nails</strong>, we provide all professional services including Manicures, Pedicures, Skin Care, A La Carte, Acrylic Nails, Eyelashes, Waxing and even Kid Services. With modern techniques and equipment, we’re greatly confident to bring customers the best care experiences. Moreover, we also pay our more attention to hygiene and serving attitude. We also require our staff to keep everything clean and welcome customers with friendly and smiley faces.</p>
                    </div>
                    <div className='col-md-6'>
                        <p>
                            <strong>Sapa Nails</strong>&nbsp;is a professional&nbsp;<a title="Sapa Nails" href="https://sapanails.com/"><strong>nail shop</strong></a>&nbsp;and spa situated in&nbsp;<a title="Sapa Nails" href="https://sapanails.com/"><strong>San Antonio</strong></a>, a major city in south-central Texas&nbsp;<a title="Sapa Nails" href="https://sapanails.com/"><strong>78230</strong></a>. Our goal is to help all customers relax and take back the balance in life after hard working days.

                        </p>
                        <p>
                            <a href="tel:2106912323" className="btn btn-danger fs-3">Call: 210-691-2323</a> <a href="#" onClick={() => openPane('booking')} className="btn btn-warning fs-3"><i className="bi bi-calendar-date"></i>&nbsp;Book online</a>
                        </p>
                    </div>

                </div>

            </SlidingPane>
        </>
    );
};

export default AboutUs


