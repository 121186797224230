import axios from 'axios'

import {
  ENDPOINT_UPSERT_MARKETING,
  ADMIN_ENDPOINT_MARKETING,
  ENDPOINT_SEARCH_CUSTOMER_MARKETING, ADMIN_ENDPOINT_MARKETING_MASTER, ADMIN_ENDPOINT_MARKETING_STATUS
} from '../../constants/endpoints';
import queryString from 'query-string'
import {CampaignModel, MarketingModel} from "../../models/MarketingModel";
import {ResponseModel} from "../../models/ResponseModel";
import {SearchModel} from "../../models/StaffModel";
import {PaginateModel} from "../../models/PaginateModel";

export function getCustomer(search: {
  store_id: number;
  customer_group: string | undefined;
  customer_name: string | undefined;
  customer_birthday: string | undefined
}) {
  return axios.get(`${ENDPOINT_SEARCH_CUSTOMER_MARKETING}?${queryString.stringify(search, {arrayFormat: 'bracket'})}`)
}

export function upsert(marketing: CampaignModel) {
  return axios.post<ResponseModel>(ENDPOINT_UPSERT_MARKETING, marketing);
}

export function getMarketingList(search: SearchModel) {
  return axios.get<PaginateModel<MarketingModel>>(`${ADMIN_ENDPOINT_MARKETING}?${queryString.stringify(search, {arrayFormat: 'bracket'})}`)
}

export function destroy(arrId: Array<number>) {
  return axios.post<ResponseModel>(`${ADMIN_ENDPOINT_MARKETING}/destroy`, {id: arrId});
}

export function getMaster() {
  return axios.get(ADMIN_ENDPOINT_MARKETING_MASTER)
}

export function getDetail(id: string) {
  return axios.get<CampaignModel>(ADMIN_ENDPOINT_MARKETING + "/" + id)
}


export function updateStatus(params: { id: number, status: boolean }) {
  return axios.post<CampaignModel>(ADMIN_ENDPOINT_MARKETING_STATUS, params)
}

