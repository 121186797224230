import React, { Suspense } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { RootState } from '../../setup'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { FallbackView } from '../../_metronic/partials'
import {
    BOOKING_ROUTE,
    COUPON_ROUTE,
    CUSTOMER_ROUTE,
    DASHBOARD_ROUTE,
    GOCHECKIN_ROUTE,
    MARKETING_CREATE_ROUTE,
    MARKETING_ROUTE,
    MARKETING_UPDATE_ROUTE
} from '../constants/routes'
import { AuthPage } from '../modules/auth'
import BookingWrapper from '../pages/booking-management/BookingWrapper'
import CheckinWrapper from '../pages/checkin-management/CheckinWrapper'
import CouponWrapper from '../pages/coupon-management/CouponWrapper'
import CustomerWrapper from '../pages/customer-management/CustomerWrapper'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import MarketingWrapper from "../pages/marketing-management/MarketingWrapper";
import UpsertMarketingWrapper from "../pages/marketing-management/Upsert/UpsertMarketinngWrapper";

export function PrivateRoutes() {
    const isAuthorized = useSelector<RootState>(({ auth }) => auth.user, shallowEqual)
    return (
        <Suspense fallback={<FallbackView />}>
            <Switch>
                
                {(!isAuthorized) && (
                    <>
                        <Route>
                            <AuthPage />
                        </Route>
                        <Redirect to='/auth/login' />
                    </>
                )}
                <>
                    <MasterLayout>
                        <Switch>
                            <Route exact path={DASHBOARD_ROUTE} component={DashboardWrapper} />
                            <Route exact path={BOOKING_ROUTE} component={BookingWrapper} />
                            <Route exact path={GOCHECKIN_ROUTE} component={CheckinWrapper} />
                            <Route exact path={COUPON_ROUTE} component={CouponWrapper} />
                            <Route exact path={CUSTOMER_ROUTE} component={CustomerWrapper} />
                            <Route exact path={MARKETING_ROUTE} component={MarketingWrapper} />
                            <Route exact path={MARKETING_CREATE_ROUTE} component={UpsertMarketingWrapper} />
                            <Route path={MARKETING_UPDATE_ROUTE + '/:id'} component={UpsertMarketingWrapper} />
                            <Redirect from='/auth' to='/dashboard' />
                            <Redirect exact from='/' to='/dashboard' />
                            <Redirect exact from='/admin' to='/auth/login' />
                            <Redirect to='error/404' />
                        </Switch>
                    </MasterLayout>
                </>
                
            </Switch>
        </Suspense>
    )
}
