import React from 'react';
import moment from 'moment';
import { BookingModel } from '../../models/BookingModel';
import { bookingStatus, NEW_SATUS_CODE } from '../../constants/commons';

type Props = {
    data: Array<BookingModel>
};
const BookingTable: React.FC<Props> = ({ data}) => {

    const renderStatus = (status: number) => {
        let statusClass = "success";
        
        switch (status){
            case 1:
                statusClass = "warning"
                break;
            case 2: 
                statusClass = "success"
                break;
            case 3: 
                statusClass = "danger"
                break;
            default:
                statusClass = "primary"
                
        }
        return <span className={"badge badge-light-" + statusClass}>{bookingStatus[status]}</span>
    }

    return (
        <div className='table-responsive'>
            <table className="table table-hover table-rounded table-striped border gy-7 gs-7">
                <thead>
                    <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                        <th>
                            No
                        </th>
                        <th>Customer name</th>
                        <th>Phone</th>
                        <th>Booking date</th>
                        {/* <th>Booking at</th> */}
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {data.length > 0 && data.map((item: BookingModel, index: number) => (
                        
                        <tr key={item.id}>
                            <td>
                                {index + 1}
                            </td>
                            <td>{item.customer.name || ''}</td>
                            <td>{item.customer.phone}</td>
                            <td>{moment(item.date).format('MM/DD/YYYY hh:mm A')}</td>
                            {/* <td>{moment(item.created_at).format('MM/DD/YYYY h:m A')}</td> */}
                            <td className="text-center">
                                {renderStatus(item.status)}
                            </td>
                        </tr>
                    ))
                    }
                </tbody>
            </table>
        </div>
    );
}

export default BookingTable;