/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {Link} from 'react-router-dom'
import { VERSION } from '../../../app/constants/commons';

const FooterClient: FC = () => {
   
    return (
        <>
            <div className='py-6'>
                <div className='row'>
                    <div className="d-flex flex-column flex-md-row gap-5 text-center">
                        <div className='fv-row flex-row-fluid fv-plugins-icon-container justify-content-center'>
                            <span className='text-muted fw-bold me-2'>{new Date().getFullYear()} &copy;</span>
                            <Link className='text-gray-800 text-hover-primary' to={'https://www.sapanails.com'}>
                                sapanails.com - Version {VERSION}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>                      
        </>
    )
}

export { FooterClient }
