import axios, { AxiosResponse } from 'axios'
import { ResponseModel } from '../../models/ResponseModel'
import { ServiceModel } from '../../models/ServiceModel'
import { StaffModel } from '../../models/StaffModel'
import { Appointment } from './types/Appointment'

const API_URL = `${process.env.REACT_APP_API_URL}/api`

export const SERVICES_URL = `${API_URL}/get-services`
export const STAFFS_URL = `${API_URL}/get-staffs`
export const MAKE_APPOINTMENT_URL = `${API_URL}/make-appointment`


export function getServices() {
  return axios.get<AxiosResponse<ServiceModel>>(SERVICES_URL)
}

export function getStaffs() {
  return axios.get<AxiosResponse<StaffModel>>(STAFFS_URL)
}

export function makeAppointMent(data:Appointment) {
  return axios.post<ResponseModel>(MAKE_APPOINTMENT_URL, data);
}
