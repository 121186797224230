import React from 'react';
import { BookingModel, BookingDetailModel } from '../../models/BookingModel';
import { bookingStatus, NEW_SATUS_CODE } from '../../constants/commons';
import moment from 'moment';

type Props = {
    data: Array<BookingModel>,
    onEdit: (booking: BookingModel) => void,
    onDelete: (booking: BookingModel) => void,
    onConfirm: (e: any) => void
};


const BookingTable: React.FC<Props> = ({ data, onConfirm, onEdit, onDelete}) => {

    const renderStatus = (status: number) => {
        let statusClass = "success";
        
        switch (status){
            case 1:
                statusClass = "warning"
                break;
            case 2: 
                statusClass = "success"
                break;
            case 3: 
                statusClass = "danger"
                break;
            default:
                statusClass = "primary"
                
        }
        return <span className={"badge badge-light-" + statusClass}>{bookingStatus[status]}</span>
    }

    const renderCustomerType = (type: string) => {
        let typeClass = "success";
        type = type.toLowerCase();
        switch (type){
            case 'new':
                typeClass = "success"
                break;
            case 'silver': 
                typeClass = "primary"
                break;
            case 'gold': 
                typeClass = "info"
                break;
            case 'platinum': 
                typeClass = "warning"
                break;
            case 'vip': 
                typeClass = "danger"
                break;
            default:
                typeClass = "success"
                
        }
        return <span className={"badge badge-light-" + typeClass}>{type}</span>
    }

    return (
        <div className='table-responsive'>
            <table className="table table-hover table-rounded table-striped border gy-7 gs-7">
                <thead>
                    <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                        <th>
                            No
                        </th>
                        <th>Action</th>
                        <th>Customer name</th>
                        <th>Appointment Date & Time</th>
                        <th>Booking Timestamp</th>
                        <th>Service</th>
                        <th>Techician Name</th>
                        <th>Note</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {data.length > 0 && data.map((item: BookingModel, index: number) => (
                        <tr key={item.id}>
                            <td>
                                {index + 1}
                            </td>
                            <td className="text-center">
                                <div className='button-group'>
                                    {(item.status === NEW_SATUS_CODE ) && (
                                        <button className="btn btn-icon btn-success w-30px h-30px ps-2 me-2" onClick={() => onConfirm(item)} data-toggle="tooltip" data-theme="dark" title="Click to Confirm Booking!">
                                            <i className="bi bi-arrow-repeat fs-4 me-2"></i>
                                        </button>
                                    )}
                                    {/* {(item.status === CONFIRMED_SATUS_CODE) && (
                                        <Link className="btn btn-icon btn-success w-30px h-30px ps-2 me-2" to={GOCHECKIN_ROUTE}>
                                            <i className="bi bi-calendar-check fs-4 me-2"></i>
                                        </Link>
                                    )} */}

                                    <button className="btn btn-icon btn-warning w-30px h-30px ps-2 me-2" onClick={() => onEdit(item)}>
                                        <i className="bi bi-pencil-square fs-4 me-2"></i>
                                    </button>
                                    <button className="btn btn-icon btn-danger w-30px h-30px ps-2" onClick={() => onDelete(item)}>
                                        <i className="bi bi-trash fs-4 me-2"></i>
                                    </button>
                                </div>
                            </td>
                            <td>
                                {(item?.customer) && (
                                    <>
                                        {renderCustomerType(item?.customer?.type)} {item?.customer?.name || ''}
                                        <span className="text-primary fw-bold d-block"><i className="fas fa-phone-alt"></i>&nbsp;{item.customer?.phone || ''}</span>
                                        <span className="text-danger fw-bold d-block"><i className="fas fa-envelope"></i>&nbsp;{item.customer?.email || ''}</span>
                                    </>
                                )}
                                

                            </td>
                            <td>
                                {moment(item.date).format('MM/DD/YYYY')}<br/>
                                {moment(item.date).format('hh:mm A')}
                            </td>
                            <td>
                                {moment(item.created_at).format('MM/DD/YYYY')}<br/>
                                {moment(item.created_at).format('hh:mm A')}
                            </td>
                            <td>
                                {item.details.length > 0 && item.details.map((service: BookingDetailModel, index: number) => (
                                    <React.Fragment key={index}>
                                        {service.service_name} <br />
                                    </React.Fragment>
                                    ))
                                }
                            </td>
                            <td>{item.staff_name}</td>
                            <td>{item.user_note}</td>
                            <td className="text-center">
                                {renderStatus(item.status)}
                            </td>
                        </tr>
                    ))
                    }
                </tbody>
            </table>
        </div>
    );
}

export default BookingTable;