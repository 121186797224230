import React, { FC } from 'react';
import SlidingPane from "react-sliding-pane";
import serviceIcon from '../asset/icons/report-3-64.png';


import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../setup';
import * as homeRedux from '../HomeRedux'


const Service: FC = () => {
  const dispatch = useDispatch()
  const paneOpened = useSelector<RootState>(({ home }) => home.pane_name, shallowEqual)
  const openPane = (name: string | null) => {
    if (paneOpened === name) {
      name = null;
    }
    dispatch(homeRedux.actions.changedPane(name));
  }



  return (
    <>
      <div className="icon-task" title="Our Services" onClick={() => openPane('service')}>
        <img src={serviceIcon} className="icon-label" alt="Our Services" /> <span className="d-none d-sm-block">Our Services</span>

      </div>

      <SlidingPane
        className="sapa-panel-sliding  service-panel-sliding bg-white"
        overlayClassName="sapa-overlay-panel-sliding"
        isOpen={paneOpened === 'service'}
        width="auto"
        hideHeader
        shouldCloseOnEsc
        from="bottom"
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          openPane(null);
        }}
      >
        <ul className="nav nav-tabs nav-line-tabs mb-0 fs-6 mx-5">
          <li className="nav-item">
            <a
              className="nav-link active"
              data-bs-toggle="tab"
              href="#kt_tab_pane_manuicures"
            >
              MANICURES
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              data-bs-toggle="tab"
              href="#kt_tab_pane_waxing"
            >
              WAXING
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              data-bs-toggle="tab"
              href="#kt_tab_pane_pedicures"
            >
              PEDICURES
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              data-bs-toggle="tab"
              href="#kt_tab_pane_kid"
            >
              KID SERVICES
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              data-bs-toggle="tab"
              href="#kt_tab_pane_alacater"
            >
              A-LA-CARTE
            </a>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade active show"
            id="kt_tab_pane_manuicures"
            role="tabpanel"
          >
            <div className="table-responsive my-5">
                          
              <div className="row  pt-1">
                <div className="col-12">
                <strong>$20 - Basic Manicure</strong>, (Quick soak, cuticle push, arm massage, polish or buff your
                      choice).
                </div>                
              </div>            
              <div className="row  pt-3">
                <div className="col-12">
                <strong>$30 - Signature Manicure</strong> - Basic manicure plus exfoliating scrub and warm Paraffin
                      wax treatment.
                </div>                
              </div>            
              <div className="row  pt-3 pb-5">
                <div className="col-12">
                <strong>$40 - Aroma Manicure</strong> (Using collagen gloves, sugar scrub, lotion &amp; paraffin wax used
                      for super hydrated driest of hands and nails.
                </div>                
              </div>           
              <div className="row  pt-3 bg-odd bg-gradient">
                <div className="col-8">Shellac (Gel) Manicure</div>
                <div className="col-4 text-end">$35 and Up</div>
              </div>           
              <div className="row  pt-3 bg-odd bg-gradient">
                <div className="col-8">Color change Shellac (Gel) only</div>
                <div className="col-4 text-end">$25</div>
              </div>           
              <div className="row  pt-3 bg-odd bg-gradient">
                <div className="col-8"><strong>Nextgen Dip - SNS</strong> (Calcium treatment for weak, damaged nail, <br />or if Acrylic and Shellac are no longer working for you.</div>
                <div className="col-4 text-end">$40</div>
              </div>           
              <div className="row  pt-3 bg-odd bg-gradient">
                <div className="col-8"><i className='ms-5 fs-4'>French Style</i></div>
                <div className="col-4 text-end">Extra $5</div>
              </div>           
              <div className="row  pt-3 bg-odd bg-gradient">
                <div className="col-8"><i className='ms-5 fs-4'>Exfoliate Sugar Scrub</i></div>
                <div className="col-4 text-end">Extra $8</div>
              </div>            
              <div className="row  pt-3 bg-odd bg-gradient">
                <div className="col-8"><i className='ms-5 fs-4'>Paraffin Wax Treatment</i></div>
                <div className="col-4 text-end">Extra $8</div>
              </div>           
              <div className="row  pt-3 bg-odd bg-gradient">
                <div className="col-8"><i className='ms-5 fs-4'>Hot Stone Treatment</i></div>
                <div className="col-4 text-end">Extra $10</div>
              </div>            
              <div className="row  pt-3 bg-odd bg-gradient">
                <div className="col-8"><i className='ms-5 fs-4'>Using Collagen Gloves for any Manicure service</i></div>
                <div className="col-4 text-end">Extra $8</div>
              </div>
            </div>

            <div className="table-responsive my-5">

            <div className="container p-0 pt-5">            
                <div className="row mb-4">
                  <div className="col-8 "><strong className='fs-2 border-bottom border-primary'>ACRYLIC NAILS</strong></div>
                  <div className="col-2 text-end">FULL SET</div>
                  <div className="col-2 text-end">FILL</div>
                </div>            
                <div className="row  pt-3 bg-odd bg-gradient">
                  <div className="col-8">Solar Nail with Regular Polish</div>
                  <div className="col-2 text-end">$45 &amp; up</div>
                  <div className="col-2 text-end">$40</div>
                </div>            
                <div className="row  pt-3 bg-white bg-gradient">
                  <div className="col-8">Solar Nail with Shellac/Gel Polish</div>
                  <div className="col-2 text-end">$50 &amp; up</div>
                  <div className="col-2 text-end">$45</div>
                </div>            
                <div className="row  pt-3 bg-odd bg-gradient">
                  <div className="col-8">Solar Nail with Pink &amp; White</div>
                  <div className="col-2 text-end">$55</div>
                  <div className="col-2 text-end">$50</div>
                </div>            
                <div className="row  pt-3 bg-white bg-gradient">
                  <div className="col-8">Solar Nail Set Pearl Tip</div>
                  <div className="col-2 text-end">$50 &amp; up</div>
                  <div className="col-2 text-end">$40</div>
                </div>            
                <div className="row  pt-3 bg-odd bg-gradient">
                  <div className="col-8">Solar Nail Set color Powder</div>
                  <div className="col-2 text-end">$50 &amp; up</div>
                  <div className="col-2 text-end">$45  &amp; up</div>
                </div>           
                <div className="row  pt-3 bg-white bg-gradient">
                  <div className="col-8">Ombre' Nails</div>
                  <div className="col-2 text-end">$60</div>
                  <div className="col-2 text-end">$45</div>
                </div>
              

              </div>

            </div>

            <div className="table-responsive my-5">
              <div className="container p-0 pt-5">            
                <div className="row mb-4">
                  <div className="col-12 "><strong className='fs-2 border-bottom border-primary'>DIPPING POWDER</strong></div>
                </div>            
                <div className="row  pt-3 bg-odd bg-gradient">
                  <div className="col-8"><b>Dip Nail (SNS, NEXTGEN...)</b> on your real nails</div>
                  <div className="col-4 text-end">$45 (T/off $5 extra)</div>
                </div>            
                <div className="row  pt-3 bg-white bg-gradient">
                  <div className="col-8"><b>Dip Nail (SNS, NEXTGEN...)</b> with tip</div>
                  <div className="col-4 text-end">$50 (T/off $5 extra)</div>
                </div>            
                <div className="row  pt-3 bg-odd bg-gradient">
                  <div className="col-8">French Style</div>
                  <div className="col-4 text-end">Extra $10 &amp; up</div>
                </div>            
                <div className="row  pt-3 bg-white bg-gradient">
                  <div className="col-8">Long Nails</div>
                  <div className="col-4 text-end">Extra $5 &amp; up</div>
                </div>            
                <div className="row  pt-3 bg-odd bg-gradient">
                  <div className="col-8">Coffin Shape</div>
                  <div className="col-4 text-end">Extra $5</div>
                </div>            
                <div className="row  pt-3 bg-white bg-gradient">
                  <div className="col-8">Chrome Gell Polish</div>
                  <div className="col-4 text-end">Extra $15</div>
                </div>     
                <div className="row  pt-3 bg-odd bg-gradient">
                  <div className="col-8">Take off Powder / SNS only</div>
                  <div className="col-4 text-end">$15</div>
                </div>     
                <div className="row  pt-3 bg-white bg-gradient">
                  <div className="col-8">Take off Gel Polish</div>
                  <div className="col-4 text-end">$10</div>
                </div>     
                <div className="row  pt-3 bg-odd bg-gradient">
                  <div className="col-8">Toes Full Set</div>
                  <div className="col-4 text-end">$55 &amp; Fill $50</div>
                </div>
              </div>
             
            </div>


          </div>
          <div className="tab-pane fade" id="kt_tab_pane_waxing" role="tabpanel">
          <div className="container p-0 pb-5">            
            <div className="row mb-4 ">
              <div className="col-12 "><strong className='fs-2 border-bottom border-primary'>WAXING SERVICES</strong></div>
            </div>            
            <div className="row  pt-3 bg-odd bg-gradient">
              <div className="col-8">Eyebrow</div>
              <div className="col-4 text-end">$13</div>
            </div>            
            <div className="row  pt-3 bg-white bg-gradient">
              <div className="col-8">Lip</div>
              <div className="col-4 text-end">$10</div>
            </div> 

            <div className="row  pt-3 bg-odd bg-gradient">
              <div className="col-8">Chin</div>
              <div className="col-4 text-end">$10</div>
            </div>            
            <div className="row  pt-3 bg-white bg-gradient">
              <div className="col-8">Forehead</div>
              <div className="col-4 text-end">$12</div>
            </div>

            <div className="row  pt-3 bg-odd bg-gradient">
              <div className="col-8">Under Arm</div>
              <div className="col-4 text-end">$30</div>
            </div>

            <div className="row  pt-3 bg-white bg-gradient">
              <div className="col-8">Face Whole</div>
              <div className="col-4 text-end">$45</div>
            </div>

            <div className="row  pt-3 bg-odd bg-gradient">
              <div className="col-8">Half Arm</div>
              <div className="col-4 text-end">$30</div>
            </div>      

            <div className="row  pt-3 bg-white bg-gradient">
              <div className="col-8">Full Arm</div>
              <div className="col-4 text-end">$50</div>
            </div>      

            <div className="row  pt-3 bg-odd bg-gradient">
              <div className="col-8">Half Legs</div>
              <div className="col-4 text-end">$40+</div>
            </div>      

            <div className="row  pt-3 bg-white bg-gradient">
              <div className="col-8">Full Legs</div>
              <div className="col-4 text-end">$60</div>
            </div>      

            <div className="row  pt-3 bg-odd bg-gradient">
              <div className="col-8">Back Waxing</div>
              <div className="col-4 text-end">$55+</div>
            </div>            

            <div className="row  pt-3 bg-white bg-gradient">
              <div className="col-8">Bikini Lines</div>
              <div className="col-4 text-end">$30 &amp; up</div>
            </div>  
            
            <div className="row  pt-3 bg-odd bg-gradient">
              <div className="col-8">Brazilian/Boy Zilian</div>
              <div className="col-4 text-end">$60+</div>
            </div>

          </div>
          </div>
          <div className="tab-pane fade" id="kt_tab_pane_pedicures" role="tabpanel">
            <div className="table-responsive my-5 pb-5">

              <div className="row  pt-1">
                <div className="col-12">
                <strong>$30 - Regular Spa Pedicure - soothing foot bath</strong>, and cleansing of nails shaping cuticle treatments. 
                Finished with a relaxing foot/leg massage & nail polish of your choice (callus removal additional $5).
              
                </div>                
              </div>            
              <div className="row  pt-3">
                <div className="col-12">
                <strong>$40 - Salon Signature Spa Pedicure</strong> -  in addition to the classic Spa Pedicure, 
                we will focus on removing rough calluses with special scrubs: Tropical Citrus.              
                </div>                
              </div>            
              <div className="row  pt-3 ">
                <div className="col-12">
                <strong>$50 - Deluxe Spa Pedicure - 40 mins</strong>, includes our Salon Signature Spa Pedicure, the VOESH 4 steps organic product: 
                <br />
                (1.  Sea Salt Soak, 2. Moisture Scrub, 3. Mud Masque, 4. Massage Lotion) and a choice of any PEDI in the Box Deluxe* flavor and Paraffin Wax.                  

                  <ol className="list-group list-group-numbered list-group-flush  p-3">
                    <li className="list-group-item bg-light">Milk & Honey (Congestion Relief)</li>
                    <li className="list-group-item bg-light">Lavender Relieve (Stressed?)</li>
                    <li className="list-group-item bg-light">Cucumber Fresh (Freshen Your Feet?) </li>
                    <li className="list-group-item bg-light">Lemon Quench (Dry Skin?)</li>
                    <li className="list-group-item bg-light">Mango (Hydrating Relief) [See more information on the back]</li>
                  </ol>
                </div>                
              </div>           
              <div className="row  pt-3">
                <div className="col-12">
                <strong>$60 - Nu Pedicure - 40 mins</strong>, in addition to the Salon Signature Spa Pedicure, we will use the Nu-Skin products
                 (a proprietary ingredient blend that targets aging at its source to immediately feel refreshed, hydrated, and incredibly soft & clean ) and paraffin wax.
                            
                </div>                
              </div>              
              <div className="row  pt-3">
                <div className="col-12">
                <strong>$70 - Hot Stone Pedicure - 50 mins</strong> -  in addition paraffin wax, you will experience 5 steps COLLAGEN VOLCANO PRODUCT: 
                <br />
                1. Detox Volcano Crystal,<br /> 
                2. Detox Volcano Activate, <br />
                3. Exfoliation Sugar Scrub, <br />
                4. Collagen Cream Mask, <br />
                5. Collagen Massage Lotion and your choice of any 5  PEDI in the box Volcano Flavor), and you will get to experience our Hot Stone Massage, specialized to heal hurting or swollen feet/ankles and combine with essential oils, antioxidants & conditioners to moisturize and soothe the skin.
                       
                </div>                
              </div>              
              <div className="row  pt-3 ">
                <div className="col-12">
                <strong>$75 - All-Inclusive Pedicure - 55 min</strong>, in addition to the Deluxe Spa Pedicure and Hot Lava Stone massage
                 (Just close your eyes and enjoy the service)            
                </div>                
              </div>       
              
            </div>
          </div>
          <div className="tab-pane fade" id="kt_tab_pane_kid" role="tabpanel">
            
            <div className="container p-0 pb-5">            
              <div className="row mb-4">
                <div className="col-12 "><strong className='fs-2 border-bottom border-primary'>KID SERVICES (Kids under 8 Years of age)</strong></div>
              </div>            
              <div className="row  pt-3 bg-odd bg-gradient">
                <div className="col-8">Manicure</div>
                <div className="col-4 text-end">$15</div>
              </div>            
              <div className="row  pt-3 bg-white bg-gradient">
                <div className="col-8">Pedicure</div>
                <div className="col-4 text-end">$25</div>
              </div>            
              <div className="row  pt-3 bg-odd bg-gradient">
                <div className="col-8">Nails Polish</div>
                <div className="col-4 text-end">$8 &amp; up</div>
              </div>
            </div>

           

          </div>
          <div className="tab-pane fade" id="kt_tab_pane_alacater" role="tabpanel">

          <div className="container p-0 pb-5">            
              <div className="row mb-4">
                <div className="col-12 "><strong className='fs-2 border-bottom border-primary'>A-LA-CATER</strong></div>
              </div>            
              <div className="row  pt-3 bg-odd bg-gradient">
                <div className="col-8">Nail Removal</div>
                <div className="col-4 text-end">$10 &amp; up</div>
              </div>            
              <div className="row  pt-3 bg-white bg-gradient">
                <div className="col-8">Shellac (Gel)</div>
                <div className="col-4 text-end">$8</div>
              </div>            
              <div className="row  pt-3 bg-odd bg-gradient">
                <div className="col-8">French Polish add</div>
                <div className="col-4 text-end">$5</div>
              </div>            
              <div className="row  pt-3 bg-white bg-gradient">
                <div className="col-8">Coffin Shape add</div>
                <div className="col-4 text-end">$5</div>
              </div>            
              <div className="row  pt-3 bg-odd bg-gradient">
                <div className="col-8">Long Nails Set add</div>
                <div className="col-4 text-end">$5</div>
              </div>            
              <div className="row  pt-3 bg-white bg-gradient">
                <div className="col-8">Nail Repair 1</div>
                <div className="col-4 text-end">$3</div>
              </div>            
              <div className="row  pt-3 bg-odd bg-gradient">
                <div className="col-8">Nail Repair 2</div>
                <div className="col-4 text-end">$5</div>
              </div>            
              <div className="row  pt-3 bg-white bg-gradient">
                <div className="col-8">Nail Repair 3 &amp; up</div>
                <div className="col-4 text-end">$7</div>
              </div>            
              <div className="row  pt-3 bg-odd bg-gradient">
                <div className="col-8">Hand Polish</div>
                <div className="col-4 text-end">$10</div>
              </div>            
              <div className="row  pt-3 bg-white bg-gradient">
                <div className="col-8">Toe Polish</div>
                <div className="col-4 text-end">$15</div>
              </div>            
              <div className="row  pt-3 bg-odd bg-gradient">
                <div className="col-8">3D Reflection Gel Polish</div>
                <div className="col-4 text-end">Extra $15</div>
              </div>            
              <div className="row  pt-3 bg-white bg-gradient">
                <div className="col-8">Chrome Gel All</div>
                <div className="col-4 text-end">Extra $15</div>
              </div>             
              <div className="row  pt-3 bg-odd bg-gradient">
                <div className="col-8">Chrome Gel Polish for 2 nails only</div>
                <div className="col-4 text-end">Extra $8</div>
              </div>            
              <div className="row  pt-3 bg-white bg-gradient">
                <div className="col-8">3D Gel Reflection Design for 2 nails only</div>
                <div className="col-4 text-end">Extra $8</div>
              </div>            
              <div className="row  pt-3 bg-odd bg-gradient">
                <div className="col-8">3D Jewelry add</div>
                <div className="col-4 text-end">Extra $5</div>
              </div>            
              <div className="row  pt-3 bg-white bg-gradient">
                <div className="col-8">Special VOESH Collagen Manicure Glov</div>
                <div className="col-4 text-end">Extra $8</div>
              </div>
            </div>
          </div>
        </div>




        {/* <div className="accordion accordion-icon-toggle" id="kt_accordion_service">
{serviceData.map((groups, index) => (
  <div className="mb-5">
    <div
      className= {"accordion-header py-3 d-flex"+(index !== 0?" collapsed":"")}    
      data-bs-toggle="collapse"
      data-bs-target={"#kt_accordion_service_item_"+index}
    >
      <span className="accordion-icon">
        <KTSVG
          className="svg-icon svg-icon-4"
          path="/media/icons/duotune/arrows/arr064.svg"
        />
      </span>
      <h3 className="fs-4 text-gray-800 fw-bold mb-0 ms-4">
        {groups.title}
      </h3>
    </div>
    <div
      id={"#kt_accordion_service_item_"+index}
      className={"fs-6 collapse"+(index === 0?" show":"")+" ps-10"}
      data-bs-parent="#kt_accordion_service"
    >
       <Fragment>{groups.description}</Fragment>
    </div>
  </div>
)
)}
  
</div> */}

      </SlidingPane>
    </>
  );
};


export default Service;