import React, { FC } from 'react';
import SlidingPane from "react-sliding-pane";
import galleryIcon from '../asset/icons/photos-64.png';
import LightGallery from 'lightgallery/react';
import * as homeRedux from '../HomeRedux'


// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';


// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

import { Image } from '../types/Image';
import galleryData from '../asset/gallery.json';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../setup';

const PUBLIC_GALLERY = process.env.REACT_APP_GALLERY

  const Gallery: FC = () => {
    const dispatch = useDispatch()
    const paneOpened = useSelector<RootState>(({home}) => home.pane_name, shallowEqual)
      
    const openPane = (name: string | null) => {
      if(paneOpened === name){
        name = null ;
      }
      dispatch(homeRedux.actions.changedPane(name));
    }

    
    const my_gallery: Image[] = (galleryData as any[]).map((x) => ({
      ...x,
      name: x.name,
      description: x.description,
      image: x.image,
      media: x.small,
    }));
    my_gallery.sort(() => Math.random() - 0.5); 

    return (
      <>
      <div className="icon-task" title="Gallery"  onClick={() => openPane('gallery')}>  
        <img src={galleryIcon} className="icon-label" alt='Gallery'/> <span className="d-none d-sm-block">Gallery</span>
     </div> 

<SlidingPane
className="sapa-panel-sliding gallery-panel-sliding"
overlayClassName="sapa-overlay-panel-sliding"
isOpen={paneOpened === 'gallery'}
width="72.2%"
hideHeader
shouldCloseOnEsc  
from="bottom"
onRequestClose={() => {
  // triggered on "<" on left top click or on outside click
  openPane(null);
}}
>
{/* <div className="gallery-container"> */}
    <LightGallery 
        //onInit={onInit}
        speed={500}
        plugins={[lgThumbnail, lgZoom]}
        mode="lg-fade"
    >
       {/* //data-sub-html={'<h4 class="text-primary">Photo by -  '+x.name+'</h4><p>'+x.description+'</p>'} */}
      {my_gallery.map((x, index) => (
        <a 
            key={index}
            data-lg-size="1406-1390"
            className="gallery-item" 
            data-src={PUBLIC_GALLERY +  x.image}
            data-sub-html= ''
            >
          <img src={PUBLIC_GALLERY +  x.image} className="center-cropped nature"  alt=''/> 
        </a>
        // <li className="flex-item" >
        //     <img src={x.media} alt={x.name}/> 
        // </li>

      )
      )}
    </LightGallery>
{/* </div> */}
</SlidingPane>
</>
    );
  };
  
  
export default Gallery;


