import React from 'react';
import moment from 'moment';
import { CouponModel } from '../../models/CouponModel';

type Props = {
    data: Array<CouponModel>,
    onEdit: (coupon: CouponModel) => void,
    onDelete: (coupon: CouponModel) => void
};


const CouponTable: React.FC<Props> = ({ data, onEdit, onDelete}) => {

    return (
        <div className='table-responsive'>
            <table className="table table-hover table-rounded table-striped border gy-7 gs-7">
                <thead>
                    <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                        <th>
                            No
                        </th>
                        <th>Code</th>
                        <th>Start date</th>
                        <th>Expired date</th>
                        <th>Created at</th>
                    </tr>
                </thead>
                <tbody>
                    {data.length > 0 && data.map((item: CouponModel, index: number) => (
                        <tr key={item.id}>
                            <td>
                                {index + 1}
                            </td>
                            <td>{item.code || ''}</td>
                            <td>{(item.start_date) ? moment(item.start_date).format('MM/DD/YYYY') : ''}</td>
                            <td>{(item.expired_date) ? moment(item.expired_date).format('MM/DD/YYYY') : ''}</td>
                            <td>{(item.created_at) ? moment(item.created_at).format('MM/DD/YYYY H:m') : ''}</td>
                            <td>
                                <button className="btn btn-icon btn-warning w-30px h-30px ps-2 me-2" onClick={() => onEdit(item)}>
                                        <i className="bi bi-pencil-square fs-4 me-2"></i>
                                    </button>
                                <button className="btn btn-icon btn-danger w-30px h-30px ps-2" onClick={() => onDelete(item)}>
                                    <i className="bi bi-trash fs-4 me-2"></i>
                                </button>
                            </td>
                        </tr>
                    ))
                    }
                </tbody>
            </table>
        </div>
    );
}

export default CouponTable;