/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import queryString  from 'query-string';
import { BookingModel, ModalModel, SearchModel } from '../../models/BookingModel';
import * as checkinCRUD from './checkinCRUD';
import { Toast } from '../../modules/common/helper'
import CheckinModal from './CheckinModal';
import { StaffModel } from '../../models/StaffModel';
import { ResponseModel } from '../../models/ResponseModel';
import _ from 'lodash';
import moment from 'moment';
import CheckOutModal from './CheckOutModal';
import CheckCustomerModal from './CheckCustomerModal';
import { getMessaging, onMessage } from "firebase/messaging";
import RatingDetailsModal from './RatingDetailsModal';
import { useHistory } from 'react-router-dom';
import { DATA_PAGINATION_LENGTH, Paginate, PaginationLength } from '../../../_metronic/partials';
import { PaginateModel } from '../../models/PaginateModel';
import CheckinsTable from './CheckinsTable';

const Checkin: React.FC = () => {
    const history = useHistory();
    const [search, setSearch] = useState<SearchModel>({
        page: 1,
        per_page: DATA_PAGINATION_LENGTH[0],
    })
    const [dataPaginate, setDataPaginate] = useState<PaginateModel<BookingModel>>();

    const [checkinData, setData] = useState<ResponseModel>();
    const [validDate, setValidDate] = useState<string>();
    const [discount, setDiscount] = useState<number>(0);
    const [discountMsg, setDiscountMsg] = useState<string>();
    const [customer, setCustomer] = useState<string>();
    const [checkinModal, setModal] = useState<ModalModel>({
        show: false,
        mode: 'add',
    });
    const [ratingModal, setRatingModal] = useState<ModalModel>({
        show: false,
        mode: 'add',
    });
    const [checkCustomerModal, setCustomerModal] = useState<ModalModel>({
        show: false,
        mode: 'add',
    });
    const [checkOutModal, setCheckOutModal] = useState<ModalModel>({
        show: false,
        mode: 'add',
    });

    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
        //getData();
        //console.log(payload?.notification?.body);
    });
    useEffect(() => {
        const initParams: SearchModel = parseParams(queryString.parse(history.location.search))
        getData({ ...search, ...initParams });
    }, []);

    const parseParams = (params: SearchModel) => {
        if (params.page) {
            params.page = +params.page
        }
        if (params.per_page) {
            params.per_page = +params.per_page
        }
        return params
    }

    const getData = async (params: SearchModel) => {
        const { data: dataPaginate } = await checkinCRUD.getCheckinList(params);
        const objQuery = { ...params }
        if (objQuery.page === 1) {
            delete objQuery.page
        }
        if (objQuery.per_page === DATA_PAGINATION_LENGTH[0]) {
            delete objQuery.per_page
        }
        history.push({ search: `?${queryString.stringify(objQuery)}` })
        setDataPaginate(dataPaginate);
        setSearch(params);
    }

    const handleSubmit = async (values: any, actions: any) => {
        let result;
        if (checkinModal.mode === 'edit') {
            result = checkinCRUD.update({...values, action: 'checkin'});
        } else {
            result = checkinCRUD.store({...values, action: 'checkin'});
        }
        result.then(res => {
            if (res.data.status) {
                Toast.fire({
                    icon: 'success',
                    title: 'Success'
                })
                setModal({ ...checkinModal, show: false })
                getData({...search, page: 1})
            } else {
                if (res.data.valid_date) {
                    setValidDate(res.data.valid_date);
                }
                Toast.fire({
                    icon: 'error',
                    title: 'Server error'
                })
            }
        }).catch(error => {
            const response = error.response
            let message: string = '';
            if (response?.status === 422) {
                if (response?.data?.errors) {
                    const arrMess = [];
                    for (const [key, mess] of Object.entries(response?.data?.errors)) {
                        arrMess.push(mess);
                    }
                    message = arrMess.join("\n")
                }
            } else {
                message = 'Server error'
            }
            Toast.fire({
                icon: 'error',
                title: message
            })
        });
    };

    const handleUpdateServices = async (value: any) => {
        let result = checkinCRUD.updateServices(value);
        result.then(res => {
            let bookingData = res.data?.data;
            setCheckOutModal({ ...checkOutModal, data: bookingData })
        }).catch(error => {
            const response = error.response
            let message: string = '';
            if (response?.status === 422) {
                if (response?.data?.errors) {
                    const arrMess = [];
                    for (const [key, mess] of Object.entries(response?.data?.errors)) {
                        arrMess.push(mess);
                    }
                    message = arrMess.join("\n")
                }
            } else {
                message = 'Server error'
            }
            Toast.fire({
                icon: 'error',
                title: message
            })
        });
    };

    const handleCheckCustomer = async (values: any, actions: any) => {
        let result = checkinCRUD.checkCustomer({phone: values.phone});
        result.then(res => {
            setCustomerModal({ ...checkCustomerModal, show: false })
            if (res.data.data.booking) {
                setModal({ ...checkinModal, show: true, mode: 'edit', data: res.data.data.booking[0] })
            } else {
                setCustomer(res.data.data.customer);
                setModal({ ...checkinModal, show: true, mode: 'add' })
            }
        }).catch(error => {
            const response = error.response
            let message: string = '';
            if (response?.status === 422) {
                if (response?.data?.errors) {
                    const arrMess = [];
                    for (const [key, mess] of Object.entries(response?.data?.errors)) {
                        arrMess.push(mess);
                    }
                    message = arrMess.join("\n")
                }
            } else {
                message = 'Server error'
            }
            Toast.fire({
                icon: 'error',
                title: message
            })
        });
    };

    const handleSubmitCheckout = async (values: any, actions: any) => {
        let result = checkinCRUD.checkout({
            ...values,
            earning_points: values.earning_points.replace("$", "")
        });
        result.then(res => {
            if (res.data.status) {
                Toast.fire({
                    icon: 'success',
                    title: 'Success'
                })
                setCheckOutModal({ ...checkOutModal, show: false })
                getData({...search, page: 1})
            } else {
                if (res.data.valid_date) {
                    setValidDate(res.data.valid_date);
                }
                Toast.fire({
                    icon: 'error',
                    title: 'Server error'
                })
            }
        }).catch(error => {
            const response = error.response
            let message: string = '';
            if (response?.status === 422) {
                if (response?.data?.errors) {
                    const arrMess = [];
                    for (const [key, mess] of Object.entries(response?.data?.errors)) {
                        arrMess.push(mess);
                    }
                    message = arrMess.join("\n")
                }
            } else {
                message = 'Server error'
            }
            Toast.fire({
                icon: 'error',
                title: message
            })
        });
    };
    
    const updateDiscount = (values: any) => {
        let data = {
            id: values.id,
            discount_type: values.discount_type,
            use_points: values.use_points,
            coupon: values.coupon
        }
        let result = checkinCRUD.updateDiscount(data);
        result.then(res => {
            setDiscountMsg(res.data.data?.error);
            setDiscount(res.data.data?.discount);
        }).catch(error => {
            const response = error.response
            let message: string = '';
            if (response?.status === 422) {
                if (response?.data?.errors) {
                    const arrMess = [];
                    for (const [key, mess] of Object.entries(response?.data?.errors)) {
                        arrMess.push(mess);
                    }
                    message = arrMess.join("\n")
                }
            } else {
                message = error.response.message
            }
            Toast.fire({
                icon: 'error',
                title: message
            })
        });
    }
    
    const handlePageChange = (data: any) => {
        getData({ ...search, page: data.selected + 1 })
    }

    const handlePageLengthChange = (e: any) => {
        getData({ ...search, page: 1, per_page: +e.target.value })
    }

    const showCheckoutModal = (item: BookingModel) => {
        setCheckOutModal({
            data: item,
            show: true,
            mode: 'edit'
        })
    }

    const showEditModal = (item: BookingModel) => {
        setModal({
            data: item,
            show: true,
            mode: 'edit'
        })
    }

    const showRatingModal = (item: BookingModel) => {
        setRatingModal({
            data: item,
            show: true,
            mode: 'edit'
        })
    }

    return (
        <>
            <div className='card'>
                <div className="card-body">
                    <div className="row">
                        <CheckinsTable 
                            data={dataPaginate?.data || []}
                            onCheckOut={showCheckoutModal}
                            onEdit={showEditModal}
                            onViewRating={showRatingModal}
                        />
                    </div>
                </div>
                <div className="card-footer d-flex justify-content-between">
                    <PaginationLength selected={search.per_page || DATA_PAGINATION_LENGTH[0]} onChange={handlePageLengthChange} />
                    <Paginate
                        pageCount={dataPaginate?.meta?.last_page || 0}
                        onPageChange={handlePageChange}
                        forcePage={(search.page || 1) - 1}
                    />
                </div>
            </div>
            <CheckinModal
                {...checkinModal}
                validDate={validDate}
                customer={customer}
                onClose={() => { setModal({ ...checkinModal, show: false }) }}
                onSubmit={handleSubmit}
            />
            <RatingDetailsModal
                {...ratingModal}
                onClose={() => { setRatingModal({ ...ratingModal, show: false }) }}
            />
            <CheckCustomerModal
                {...checkCustomerModal}
                onClose={() => { setCustomerModal({ ...checkCustomerModal, show: false }) }}
                onSubmit={handleCheckCustomer}
            />
            <CheckOutModal
                {...checkOutModal}
                discount={discount}
                discountMsg={discountMsg}
                updateDiscount={updateDiscount}
                onClose={() => {setCheckOutModal({ ...checkOutModal, show: false }) }}
                onSubmit={handleSubmitCheckout}
                handleUpdateServices={handleUpdateServices}
            />
        </>
    )
}

export { Checkin }
