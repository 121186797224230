import React, { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { resetPassword } from '../redux/AuthCRUD'

const initialValues = {
    email: new URLSearchParams(window.location.search).get("email") ?? '',
    password: '',
    password_confirmation: '',
    token: new URLSearchParams(window.location.search).get("token") ?? ''
}

const resetPasswordSchema = Yup.object().shape({
    email: Yup.string()
        .email('Wrong email format')
        .required('Email is required'),
    password: Yup.string()
        .min(6, 'Minimum 6 symbols')
        .required('Password is required'),
    password_confirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')

})


export function ResetPassword() {
    const [loading, setLoading] = useState(false)
    const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
    const formik = useFormik({
        initialValues,
        validationSchema: resetPasswordSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            setHasErrors(undefined)
            setTimeout(() => {
                resetPassword(values.token, values.email, values.password, values.password_confirmation)
                    .then(({ data: { result } }) => {
                        setHasErrors(false)
                        setLoading(false)
                    })
                    .catch(() => {
                        setHasErrors(true)
                        setLoading(false)
                        setSubmitting(false)
                        setStatus('The login detail is incorrect')
                    })
            }, 1000)
        },
    })
    return (
        <>
            <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_login_password_reset_form'
                onSubmit={formik.handleSubmit}
            >
                <div className='text-center mb-10'>
                    {/* begin::Title */}
                    <h1 className='text-dark mb-3'>Reset new password</h1>
                    {/* end::Title */}

                    {/* begin::Link */}
                    <div className='text-gray-400 fw-bold fs-4'>Enter your new password.</div>
                    {/* end::Link */}
                </div>

                {/* begin::Title */}
                {hasErrors === true && (
                    <div className='mb-lg-15 alert alert-danger'>
                        <div className='alert-text font-weight-bold'>
                            Sorry, looks like there are some errors detected, please try again.
                        </div>
                    </div>
                )}

                {hasErrors === false && (
                    <div className='mb-10 bg-light-info p-8 rounded'>
                        <div className='text-info'>Password changed. Please login to continue!</div>
                    </div>
                )}
                {hasErrors !== false && (
                    <>
                        <div className='fv-row mb-10'>
                            <label className='form-label fw-bolder text-gray-900 fs-6'>
                                <span className="required">Email</span>
                            </label>
                            <input
                                type='email'
                                placeholder=''
                                readOnly
                                autoComplete='off'
                                {...formik.getFieldProps('email')}
                                className={clsx(
                                    'form-control form-control-lg form-control-solid',
                                    { 'is-invalid': formik.touched.email && formik.errors.email },
                                    {
                                        'is-valid': formik.touched.email && !formik.errors.email,
                                    }
                                )}
                            />
                            {formik.touched.email && formik.errors.email && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.email}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='fv-row mb-10'>
                            <label className='form-label fw-bolder text-gray-900 fs-6'>
                                <span className="required">Password</span>
                            </label>
                            <input
                                type='password'
                                placeholder=''
                                autoComplete='off'
                                {...formik.getFieldProps('password')}
                                className={clsx(
                                    'form-control form-control-lg form-control-solid',
                                    { 'is-invalid': formik.touched.password && formik.errors.password },
                                    {
                                        'is-valid': formik.touched.password && !formik.errors.password,
                                    }
                                )}
                            />
                            {formik.touched.password && formik.errors.password && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.password}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='fv-row mb-10'>
                            <label className='form-label fw-bolder text-gray-900 fs-6'>
                                <span className="required">Confirm Password</span>
                            </label>
                            <input
                                type='password'
                                placeholder=''
                                autoComplete='off'
                                {...formik.getFieldProps('password_confirmation')}
                                className={clsx(
                                    'form-control form-control-lg form-control-solid',
                                    { 'is-invalid': formik.touched.password_confirmation && formik.errors.password_confirmation },
                                    {
                                        'is-valid': formik.touched.password_confirmation && !formik.errors.password_confirmation,
                                    }
                                )}
                            />
                            {formik.touched.password_confirmation && formik.errors.password_confirmation && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.password_confirmation}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                )}
                <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                    {hasErrors !== false && (
                        <button
                            type='submit'
                            id='kt_password_reset_submit'
                            className='btn btn-lg btn-primary fw-bolder me-4'
                        >
                            <span className='indicator-label'>Submit</span>
                            {loading && (
                                <span className='indicator-progress'>
                                    Please wait...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    )}
                    <Link to='/auth/login'>
                        <button
                            type='button'
                            id='kt_login_password_reset_form_cancel_button'
                            className='btn btn-lg btn-light-primary fw-bolder'
                            disabled={formik.isSubmitting || !formik.isValid}
                        >
                            Login
                        </button>
                    </Link>{' '}
                </div>
            </form>
        </>
    )
}
