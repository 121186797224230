import axios from 'axios'
import queryString  from 'query-string';
import { BookingModel, SearchModel, UpdateDiscountModel } from '../../models/BookingModel';
import { ResponseModel } from '../../models/ResponseModel';
import { ADMIN_ENDPOINT_BOOKINGS, ADMIN_ENDPOINT_CHECKIN, ADMIN_ENDPOINT_CHECKOUT, ADMIN_ENDPOINT_CHECK_CUSTOMER, ADMIN_ENDPOINT_CUSTOMERS, ADMIN_ENDPOINT_POST_RATING, ADMIN_ENDPOINT_UPDATE_BOOKING_SERVICES, ADMIN_ENDPOINT_UPDATE_DISCOUNT } from '../../constants/endpoints';
import { CustomerModel } from '../../models/CustomerModel';
import { PaginateModel } from '../../models/PaginateModel';

export function getCheckinList(search: SearchModel) {
  return axios.get<PaginateModel<BookingModel>>(`${ADMIN_ENDPOINT_CHECKIN}?${queryString.stringify(search, {arrayFormat: 'bracket'})}`)
}

export function store(booking: BookingModel) {
  return axios.post<ResponseModel>(ADMIN_ENDPOINT_BOOKINGS, booking);
}
export function update(booking: BookingModel) {
  return axios.put<ResponseModel>(ADMIN_ENDPOINT_BOOKINGS, booking);
}
export function updateServices(booking: BookingModel) {
  return axios.put<ResponseModel>(ADMIN_ENDPOINT_UPDATE_BOOKING_SERVICES, booking);
}
export function checkCustomer(data: {phone: string}) {
  return axios.post<ResponseModel>(ADMIN_ENDPOINT_CHECK_CUSTOMER, data);
}
export function updateDiscount(data: UpdateDiscountModel) {
  return axios.post<ResponseModel>(ADMIN_ENDPOINT_UPDATE_DISCOUNT, data);
}
export function checkout(booking: BookingModel) {
  return axios.post<ResponseModel>(ADMIN_ENDPOINT_CHECKOUT, booking);
}

export function createAccount(customer: CustomerModel) {
  return axios.put<ResponseModel>(ADMIN_ENDPOINT_CUSTOMERS, customer);
}

export function postRating(id: number | undefined, rating_stars: number, rating_note: string | undefined) {
  return axios.post<ResponseModel>(ADMIN_ENDPOINT_POST_RATING, {id: id, rating_stars: rating_stars, rating_note: rating_note ?? null});
}
