import React, { FC } from 'react'
import _ from 'lodash'
//import { Appointment } from '../../types/Appointment'

type Props = {
    isActive: boolean
}

const AppointmentStep5: FC<Props> = ({ isActive }) => {
    return (
        <>
        { isActive && (
            <div className='w-100 text-center'>
                <h1 className='fw-bolder text-light mb-3'>We will confirm your appointment shortly.</h1>
                <div className=' fw-bold fs-3'>
                    Thank you for your bussiness.
                </div>
            </div>
        )}
        </>
    )
}

export default AppointmentStep5
