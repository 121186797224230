/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Chart from "react-apexcharts";
import React from 'react'

type Props = {
    chartColor: Array<any>,
    customersData: any,
}

const CustomerLevelChart: React.FC<Props> = ({ chartColor, customersData }) => {
    return (
        <Chart
            options={{
                chart: {
                    type: 'donut',
                },
                colors: chartColor,
                labels: (customersData.labelLv),
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
                legend: {
                    show: false,
                }
            }}

            series={customersData.serialLv}
            type="donut"
        />
    )
}

export { CustomerLevelChart }
