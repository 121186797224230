export const ADMIN_API_URL = `${process.env.REACT_APP_API_URL}/api/admin`

export const ADMIN_ENDPOINT_BOOKINGS = `${ADMIN_API_URL}/bookings`
export const ADMIN_ENDPOINT_UPDATE_BOOKING_SERVICES = `${ADMIN_API_URL}/update-selected-services`
export const ADMIN_ENDPOINT_SERVICES = `${ADMIN_API_URL}/services`
export const ADMIN_ENDPOINT_COUPONS = `${ADMIN_API_URL}/coupons`
export const ADMIN_ENDPOINT_AVAILABLE_COUPONS = `${ADMIN_API_URL}/coupons/available`
export const ADMIN_ENDPOINT_STAFFS= `${ADMIN_API_URL}/staffs`
export const ADMIN_ENDPOINT_CHECKIN = `${ADMIN_API_URL}/checkins`
export const ADMIN_ENDPOINT_CHECKOUT = `${ADMIN_API_URL}/checkout`
export const ADMIN_ENDPOINT_UPDATE_DISCOUNT = `${ADMIN_API_URL}/update-discount`
export const ADMIN_ENDPOINT_CHECK_CUSTOMER = `${ADMIN_API_URL}/customers/customer-check`
export const ADMIN_ENDPOINT_POST_RATING = `${ADMIN_API_URL}/checkins/post-rating`
export const ADMIN_ENDPOINT_CUSTOMERS = `${ADMIN_API_URL}/customers`
export const API_ENDPOINT_REPORT = `${ADMIN_API_URL}/reports`
export const ENDPOINT_STAFFS = `${process.env.REACT_APP_API_URL}/api/get-staffs`
export const ENDPOINT_SEARCH_CUSTOMER_MARKETING = `${ADMIN_API_URL}/marketing/search-customer-campaign`;
export const ENDPOINT_UPSERT_MARKETING = `${ADMIN_API_URL}/marketing/upsert`;
export const ADMIN_ENDPOINT_MARKETING = `${ADMIN_API_URL}/marketing`;
export const ADMIN_ENDPOINT_MARKETING_MASTER = `${ADMIN_ENDPOINT_MARKETING}/master`;
export const ADMIN_ENDPOINT_MARKETING_STATUS = `${ADMIN_ENDPOINT_MARKETING}/update-status`;
