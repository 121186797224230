import React, { FC } from 'react'
import _ from 'lodash'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { CustomerModel } from '../../models/CustomerModel'
import * as Yup from 'yup'

type Props = {
    data?: CustomerModel
    handleBack: () => void,
    onSubmit: (values: any) => void
}

const validSchema = Yup.object().shape({
    name: Yup.string().required().label('Your name'),
    email: Yup.string().required().email().label('Your email'),
})

const CreateAccStep: FC<Props> = ({handleBack, onSubmit, data}) => {
    let initValues: any = {
        id: data?.id ?? null,
        name: '',
        phone: data?.phone ?? null,
        email: null,
    };
    return (
        <Formik validationSchema={validSchema} initialValues={initValues} onSubmit={onSubmit}>
            {({values, handleChange}) => {
                return (
                    <Form>
                        <div className="d-flex flex-column gap-5 mb-4 ">   
                            <div className="fv-row flex-row-fluid fv-plugins-icon-container">
                                <label className="required form-label">Your name</label>
                                <Field
                                    type='text'
                                    className='form-control'
                                    placeholder=''
                                    autoComplete='nope'
                                    name='name'
                                />
                                <div className="fv-plugins-message-container invalid-feedback"><ErrorMessage name='name' /></div>
                            </div> 
                            <div className="fv-row flex-row-fluid fv-plugins-icon-container">
                                <label className="required form-label">Your phone</label>
                                <Field
                                    type='text'
                                    className='form-control'
                                    placeholder=''
                                    autoComplete='nope'
                                    disabled
                                    name='phone'
                                />
                                <div className="fv-plugins-message-container invalid-feedback"><ErrorMessage name='phone' /></div>
                            </div> 
                            <div className="fv-row flex-row-fluid fv-plugins-icon-container">
                                <label className="required form-label">Your email</label>
                                <Field
                                    type='text'
                                    className='form-control'
                                    placeholder=''
                                    autoComplete='nope'
                                    name='email'
                                />
                                <div className="fv-plugins-message-container invalid-feedback"><ErrorMessage name='email' /></div>
                            </div>     
                        </div>
                        <div className='d-flex mb-4 justify-content-between'>   
                            <button onClick={handleBack} className="btn btn-bg-warning" type="button">Back</button>
                            <button className="btn btn-bg-success ms-auto" type="submit">Next</button>
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default CreateAccStep
