/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Modal } from 'react-bootstrap-v5'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import Flatpickr from 'react-flatpickr'
import moment from 'moment';
import { CustomerModel } from '../../models/CustomerModel'
import { customerGroups, customerTypes, NEW_TYPE } from '../../constants/commons'

type Props = {
    show: boolean
    mode: string
    data?: CustomerModel
    onClose: () => void
    onSubmit: (values: any, actions: any) => void
}
const addSchema = Yup.object().shape({
    name: Yup.string().required().label('Name'),
    email: Yup.string().required().email().label('Email'),
    phone: Yup.number().required().label('Phone'),
    type: Yup.string().required().label('Level')
})

const editSchema = Yup.object().shape({
    name: Yup.string().required().label('Name'),
    email: Yup.string().required().email().label('Email'),
    phone: Yup.number().required().label('Phone'),
    type: Yup.string().required().label('Level')
})

const CustomerModal: React.FC<Props> = ({ show, mode, data, onClose, onSubmit }) => {
    let initValues: any = {
        type: customerTypes[NEW_TYPE],
        name: '',
        email: '',
        phone: '',
        points: 0,
        bod: null,
        bom: null
    };
    if (mode === 'edit' && data) {
        initValues = {
            id: data.id,
            type: data?.type ?? customerTypes[NEW_TYPE],
            name: data?.name ?? '',
            email: data?.email ?? '',
            phone: data?.phone ?? '',
            life_point: data?.life_point ?? 0,
            points: data?.points ?? 0,
            life_point_old: data?.life_point ?? 0,
            points_old: data?.points ?? 0,
            bod: data?.bod ?? null,
            bom: data?.bom ?? null
        };
    }
    return (
        <Modal
            show={show}
            onHide={onClose}
            aria-labelledby='contained-modal-title-vcenter'
            centered
        >
            <Modal.Header className='d-flex'>
                <Modal.Title>{mode === 'edit' ? 'Edit Customer' : 'Add Customer'}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-10">
                <Formik validationSchema={mode === 'edit' ? editSchema : addSchema} initialValues={initValues} onSubmit={onSubmit}>
                    {({ values, handleChange }) => (
                        <Form>
                            <div className="d-flex flex-column gap-5 mb-5">   
                                <div className="fv-row flex-row-fluid fv-plugins-icon-container">
                                    <label className="required form-label">Name1</label>
                                    <Field
                                        type='text'
                                        className='form-control'
                                        placeholder=''
                                        autoComplete='nope'
                                        name='name'
                                    />
                                    <div className="fv-plugins-message-container invalid-feedback"><ErrorMessage name='name' /></div>
                                </div>     
                                <div className="fv-row flex-row-fluid fv-plugins-icon-container">
                                    <label className="form-label">Points</label>
                                    <Field
                                        type='text'
                                        className='form-control'
                                        placeholder=''
                                        autoComplete='nope'
                                        name='points'
                                    />
                                    <div className="fv-plugins-message-container invalid-feedback"><ErrorMessage name='points' /></div>
                                </div>   
                                <div className="fv-row flex-row-fluid fv-plugins-icon-container">
                                    <label className="required form-label">Phone</label>
                                    <Field
                                        type='text'
                                        className='form-control'
                                        placeholder=''
                                        autoComplete='nope'
                                        name='phone'
                                    />
                                    <div className="fv-plugins-message-container invalid-feedback"><ErrorMessage name='phone' /></div>
                                </div>   
                                <div className="fv-row flex-row-fluid fv-plugins-icon-container">
                                    <label className="required form-label">Email</label>
                                    <Field
                                        type='text'
                                        className='form-control'
                                        placeholder=''
                                        autoComplete='nope'
                                        name='email'
                                    />
                                    <div className="fv-plugins-message-container invalid-feedback"><ErrorMessage name='email' /></div>
                                </div>
                                <div className="fv-row flex-row-fluid fv-plugins-icon-container">
                                    <label className="form-label required">Level</label>
                                    <Field as="select" name="type" className='form-control'>
                                        {Object.keys(customerTypes).map(function(key, index) {
                                            return <option key={"type"+key} value={customerTypes[key]}>{customerTypes[key]}</option>
                                        })}
                                    </Field>
                                    <div className="fv-plugins-message-container invalid-feedback"><ErrorMessage name='type' /></div>
                                </div>
                                <div className="fv-row flex-row-fluid fv-plugins-icon-container">
                                    <label className="required form-label">Group</label>
                                    <Field as="select" name="active_status" className='form-control'>
                                        {Object.keys(customerGroups).map(function(key, index) {
                                            return <option key={"active_status"+key} value={customerGroups[key]}>{customerGroups[key]}</option>
                                        })}
                                    </Field>
                                    <div className="fv-plugins-message-container invalid-feedback"><ErrorMessage name='active_status' /></div>
                                </div>
                                <div className="fv-row flex-row-fluid fv-plugins-icon-container">
                                    <div className='row'>
                                        <div className='col-6'>
                                            <label className="form-label">BOD</label>
                                            <Field as="select" name="bod" className='form-control'>
                                                {Array.apply(null, Array(31)).map(function(key, index) {
                                                    return <option key={"bod"+index} value={index + 1}>{index + 1}</option>
                                                })}
                                            </Field>
                                        </div>
                                        <div className='col-6'>
                                            <label className="form-label">BOM</label>
                                            <Field as="select" name="bom" className='form-control'>
                                                {Array.apply(null, Array(12)).map(function(key, index) {
                                                    return <option key={"bod"+index} value={index + 1}>{index + 1}</option>
                                                })}
                                            </Field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex mb-4 justify-content-between'>
                                <button className="btn btn-bg-secondary" type="button" onClick={() => onClose()}>Close</button>
                                <button className="btn btn-bg-success" type="submit">{mode === 'edit' ? 'Update' : 'Add New'}</button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}

export default CustomerModal
