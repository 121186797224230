/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { FC } from 'react'
import { Redirect, Switch, Route , useLocation} from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { PrivateRoutes } from './PrivateRoutes'
import { Logout } from '../modules/auth'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { RootState } from '../../setup'
import { CheckinRoutes } from './CheckinRoutes'
import RatingScreen from '../modules/ratingscreen/RatingScreen'
import HomeScreen from '../modules/homescreen/HomeScreen'
import { UserModel } from '../models/UserModel'
import { CHECKIN_SCREEN_ROUTE } from '../constants/routes'

const Routes: FC = () => {
    const isAuthorized = useSelector<RootState>(({ auth }) => auth.user, shallowEqual)
    const user: any = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
    
    const isCheckinUser = () => {
        return (user?.data?.is_checkin || user?.is_checkin)
    }
    return (
        <Switch>
            <Route exact path='/' component={HomeScreen} />
            <Route exact path='/rating/:id' component={RatingScreen} />
            <Route exact path='/error' component={ErrorsPage} />
            <Route exact path='/logout' component={Logout} />
            {(isAuthorized && isCheckinUser()) ? (
                <>
                    <CheckinRoutes />
                    <Redirect to={CHECKIN_SCREEN_ROUTE} />
                </>
            ) : (
                <PrivateRoutes />
                
            )}
        </Switch>
    )
}

export { Routes }
