import axios, { AxiosResponse } from 'axios'
import { UserModel } from '../../../models/UserModel'


const API_URL = `${process.env.REACT_APP_API_URL}/api/admin`

export const CSRF_URL = `${process.env.REACT_APP_API_URL}/sanctum/csrf-cookie`
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/common/get-user`
export const LOGIN_URL = `${API_URL}/login`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot-password`
export const RESET_PASSWORD_URL = `${API_URL}/reset-password`
export const UPDATE_DEVICE_TOKEN_URL = `${API_URL}/common/update-device-token`

export function getCsrfCookie() {
  return axios.get(CSRF_URL)
}

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {email, password})
}


// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {email})
}

// Server should return object => { result: boolean }
export function resetPassword(token: string, email: string, password: string, password_confirmation: string) {
  return axios.post<{result: {status_code: number; message: string}}>(RESET_PASSWORD_URL, {token, email, password, password_confirmation})
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<AxiosResponse<UserModel>>(GET_USER_BY_ACCESSTOKEN_URL)
}

export function updateDeviceToken(token:string) {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.post(UPDATE_DEVICE_TOKEN_URL, {token})
}
