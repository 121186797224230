import React, {FC} from "react";
import {Form, Formik} from "formik";
import * as marketingCRUD from "../MarketingCRUD";
import * as Yup from "yup";
import Detail from "./Detail";
import {Toast} from "../../../modules/common/helper";
import {useHistory} from "react-router-dom";
import {MARKETING_ROUTE} from "../../../constants/routes";

type Props = {}
const UpsertMarketing: FC<Props> = () => {

  const history = useHistory();

  const schema = Yup.object().shape({
    customers_in_campaign: Yup.mixed().test('object-requried', 'Customers In Campaign is a required field', val => val && !!Object.keys(val).length),
    campaign_name: Yup.string().required().label('Campaign Name'),
    coupon_code: Yup.string().required().label('Coupon Code'),
    message: Yup.string().required().label('Message'),
    date_start_send: Yup.date().required().label('Date to start sending sms'),
    test_email: Yup.string().email('Test email must be a valid email').nullable()
  });

  const initValues = {
    store_id: 1,
    customer_name: '',
    customer_birthday: '',
    customer_group: '',
    customers_in_campaign: {},
    campaign_name: '',
    coupon_code: '',
    date_start_send: '',
    message: '',
    test_number: '',
    test_email: ''
  };


  const handleSubmit = async (values: any) => {
    try {
      const res: any = await marketingCRUD.upsert(values);
      const isSuccess = !!res.data.status;
      const isTest = res.data.is_test ?? false;
      const icon = isSuccess ? 'success' : 'warning';
      const title = isSuccess ? (res.data?.message ?? 'Success') : (res.data?.message ?? 'Error');
      if (isSuccess && !isTest) {
        history.push(MARKETING_ROUTE);
      }
      Toast.fire({
        icon,
        title
      })
    } catch (error: any) {
      const response = error?.response
      let message: string = '';
      if (response?.status === 422) {
        if (response?.data?.errors) {
          const arrMess = [];
          for (const [key, mess] of Object.entries(response?.data?.errors)) {
            arrMess.push(mess);
          }
          message = arrMess.join("\n")
        }
      } else {
        message = 'Server error'
      }
      Toast.fire({
        icon: 'error',
        title: message
      })
    }
  };
  return <>
    <Formik validationSchema={schema} initialValues={initValues} onSubmit={handleSubmit}>
      <Form>
        <Detail handleSubmit={handleSubmit}/>
      </Form>
    </Formik>
  </>
}
export default UpsertMarketing;