
import { getMessaging } from "firebase/messaging";
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";

// Initialize the Firebase app in the service worker by passing in
// your app's Firebase config object.
// https://firebase.google.com/docs/web/setup#config-object
var firebaseConfig = {
    apiKey: 'AIzaSyCFkEBDDtgVbzPhxue0BhHOIJgpLiZTIPs',
    authDomain: 'nails-61ec0.firebaseapp.com',
    databaseURL: 'https://nails-61ec0.firebaseio.com',
    projectId: 'nails-61ec0',
    storageBucket: 'nails-61ec0.appspot.com',
    messagingSenderId: '641249319591',
    appId: '1:641249319591:web:90232e50c9f7c3fadd1982'
  };
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
// Retrieve an instance of Firebase Messaging so that it can handle background
// messages.
const messaging = getMessaging(app);

export { messaging };