import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {Booking} from './Booking'

const BookingWrapper: FC = () => {
  const breadcrumbs = [
    {
      title: 'Dashboard',
      path: '/',
      isActive: true,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Booking Management</PageTitle>
      <Booking />
    </>
  )
}

export default BookingWrapper
