import { Action } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { put, takeLatest } from 'redux-saga/effects'


export interface ActionWithPayload<T> extends Action {
    payload?: T
}

export const actionTypes = {
    activePane: '[activePane] Action',
    changedPane: '[changedPane] Action',
}

const initialPaneState: IPaneState = {
    pane_name: undefined,
}

export interface IPaneState {
    pane_name?: string
}

export const reducer = persistReducer(
    { storage, key: 'saga-pane', whitelist: ['pane_name'] },
    (state: IPaneState = initialPaneState, action: ActionWithPayload<IPaneState>) => {
        switch (action.type) {
            case actionTypes.activePane: {
                const pane_name = action.payload?.pane_name
                return { ...state, pane_name }
            }
            default:
                return state
        }
    }
)

export const actions = {
    activePane: (pane_name: string) => ({
        type: actionTypes.activePane,
        payload: { pane_name },
    }),
    changedPane: (pane_name: string | null) => ({
        type: actionTypes.changedPane,
        payload: { pane_name },
    })
}

function* activePaneSaga(action: any) {
    let paneName = action.payload.pane_name;
    if (paneName == null)  {
        paneName = initialPaneState.pane_name
    }
    yield put(actions.activePane(paneName))
}


export function* saga() {
    yield takeLatest(actionTypes.changedPane, activePaneSaga)
}