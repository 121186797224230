/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Modal, Card } from 'react-bootstrap-v5'
import { BookingDetailModel, BookingModel } from '../../models/BookingModel'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'
import { AsyncSelect2 } from '../../../_metronic/partials'
import { ADMIN_ENDPOINT_AVAILABLE_COUPONS, ADMIN_ENDPOINT_SERVICES, ADMIN_ENDPOINT_STAFFS } from '../../constants/endpoints'
import moment from 'moment'
import Numpad from 'react-numberpad';

type Props = {
    show: boolean
    mode: string
    data?: BookingModel
    discount: number
    discountMsg?: string
    onClose: () => void
    updateDiscount: (values: any) => void
    handleUpdateServices: (services: any) => void
    onSubmit: (values: any, actions: any) => void
}

const editSchema = Yup.object().shape({
    use_points: Yup.number().max(450).label('Use Point Value')
})

const CheckOutModal: React.FC<Props> = ({ show, data, discount, onClose, onSubmit, handleUpdateServices, updateDiscount }) => {
    let selectedServices: Array<{ value: number, label: string }> = [];
    if (data?.details) {
        data?.details.map((detail: BookingDetailModel) => (
            selectedServices = [...selectedServices, { value: detail.service_id, label: detail.service_name }]
        ))
    }
    let initValues = {
        id: data?.id,
        discount_type: '',
        use_points: 0,
        redem_points: 0,
        earning_points: '$',
        point_earning: '$',
        star_rate: data?.rating_stars ?? 1,
        coupon: {label: 'Promotion (0)', value: 'None'},
        payment_method: 'Cash',
        services: selectedServices,
        staff: { value: data?.staff?.id ?? '1', label: data?.staff?.name ?? ' ' },
        customer_name: null,
        updateDiscount: updateDiscount
    }
    let customerPoints = data?.customer?.points ?? 0;
    let bntPointStatus =(customerPoints < 450 ? true : false);

    
    return (
        <Modal
            show={show}
            onHide={onClose}
            aria-labelledby='contained-modal-title-vcenter'
            centered
            fullscreen
            // size={'xl'}
            dialogClassName="modal-checkout"
        //size="xl"
        >
            <Modal.Header className='d-flex' closeButton>
                <Modal.Title>Checkout Info </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
                <Formik validationSchema={editSchema} initialValues={initValues} onSubmit={onSubmit}>
                    {({ values, handleChange, setFieldValue }) => {
                        const handleChangeService = (e: any) => {
                            let booking = data;
                            handleUpdateServices({ ...booking, services: e.selected });
                            setFieldValue("services", e.target.value);
                        }
                        const regex = /\b(\w)/igm;
                        const setPhone = (numText: any) => {
                            let point_earning = values.earning_points.replace("$", "");
                            if (numText === 'X') {
                                point_earning = '';
                            } else if (numText === '.') {
                                if (point_earning === '') {
                                    point_earning = '0';
                                }
                                point_earning += numText;
                            } else {
                                if (point_earning === '0') {
                                    point_earning = '';
                                }
                                point_earning += numText;
                            }
                            setFieldValue("earning_points", '$' + point_earning);
                            
                        }

                        const renderCustomerType = (type: string) => {
                            let typeClass = "success";
                            type = type.toLowerCase();
                            switch (type) {
                                case 'new':
                                    typeClass = "success"
                                    break;
                                case 'silver':
                                    typeClass = "primary"
                                    break;
                                case 'gold':
                                    typeClass = "info"
                                    break;
                                case 'platinum':
                                    typeClass = "warning"
                                    break;
                                case 'vip':
                                    typeClass = "danger"
                                    break;
                                default:
                                    typeClass = "success"

                            }
                            return <span className={"badge badge-light-" + typeClass}>{type}</span>
                        }
                        return (
                            <Form className='h-100'>
                                <div className="row h-100">
                                    <div className="col-md-12 col-lg-6 col-xxl-6 pb-10">
                                        <Card className="h-100 bg-light border rounded ">
                                            <Card.Header className=" me-1 pe-0 ">
                                                <Card.Title className='w-100'>
                                                    <div className="row w-100">
                                                        <div className="col-3 d-flex align-items-center">Services</div>
                                                        <div className="col-9">
                                                            <AsyncSelect2
                                                                name="service"
                                                                className="w-100 fs-3 border border-primary rounded"
                                                                placeholder=""
                                                                isGray
                                                                sourceUrl={ADMIN_ENDPOINT_SERVICES}
                                                                defaultOptions
                                                                value={values.services}
                                                                default
                                                                isMulti
                                                                onChange={(selectedOption: any) => {
                                                                    values.services = selectedOption
                                                                    handleChangeService({
                                                                        selected: selectedOption,
                                                                        target: { name: 'service', value: selectedOption.value },
                                                                    })
                                                                    values.updateDiscount({ 
                                                                        ...values
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card.Title>
                                            </Card.Header>
                                            <Card.Body className="p-1">

                                                <Card.Title style={{ height: "35%" }}>

                                                    <table className='table table-striped gy-2 gs-4'>
                                                        <tbody>
                                                            {(data?.details && data?.details.map((item, index) => {
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td className='fw-bold fs-4'>{item.service_name}</td>
                                                                            {/* <td className="w-80px">${item.service_price}</td> */}
                                                                        </tr>
                                                                    </>
                                                                )
                                                            }))}
                                                        </tbody>
                                                    </table>
                                                </Card.Title>
                                                <Card.Text className='row'>
                                                    <div className="fs-1 col-md-6 col-lg-12 col-xxl-7">
                                                        <div className='d-flex p-4'>
                                                            <div className="fs-1 bnt-select-wrapper col-md-6 col-lg-6 col-xxl-6 text-start pe-1">
                                                                <AsyncSelect2
                                                                    name="coupon"
                                                                    className={'bnt-select-menu fs-3 border rounded ' + (values.coupon.value !== 'None'? 'border-primary' : 'border-secondary')} 
                                                                    placeholder=""
                                                                    sourceUrl={ADMIN_ENDPOINT_AVAILABLE_COUPONS}
                                                                    defaultOptions
                                                                    value={values.coupon}
                                                                    default
                                                                    clearAble={true}
                                                                    onChange={(selectedOption: any) => {
                                                                        handleChange({
                                                                            target: { name: 'coupon', value: selectedOption },
                                                                        })
                                                                        handleChange({
                                                                            target: { name: 'use_points', value: 0 },
                                                                        })
                                                                        handleChange({
                                                                            target: { name: 'discount_type', value: 'coupon' },
                                                                        })
                                                                        values.updateDiscount({ 
                                                                            ...values, 
                                                                            discount_type: 'coupon', 
                                                                            coupon: selectedOption,
                                                                            use_points: 0
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                            
                                                            <div className="fs-1 fs-1 col-md-6 col-lg-6 col-xxl-6 text-end ps-1">
                                                                <button
                                                                    className={'btn  btn-lg w-100 ' + (values.discount_type === 'point' ? 'btn-bg-primary' : 'btn-bg-secondary')}
                                                                    disabled={bntPointStatus}
                                                                    type="button"
                                                                    onClick={() => {
                                                                        handleChange({
                                                                            target: { name: 'discount_type', value: 'point' },
                                                                        })
                                                                        handleChange({
                                                                            target: { name: 'use_points', value: 450 },
                                                                        })
                                                                        handleChange({
                                                                            target: { name: 'coupon', value: {label: 'Promotion (0)', value: 'None'} },
                                                                        })
                                                                        values.updateDiscount({ ...values, discount_type: 'point', use_points: 450 });
                                                                    }}
                                                                >Redeem ({(values.use_points === 450?'1':'0')})</button>
                                                            </div>
                                                        
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-12 col-xxl-5 text-end">
                                                        {/* <div className='d-flex border-bottom p-4 justify-content-end'>
                                                            <div className="fs-1 col-md-4 col-lg-4 col-xxl-6 text-start">
                                                                Total
                                                            </div>
                                                            <div className="fs-1 fs-1 col-md-6 col-lg-6 col-xxl-6 text-end">
                                                                ${data?.total}
                                                            </div>
                                                        </div> */}
                                                        {(discount > 0) && (
                                                            <div className='d-flex border-bottom p-4 justify-content-end'>
                                                                <div className="fs-1 col-md-4 col-lg-4 col-xxl-6 text-start">
                                                                    Discount
                                                                </div>
                                                                <div className="fs-1 fs-1 col-md-6 col-lg-6 col-xxl-6 text-end">
                                                                    -${(discount) ? discount : 0.00}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </Card.Text>
                                            </Card.Body>
                                            <Card.Footer className="d-flex align-self-end col-md-6 col-lg-10 col-xxl-6 border-top p-4 me-10">
                                                <div className="fs-1 col-md-6 col-lg-6 col-xxl-6">
                                                    Balanced
                                                </div>
                                                <div className="fs-1 fs-1 col-md-6 col-lg-6 col-xxl-6 text-end">
                                                    {values.earning_points || '0.00'}
                                                </div>
                                            </Card.Footer>
                                        </Card>
                                    </div>
                                    <div className="col-md-12 col-lg-6 col-xxl-6 ">
                                        <Card className="h-100">
                                            <Card.Header className="d-flex align-items-center  flex-wrap">
                                                <div className="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                                                    <div className="symbol-avatar" data-letters={data?.customer?.name ? data?.customer?.name.match(regex)?.join("") : "NA"} ></div>
                                                </div>
                                                <div>
                                                    <a href="#" className="fw-bolder fs-2 text-dark-75 text-hover-primary">{data?.customer?.name} {renderCustomerType(data?.customer?.type || '')}</a>
                                                    <div className="fw-bolder fs-2 text-muted"> <i className="fas fa-phone-alt"></i>&nbsp;{data?.customer?.phone || ''}</div>
                                                    <div className="mt-2">
                                                        Last visit: {(!_.isEmpty(data?.customer?.lastvisit)) ? moment(data?.customer?.lastvisit).format('MM/DD/YYYY h:m A') : ''}
                                                    </div>
                                                </div>
                                            </Card.Header>
                                            <Card.Body className="p-2 py-3">
                                                <Card.Title className='d-flex align-items-center flex-wrap w-100'>
                                                    <div className="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                                                        <div className="d-flex flex-column text-dark-75">
                                                            <span className="fw-bolder fs-sm">Points</span>
                                                            <span className="fw-bolder fs-5">
                                                                <span className="text-dark-50 fw-bold">{customerPoints}</span> Pts</span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                                                        <div className="d-flex flex-column text-dark-75">
                                                            <span className="fw-bolder fs-sm">Visit</span>
                                                            <span className="fw-bolder fs-5">
                                                                <span className="text-dark-50 fw-bold">{data?.customer?.visit_count}</span> Times</span>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                                                        <div className="d-flex flex-column text-dark-75">
                                                            <span className="fw-bolder fs-sm">Check in</span>
                                                            <span className="fw-bolder fs-5">
                                                                <span className="text-dark-50 fw-bold">{(!_.isEmpty(data?.checkin)) ? moment(data?.checkin).format('MM/DD/YYYY h:m A') : ''}</span> </span>
                                                        </div>
                                                    </div>
                                                </Card.Title>
                                                <Card.Text>
                                                    <div className="fv-row flex-row-fluid fv-plugins-icon-container p-0s">
                                                        <Field
                                                            type='text'
                                                            className='form-control form-control-solid checkout-price'
                                                            placeholder=''
                                                            autoComplete='nope'
                                                            name='earning_points'
                                                        />
                                                        <div className='text-danger mt-2'>
                                                            <ErrorMessage name='earning_points' />
                                                        </div>
                                                    </div>
                                                    <div className="fv-row flex-row-fluid fv-plugins-icon-container">
                                                        <Numpad onKeyPress={setPhone} />
                                                    </div>
                                                </Card.Text>
                                                
                                            </Card.Body>
                                            <Card.Footer className="text-muted">
                                                <button className="btn  btn-lg btn-bg-primary w-100" type="submit">CHECKOUT</button>
                                            </Card.Footer>
                                        </Card>
                                    </div>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}

export default CheckOutModal