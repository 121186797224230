import React, { FC } from 'react';
import SlidingPane from "react-sliding-pane";
import InfoIcon from '../asset/icons/info-64.png';
import StorebIcon from '../asset/icons/storeb-128.png';
import StoreOpen from '../asset/icons/time-6-32.png';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../setup';
import * as homeRedux from '../HomeRedux'

  const Infomation: FC = () => {
    const dispatch = useDispatch()
    const paneOpened = useSelector<RootState>(({home}) => home.pane_name, shallowEqual)
    const openPane = (name: string | null ) => {
      if(paneOpened === name){
        name = null ;
      }
      dispatch(homeRedux.actions.changedPane(name));
    }
    return (
      <>
      <div className="icon-task" title="Sapanails Infomation"  onClick={() =>  openPane('information')}>   
          <img src={InfoIcon} className="icon-label"/> 

      </div> 

      <SlidingPane
      className="sapa-panel-sliding info-panel-sliding"
      overlayClassName="sapa-overlay-panel-sliding"
      isOpen={paneOpened === 'information'}
      width="400px"
      hideHeader
      shouldCloseOnEsc  
      from="left"
      onRequestClose={() => {
        // triggered on "<" on left top click or on outside click
        openPane(null);
      }}
      >
      <div className="row">
        <div className="card card-custom gutter-b card-stretch">
          {/* begin::Body */}
          <div className="card-body pt-4 d-flex flex-column justify-content-between">
            
            {/* begin::User */}
            <div className="d-flex align-items-center mb-7">
              {/* begin::Pic */}
              <div className="flex-shrink-0 mr-4 mt-lg-0 mt-3">
                <div className="symbol symbol-lg-75">
                  <img alt="Sapanail Store" src={StorebIcon} />
                </div>
                <div className="symbol symbol-lg-75 symbol-primary d-none">
                  <span className="font-size-h3 fw-boldest">SN</span>
                </div>
              </div>
              {/* end::Pic */}
              {/* begin::Title */}
              <div className="d-flex flex-column px-5">
                <a href="#" className="text-light fw-bold text-hover-primary fs-2 mb-0">Sapanails</a>
                <span className="text-danger fw-bold fs-4">210-691-2323</span>
              </div>
              {/* end::Title */}
            </div>
            {/* end::User */}
            {/* begin::Desc */}
            <p className="mb-7">
            12730 West Interstate 10 Suite 305.  San Antonio, Texas 78230
            </p>
            {/* end::Desc */}
            {/* begin::Info */}
            <div className="mb-7">

            <div className="mb-2">
                <span className="navi-icon mr-2">
                  <span className="svg-icon">
                    <img src={StoreOpen}/>
                  </span>
                </span>
                <span className="text-light fw-bold text-hover-primary fs-2 px-5">OPENING TIME</span>
            </div>
            
              <div className="d-flex justify-content-between align-items-center">
                <span className="text-light fw-bolder mr-2 fs-4">Mon - Sat:</span>
                <span className="text-success fw-bolder  fs-4">10:00 am - 7:30 pm</span>
              </div>
              <div className="d-flex justify-content-between align-items-cente my-1">
                <span className="text-light fw-bolder mr-2 fs-4">Sunday:</span>
                <span className="text-success fw-bolder  fs-4">11:30 am - 5:30 pm</span>
              </div>
              <div className="d-flex justify-content-between align-items-center">
               &nbsp;
              </div>
            </div>
            {/* end::Info */}
            <a href="https://search.google.com/local/writereview?placeid=ChIJqZh1bd5mXIYR2FZsnOH8JDc" target="_blank" className="btn btn-block btn-sm btn-light-danger font-weight-bolder text-uppercase py-4">Write Review</a>
          </div>
          {/* end::Body */}
        </div>
      </div>

      </SlidingPane>
</>
    );
  };
  
  
export default Infomation;


