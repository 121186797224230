import React, { FC, useState } from 'react'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { Toast } from '../../modules/common/helper'
import * as checkinCRUD from '../../pages/checkin-management/checkinCRUD';


import _ from 'lodash'
import CheckCustomerStep from './CheckCustomerStep'
import RatingScreen from './RatingScreen'
import { getMessaging, onMessage } from "firebase/messaging";

import CreateAccStep from './CreateAccStep';
import RatingSuccessScreen from './RatingSuccessScreen';
import { CustomerModel } from '../../models/CustomerModel';
import { BookingModel } from '../../models/BookingModel';
import { CHECK_CUSTOMER_STEP, CREATE_ACCOUNT_STEP, RATING_STEP, RATING_SUCCESS_STEP } from '../../constants/commons';

import {FooterClient} from '../../../_metronic/layout/components/FooterClient';
const CheckinScreen: FC = () => {
    const [loading, setLoading] = useState(false)
    const [step, setStep] = useState(1)
    const [customerData, setCustomerData] = useState<CustomerModel>()
    const [bookingData, setBookingData] = useState<any>()
    const handleCheckCustomer = async (values: any, actions: any) => {
        setLoading(true);
        let result = checkinCRUD.checkCustomer({phone: values.phone});
        result.then(res => {
            setBookingData(res.data?.data?.booking);
            setCustomerData(res.data?.data?.customer);
            //console.log(customerData)
            //(res.data?.data?.acc_create_flg === 1) ? setStep(RATING_SUCCESS_STEP) : setStep(CREATE_ACCOUNT_STEP);
            setStep(RATING_SUCCESS_STEP) 
            setLoading(false);
        }).catch(error => {
            const response = error.response
            let message: string = '';
            if (response?.status === 422) {
                if (response?.data?.errors) {
                    const arrMess = [];
                    for (const [key, mess] of Object.entries(response?.data?.errors)) {
                        arrMess.push(mess);
                    }
                    message = arrMess.join("\n")
                }
            } else {
                message = 'Server error'
            }
            setLoading(false);
            Toast.fire({
                icon: 'error',
                title: message
            })
        });
    };

    const handleCreateAccount = (values: any) => {
        setLoading(true);
        let result = checkinCRUD.createAccount(values);
        result.then(res => {
            setStep(RATING_STEP)
            setLoading(false);
        }).catch(error => {
            const response = error.response
            let message: string = '';
            if (response?.status === 422) {
                if (response?.data?.errors) {
                    const arrMess = [];
                    for (const [key, mess] of Object.entries(response?.data?.errors)) {
                        arrMess.push(mess);
                    }
                    message = arrMess.join("\n")
                }
            } else {
                message = 'Server error'
            }
            setLoading(false);
            Toast.fire({
                icon: 'error',
                title: message
            })
        });
        
    }

    const handleSubmitRating = (rating: number, rating_note: string) => {
        setLoading(true);
        let result = checkinCRUD.postRating(bookingData?.id, rating, rating_note);
        result.then(res => {
            setStep(RATING_SUCCESS_STEP)
            setLoading(false);
        }).catch(error => {
            const response = error.response
            let message: string = '';
            if (response?.status === 422) {
                if (response?.data?.errors) {
                    const arrMess = [];
                    for (const [key, mess] of Object.entries(response?.data?.errors)) {
                        arrMess.push(mess);
                    }
                    message = arrMess.join("\n")
                }
            } else {
                message = 'Server error'
            }
            setLoading(false);
            Toast.fire({
                icon: 'error',
                title: message
            })
        });
        
    }

    const handlePreviousStep = () => {
        setStep(step - 1)
    }

    const handleBack = () => {
        setStep(CHECK_CUSTOMER_STEP)
    }

    const formContent = () => {
        let content = <CheckCustomerStep handleCheckCustomer={handleCheckCustomer} />
        if (step === CREATE_ACCOUNT_STEP) {
            content = <CreateAccStep
                data={customerData}
                onSubmit={handleCreateAccount}
                handleBack={handleBack}
            />
        }
        if (step === RATING_STEP) {
            content = <RatingScreen 
                handleSubmitRating={handleSubmitRating} 
                handlePreviousStep={handlePreviousStep}
                handleBack={handleBack}
                customer={customerData}
                booking={bookingData}
            />
        }
        if (step === RATING_SUCCESS_STEP) {
            content = <RatingSuccessScreen 
            handlePreviousStep={handlePreviousStep}
            customer={customerData}
            handleBack={handleBack}
            />
        }
        return content
    }
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
        let customer = JSON.parse(payload?.data?.customer ?? '');
        let booking = JSON.parse(payload?.data?.booking ?? '');
        if (!_.isEmpty(customer)) {
            setCustomerData(customer);
        }
        if (!_.isEmpty(booking)) {
            setBookingData(booking);
        }/* 
        if (step === CHECK_CUSTOMER_STEP) {
            setStep(RATING_STEP)
        } */
    });
    return (
        <div
            className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
            style={{
                backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
            }}
        >
            {/* begin::Content */}
            <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
                {/* begin::Logo */}
                <a href='#' className='mb-12'>
                    <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo.png')} className='h-45px' />
                </a>
                {/* end::Logo */}
                {/* begin::Wrapper */}
                <div className='w-lg-500px bg-white rounded shadow-sm p-5 p-lg-10 mx-auto'>
                    {formContent()}
                </div>
                {/* end::Wrapper */}
                <FooterClient />
                
                
            </div>
            {/* end::Content */}
        </div>
    )
}

export default CheckinScreen
