import { ErrorMessage, Field, Form, Formik } from 'formik'
import { FC } from 'react'
import * as Yup from 'yup'
import Numpad from 'react-numberpad';
import {KTSVG} from '../../../_metronic/helpers'

const step1Schema = Yup.object().shape({
    phone: Yup.number().required().label('Phone number'),
})

type Props = {
    handleCheckCustomer: (values: any, actions: any) => void
}
const CheckCustomerStep: FC<Props> = ({handleCheckCustomer}) => {
    let step1InitValues: any = {
        phone: ''
    };
    
    return (
        <>
            <div className="card card-flush h-lg-100" id="kt_contacts_main">
                <div className="card-header pt-7" id="kt_chat_contacts_header">
                    <div className="card-title">
                        <span className="svg-icon svg-icon-1 me-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-phone" viewBox="0 0 16 16">
                            <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"/>
                            <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                        </svg>
                        </span>
                        <h2>Sapa Nail's Customers Check-in</h2>
                    </div>
                </div>
                

                <div className="card-body pt-5">
                    
                <Formik validationSchema={step1Schema} initialValues={step1InitValues} onSubmit={handleCheckCustomer}>
                    {({ values, handleChange, setFieldValue }) => {
                        const setPhone = (numText:any) => {
                                let phone = values.phone;
                                if (numText === '<') {
                                    phone = phone.slice(0, -1);
                                    if (!phone) {
                                        phone = '';
                                    }
                                } else if (numText === 'X') {
                                    phone = '';
                                } else {
                                    if (phone === '0') {
                                        phone = '';
                                    }
                                    phone += numText;
                                }             
                                setFieldValue("phone", phone);
                        }
                        return (
                            <Form>
                                <div className="d-flex flex-column flex-md-row gap-5">  
                                    <div className="fv-row flex-row-fluid fv-plugins-icon-container">
                                        <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                            <span className='required'>Your phone number</span>
                                        </label>
                                        <Field
                                            type='text'
                                            className='form-control form-control-solid'
                                            placeholder=''
                                            autoComplete='nope'
                                            name='phone'
                                        />
                                        <div className='text-danger mt-2'>
                                            <ErrorMessage name='phone' />
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="d-flex flex-column flex-md-row gap-5">                            
                                    <div className="fv-row flex-row-fluid fv-plugins-icon-container">
                                        <Numpad onKeyPress={setPhone} />
                                    </div>
                                </div>
                                <div>&nbsp;</div>

                                <div className="d-flex flex-column bd-highlight mb-3">
                                    <div className="p-2 bd-highlight"> <button className="btn  btn-lg btn-bg-danger text-light w-100" type="submit">Check-In</button></div>
                                    <div className="p-2 bd-highlight d-flex justify-content-between">
                                        <div className="bd-highlight w-40px">
                                            <label className="checkbox checkbox-lg checkbox-light-success checkbox-inline flex-shrink-0 align-middle">
                                                <input type='checkbox' className='form-check-input mt-0 me-2' defaultChecked={true}/>
                                            </label>
                                        </div>
                                        <div className="bd-highlight text-justify fs-8  me-2">
                                            By checking this box and clicking "Check-In", you give Sapa Nails, 
                                            express written consent to contact you at the number entered for booking reminders or promotional purposes.  
                                            Consent is not required to check in or make a purchase.
                                            You also agree to the <a href="#" className="link"  data-bs-toggle="modal" data-bs-target="#ModalPrivacy">Terms and Conditions / Privacy Policy</a> and Store policy.
                                        </div>
                                    </div>
                                    
                                </div>

                                
                            </Form>                            
                        )
                    }}
                </Formik>
                </div>
            </div>
            
            <div className="modal fade" id="ModalPrivacy" tabIndex={-1} role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Privacy and terms &amp; conditions</h5>
                            
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                                </div>

                        </div>
                        <div className="modal-body" style={{height: '300px'}}>
                        <h4>Sapa Nails Policies</h4><br /><br />
<strong>OUR MISSION</strong><br />
It is our mission at Sapa Nails to provide all our guests with a remarkable experience while
performing our business with integrity and deep knowledge of our profession. We are
committed to providing superior service to each individual guest with added value. Through our
friendly customer service and life long education, we will strive to be your number one choice
for all your NAIL CARE needs.<br /><br />

<strong>CANCELLATIONS</strong><br />
Appointments can be cancelled 2 hours in advance by calling (210) 691-2323. Please give at
least 2 hours notice so that we have the opportunity to offer your reserved time to another client
on our waiting list. We understand that sometimes emergencies happen, and it’s not always
possible to give adequate notice when you can’t make an appointment. However, be aware that
if you repeatedly cancel appointments without proper notice, we reserve the right to ask for a
non-refundable deposit to secure your next appointment.
We ask that you please reschedule or cancel at least 2 hours before the beginning of your
appointment.<br /><br />

<strong>LATE ARRIVALS</strong><br />
Tardiness Scheduled appointments have a 15 minute grace period to allow for unpredicted
traffic or parking difficulties. If you anticipate that you will be later than 15 minutes, please call
ahead to see if we have the availability to complete your service in full. One late client can throw
off our entire schedule and cause our other clients to be late for their commitments. Therefore,
if you arrive more than 15 minutes late, we reserve the right to refuse partial or complete
services.<br /><br />

<strong>NO SHOWS</strong><br />
If you make an appointment that you don’t cancel and don’t show up for, we reserve the right to
charge a service fee if it happens more than once.<br /><br />

<strong>REFUNDS</strong><br />
If you are not happy with your nails, please make us aware of it before you pay. We will adjust
them to your satisfaction or remove any enhancements or coatings that have been applied. No
refunds will be given after you have left the salon.<br /><br />

<strong>COMPLIMENTARY FIXES</strong><br />
If you are ever dissatisfied with any service in the salon, we will gladly make the corrections<br /><br />

necessary to exceed your expectations within 3 days of your original services. No refund is
given after it has been rendered.
– Enhancements and gel polish manicures are guaranteed for 5 business days after your
appointment; excluding breakages. If you lose an enhancement or notice chips or lifting in the
first 5 days, please call us to schedule a free repair. (Repairs must be arranged within 3 days of
notification to the salon to be considered complimentary fixes.) Repairs after 5 days or for
breakages, tears, and corner breaks without notifications are $10+ each. (Please remember,
nails are jewels, not tools. Be kind to your nails and they will look beautiful for weeks after your
service.)<br />
-Traditional Nail polish services are not guaranteed.
However we would be glad to fix them for you. Must be called in within 24-48 hours after initial
service and schedule an appointment for nail fix within 2 days.
Please DO NOT EMAIL for fixes. Please call the salon and leave a voice message if no one
picks up. We would like to quickly serve you as soon as possible. If you do not contact us within
3 days, you waive the right to file any dispute or legal issues for any potential dissatisfaction or
injuries that may have been caused by Sapa Nails.<br /><br />

<strong>CHILD SAFETY</strong><br />
In order to provide a comfortable and relaxing atmosphere children are only permitted in the
salon if they are being seen by one of our Nail Technicians. For safety reasons and insurance
purposes, NO child under the age of 12 may accompany you while you are having a service. We
do not want to compromise you and our other guests experience and kindly ask that you make
your child care arrangements prior to your scheduled appointment. We do not have the facilities
to care for children and will not provide child supervision. We cannot assure their safety in a
professional environment. YOUR UNDERSTANDING IS GREATLY APPRECIATED.<br /><br />

<strong>PETS</strong><br />
Pets of any kind, other than service animals, are NOT allowed in the salon. The State Board of
Cosmetology have specific Rules and Regulations stating cleanliness and sanitation, and we
abide by these rules. In order to protect our clients that are more susceptible to allergens, NO
animals of any kind are to be permitted in the salon at any time. Service dogs with the official
“Service Animal” vests are the ONLY exception. Therapy dogs, emotional support dogs and/or
companion dogs are not recognized as “Service Animals” according to The Texas State Human
Rights Commission. We invite you to talk with us about any questions or concerns.<br /><br />

<strong>CELL PHONES</strong><br />
Sapa Nails is a place for our clients to relax. Please check to see if your phone is on vibrate or
silent. We ask kindly that if you need to make a phone, call please step outside. If you need to
take an important phone call, please let us know in advance before your service. Keep in mind
this may cut into your service time. We also ask that while your Nail Technician is working with<br />

you, you do not use your cell phone to talk to someone. It makes it difficult for them to do their
job up to the standards. Thank you!<br /><br />

We Reserve the Right to Refuse Service To:<br />
Anyone with a nail condition we suspect may be contagious<br />
Anyone with open or infected wounds on the treatment area<br />
Anyone in ill health that we may suspect may be contagious, or we fear could be harmed by our
service<br />
Anyone more than 10-15 minutes late for an appointment<br />
Anyone demonstrating inappropriate behavior to our nail technicians and other customers 
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light-primary font-weight-bold" data-bs-dismiss="modal">Close</button>                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CheckCustomerStep
