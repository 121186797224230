/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Modal } from 'react-bootstrap-v5'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import Flatpickr from 'react-flatpickr'
import moment from 'moment';
import { CouponModel } from '../../models/CouponModel'

type Props = {
    show: boolean
    mode: string
    data?: CouponModel
    onClose: () => void
    onSubmit: (values: any, actions: any) => void
}
const addSchema = Yup.object().shape({
    code: Yup.string().required().label('Coupon Code'),
    start_date: Yup.date().nullable().label('Start Date'),
    expired_date: Yup.date().nullable().min(
        Yup.ref('start_date'), 
        'Expired date should less after Start date'
    ).label('Expired Date')
})

const editSchema = Yup.object().shape({
    code: Yup.string().required().label('Coupon Code'),
    start_date: Yup.date().nullable().label('Start Date'),
    expired_date: Yup.date().nullable().min(
        Yup.ref('start_date'), 
        'Expired date should less after Start date'
    ).label('Expired Date')
})

const CouponModal: React.FC<Props> = ({ show, mode, data, onClose, onSubmit }) => {
    let initValues: any = {
        code: '',
        start_date: null,
        expired_date: null,
    };
    if (mode === 'edit' && data) {
        initValues = {
            id: data?.id ?? null, 
            code: data?.code ?? '',
            start_date: data?.start_date ?? null,
            expired_date: data?.expired_date ?? null,
        };
    }
    return (
        <Modal
            show={show}
            onHide={onClose}
            aria-labelledby='contained-modal-title-vcenter'
            centered
        >
            <Modal.Header className='d-flex'>
                <Modal.Title>{mode === 'edit' ? 'Edit Coupon' : 'Add Coupon'}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-10">
                <Formik validationSchema={mode === 'edit' ? editSchema : addSchema} initialValues={initValues} onSubmit={onSubmit}>
                    {({ values, handleChange }) => (
                        <Form>
                            <div className="d-flex flex-column gap-5">   
                                <div className="fv-row flex-row-fluid fv-plugins-icon-container">
                                    <label className="required form-label">Coupon code</label>
                                    <Field
                                        type='text'
                                        className='form-control'
                                        placeholder=''
                                        autoComplete='nope'
                                        name='code'
                                    />
                                    <div className="fv-plugins-message-container invalid-feedback"><ErrorMessage name='phone' /></div>
                                </div>   

                                <div className="fv-row flex-row-fluid fv-plugins-icon-container">
                                    <label className="form-label">Start Date</label>
                                    <Flatpickr
                                        className='form-control'
                                        name='start_date'
                                        value={values.start_date}
                                        onChange={start_date => {
                                            handleChange({
                                                target: { name: 'start_date', value: moment(start_date[0]).format('YYYY-MM-DD') },
                                            })
                                        }}
                                        options={{
                                            altInput: true,
                                            dateFormat: 'Y-m-d',
                                            altFormat: "m-d-Y",
                                            enableTime: false
                                        }}
                                    />
                                    <div className='text-danger mt-2'>
                                        <ErrorMessage name='start_date' />
                                    </div>
                                </div>
                                <div className="fv-row flex-row-fluid fv-plugins-icon-container">
                                    <label className="form-label">Epired Date</label>
                                    <Flatpickr
                                        className='form-control'
                                        name='expired_date'
                                        value={values.expired_date}
                                        onChange={expired_date => {
                                            handleChange({
                                                target: { name: 'expired_date', value: moment(expired_date[0]).format('YYYY-MM-DD') },
                                            })
                                        }}
                                        options={{
                                            altInput: true,
                                            dateFormat: 'Y-m-d',
                                            altFormat: "m-d-Y",
                                            enableTime: false
                                        }}
                                    />
                                    <div className='text-danger mt-2'>
                                        <ErrorMessage name='expired_date' />
                                    </div>
                                </div>
                            </div>
                           
                            <div className='d-flex mb-4 justify-content-between'>
                                <button className="btn btn-bg-secondary" type="button" onClick={() => onClose()}>Close</button>
                                <button className="btn btn-bg-success" type="submit">{mode === 'edit' ? 'Update' : 'Add New'}</button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}

export default CouponModal
