import React from 'react';
import moment from 'moment';
import { CouponModel } from '../../models/CouponModel';
import { CustomerModel } from '../../models/CustomerModel';
import _ from 'lodash';
import { THSort } from '../../../_metronic/partials';
import { ACTIVE, INACTIVE } from '../../constants/commons';

type Props = {
    data: Array<CustomerModel>,
    onEdit: (customer: CustomerModel) => void,
    handleSortClick: (name: string, type_sort: string) => void,
    onDelete: (customer: CustomerModel) => void,
    type_sort?: string,
    sort_column?: string,
    handleClickAll: (e: any) => void, 
    isCheckedAll: boolean,
    handleClick: (e: any) => void,
    isChecked: Array<number>,
    toggleStatus: (customer: CustomerModel) => void,
};


const CustomerTable: React.FC<Props> = ({ 
    data, 
    onEdit, 
    onDelete, 
    handleSortClick,
    handleClickAll, 
    isCheckedAll,
    handleClick,
    isChecked,
    type_sort,
    sort_column,
    toggleStatus
}) => {
    const renderCustomerType = (type: string) => {
        let typeClass = "success";
        type = type.toLowerCase();
        switch (type){
            case 'new':
                typeClass = "success"
                break;
            case 'silver': 
                typeClass = "primary"
                break;
            case 'gold': 
                typeClass = "info"
                break;
            case 'platinum': 
                typeClass = "warning"
                break;
            case 'vip': 
                typeClass = "danger"
                break;
            default:
                typeClass = "success"
                
        }
        return <span className={"badge badge-light-" + typeClass}>{type}</span>
    }
    return (
        <div className='table-responsive'>
            <table className="table table-hover table-rounded table-striped border gy-7 gs-7">
                <thead>
                    <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                        <th>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input
                                    className='form-check-input'
                                    type='checkbox'
                                    value='1'
                                    onChange={handleClickAll}
                                    checked={isCheckedAll}
                                />
                            </div>
                        </th>
                        <THSort handleSortClick={handleSortClick} name='name' sort_column={sort_column || ''} type_sort={type_sort || ''}>Customer name</THSort>
                        <th>Point/Life points</th>
                        <th>Visit counts</th>
                        <THSort handleSortClick={handleSortClick} name='lastvisit' sort_column={sort_column || ''} type_sort={type_sort || ''}>Last visit</THSort>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {data.length > 0 && data.map((item: CustomerModel, index: number) => (
                        <tr key={item.id}>
                            <td>
                                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                    <input 
                                        className='form-check-input widget-9-check' 
                                        type='checkbox' 
                                        value='1' 
                                        id={(item.id).toString()}
                                        onChange={handleClick}
                                        checked={isChecked.includes(item.id)} />
                                </div>
                            </td>
                            <td>
                                {item.name || ''} {renderCustomerType(item.type)} 
                                <span className="text-muted font-weight-bold d-block">
                                    <i className="fas fa-phone-alt"></i>&nbsp;{item.phone || ''}
                                </span>
                                <span className="text-muted font-weight-bold d-block"><i className="fas fa-envelope"></i>&nbsp;{item.email || ''}</span>
                            </td>
                            <td><span className={"badge badge-light-success"}>{item.points || 0}</span>/<span className={"badge badge-light-primary"}>{item.life_point || 0}</span></td>
                            <td>{item.visit_count || 0}</td>
                            <td>{(!_.isEmpty(item.lastvisit)) ? moment(item.lastvisit).format('MM/DD/YYYY h:m A') : ''}</td>
                            <td>
                                {(item.active_status === INACTIVE) ? (
                                    <button className="btn btn-icon btn-danger w-30px h-30px ps-2 me-2" onClick={() => toggleStatus({...item, active_status: ACTIVE})}  data-bs-toggle="tooltip" data-bs-placement="top" data-bs-theme="dark" title='Click to change Active customer groups'>
                                        <i className="bi bi-lock fs-4 me-2"></i>
                                    </button>
                                ) : (
                                    <button className="btn btn-icon btn-success w-30px h-30px ps-2 me-2" onClick={() => toggleStatus({...item, active_status: INACTIVE})}  data-bs-toggle="tooltip" data-bs-placement="top" data-bs-theme="dark" title='Click to change In-active customer groups'>
                                        <i className="bi bi-unlock fs-4 me-2"></i>
                                    </button>
                                )}
                                <button className="btn btn-icon btn-warning w-30px h-30px ps-2 me-2" onClick={() => onEdit(item)}>
                                    <i className="bi bi-pencil-square fs-4 me-2"></i>
                                </button>
                                <button className="btn btn-icon btn-danger w-30px h-30px ps-2" onClick={() => onDelete(item)}>
                                    <i className="bi bi-trash fs-4 me-2"></i>
                                </button>
                            </td>
                        </tr>    
                    ))
                    }
                </tbody>
            </table>
        </div>
    );
}

export default CustomerTable;