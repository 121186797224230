/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Chart from "react-apexcharts";
import React from 'react'
import _ from "lodash";

type Props = {
    chartColor: Array<any>,
    bookingData: any,
}

const Last7DayBookingsChart: React.FC<Props> = ({ chartColor, bookingData }) => {
    return (
        <Chart
            options={{
                chart: {
                    type: 'bar',
                    height: 500
                },
                colors: chartColor,
                plotOptions: {
                    bar: {
                        columnWidth: '45%',
                        distributed: true,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                legend: {
                    show: false
                },
                xaxis: {
                    categories: _.keys(bookingData),
                    labels: {
                        style: {
                            colors: [],
                            fontSize: '12px'
                        }
                    }
                },
                yaxis: {
                    
                }
              
            }}
            series={[{
                name: "New bookings",
                data: _.values(bookingData)
            }]}
            type="bar"
            height="350"
        />
    )
}

export { Last7DayBookingsChart }
