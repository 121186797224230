import React, { FC,useState } from 'react';
import * as luxon from 'luxon';
import * as _ from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../setup';
import * as homeRedux from '../HomeRedux'

  const TopNavigation: FC = () => {
    const timeFormat = 'hh:mm a';
    const dateFormat = 'cccc, LLL dd';
    const [dateTime, setDateTime] = useState<luxon.DateTime>(
      luxon.DateTime.local().setZone("America/Chicago")
    );

    const dispatch = useDispatch()
    const paneOpened = useSelector<RootState>(({ home }) => home.pane_name, shallowEqual)
    const openPane = (name: string | null) => {
        if (paneOpened === name) {
            name = null;
        }
        dispatch(homeRedux.actions.changedPane(name));
    }

    return (
      <>
      <div className="topnav-container px-5">
        <div className='topnav-logo logo'>
          <a href="https://sapanails.com" target="_blank" rel="noreferrer"><img src={"/media/logos/logo.png"} className='img' alt='Sapanails '/></a>
        </div>

        <div className='topnav-time'>
        {dateTime.toFormat(timeFormat)}<br/>
        {dateTime.toFormat(dateFormat)}
        </div>
      </div>
      {/* onClick={() => openPane('booking')}  */}
      <div className="topnav-center px-5">       
        <button type="button"  onClick={() => openPane('booking')} className="btn btn-warning fw-bolder mr-6 px-6 fs-sm d-flex justify-content-center  align-items-center">
          <i className="bi bi-calendar-date"></i>&nbsp;<span className="d-none d-sm-block">Book online</span><span className="d-block d-sm-none">Book</span></button>

          {/* <button type="button"  onClick={() => openPane('promotion')} className="btn btn-warning fw-bolder mr-6 px-6 fs-sm d-flex justify-content-center  align-items-center ms-3">
          <i className="bi bi-newspaper"></i>&nbsp;<span className="d-none d-sm-block">New Promotions</span><span className="d-block d-sm-none">Event</span></button> */}
      </div>
      </>
    );
  };
  
  
export default TopNavigation;