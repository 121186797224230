/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import _ from 'lodash';
import queryString from 'query-string'
import { Link, useHistory } from 'react-router-dom';
import * as reportCRUD from './ReportCRUD';
import { reportCheckOutModel, SearchModel, reportCusGroupModel, reportCusLevelModel } from '../../models/ReportModel';
import { DATA_PAGINATION_LENGTH, Paginate, PaginationLength } from '../../../_metronic/partials';
import { PaginateModel, ReportPaginateModel } from '../../models/PaginateModel';
import { BookingModel } from '../../models/BookingModel';
import BookingTable from './BookingTable';
import { Widgets } from './Widgets';
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers';
import Chart from "react-apexcharts";
import { CustomerLevelChart } from './CustomerLevelChart';
import { Last7DayBookingsChart } from './Last7DayBookingsChart';

const Dashboard: React.FC = () => {
    const history = useHistory();

    const definedDates: any[] = [
        {
            label: 'Today',
            value: 'today'
        },
        {
            label: 'Yesterday',
            value: 'yesterday'
        },
        {
            label: 'In 30 days',
            value: 'in_30_days'
        },
        {
            label: 'Current Week',
            value: 'current_week'
        },
        {
            label: 'Last Week',
            value: 'last_week'
        },
        {
            label: 'Current Month',
            value: 'current_month'
        },
        {
            label: 'Last Month',
            value: 'last_month'
        },
        {
            label: 'Current Year',
            value: 'current_year'
        },
        {
            label: 'Last Year',
            value: 'last_year'
        }
    ]

    const [search, setSearch] = useState<SearchModel>({ predefined_date: 'today' })
    const [dataPaginate, setDataPaginate] = useState<ReportPaginateModel<BookingModel>>();
    const [widgetsData, setWidgetData] = useState<{ check_in: number, check_out: reportCheckOutModel }>();
    const [last7DChartData, setLast7DChartData] = useState<any>();
    const [customersData, setCustomersData] = useState<{ cusGroup: any, cusLevel: Array<reportCusLevelModel>, labelLv: Array<any>, serialLv: Array<any> }>({
        cusGroup: {},
        cusLevel: [],
        serialLv: [],
        labelLv: []
    });
    useEffect(() => {
        const initParams: SearchModel = parseParams(queryString.parse(history.location.search))
        getData({ ...search, ...initParams });
    }, []);

    const handleSearch = async (event: any) => {
        getData({ ...search, predefined_date: event.target.value });
    }

    const parseParams = (params: SearchModel) => {
        if (params.page) {
            params.page = +params.page
        }
        if (params.per_page) {
            params.per_page = +params.per_page
        }
        return params
    }

    const getData = async (params: SearchModel) => {
        const { data: reportData } = await reportCRUD.getReportData(params);
        const objQuery = { ...params }
        if (objQuery.page === 1) {
            delete objQuery.page
        }
        if (objQuery.per_page === DATA_PAGINATION_LENGTH[0]) {
            delete objQuery.per_page
        }
        history.push({ search: `?${queryString.stringify(objQuery)}` })
        setDataPaginate(reportData.list);
        setWidgetData({
            check_in: reportData.check_in,
            check_out: reportData.check_out

        })
        setLast7DChartData(reportData.last_7_days_bookings)
        setCustomersData({
            cusGroup: reportData.customer?.customer_status,
            cusLevel: reportData.customer?.customer_types,
            labelLv: reportData.customer?.label_type,
            serialLv: reportData.customer?.serial_type
        })
        setSearch(params);
    }

    const handlePageChange = (data: any) => {
        getData({ ...search, page: data.selected + 1 })
    }

    const handlePageLengthChange = (e: any) => {
        getData({ ...search, page: 1, per_page: parseInt(e.target.value) })
    }

    const chartColor = ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#5292B0'];
    const sumTotal = (dataArr: Array<number>): number => {
        let initialValue = dataArr.reduce(function (accumVariable, curValue) {
            return accumVariable + curValue
        }, 0);
        return initialValue;
    }


    return (
        <>
            <div className='card mb-5 mb-xl-10'>
                <div className='card-body pt-9 pb-0'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-4'>
                                    <div className='position-relative'>
                                        <img className='mw-100' src={toAbsoluteUrl('/media/logos/logo.png')} alt='Sapa Nails' />
                                    </div>
                                </div>
                                <div className='col-lg-8'>
                                    <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                                        <div className='d-flex flex-column'>
                                            <div className='d-flex align-items-center mb-2'>
                                                <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                                                    Sapa Nails
                                                </a>
                                            </div>
                                            <div className='d-flex flex-wrap fw-bold fs-6 mb-0 pe-2'>
                                                <a
                                                    href='#'
                                                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                                                >
                                                    <KTSVG
                                                        path='/media/icons/duotune/general/gen018.svg'
                                                        className='svg-icon-4 me-1'
                                                    />
                                                    San Antonio, Texas 78230

                                                </a>
                                                <a
                                                    href='#'
                                                    className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                                                >
                                                    <KTSVG
                                                        path='/media/icons/duotune/communication/com011.svg'
                                                        className='svg-icon-4 me-1'
                                                    />
                                                    info@sapanails.xyz
                                                </a>
                                            </div>
                                            <div className='d-flex flex-wrap'>
                                                At Sapa Nails, We also require our staff to keep everything clean and welcome customers with friendly and smiley faces.
                                            </div>

                                        </div>

                                    </div>

                                </div>
                                <div className='col-md-12 mt-10'>
                                    <Widgets
                                        predefined_date={_.find(definedDates, ['value', search.predefined_date])?.label ?? 'Today'}
                                        booking_count={dataPaginate?.total}
                                        check_in_count={widgetsData?.check_in}
                                        check_out={widgetsData?.check_out}
                                    />
                                </div>

                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-lg-8'>
                                    <CustomerLevelChart
                                        chartColor={chartColor}
                                        customersData={customersData}
                                    />
                                </div>
                                <div className='col-lg-4'>
                                    <div className="text-undefined fw-bold fs-1 mb-0 mt-0 text-success">{sumTotal(customersData.serialLv)}</div>
                                    <div className="text-muted fw-bold mb-2">Customers</div>
                                    {/* begin::Item */}
                                    {customersData.cusLevel && customersData.cusLevel.map((item: reportCusLevelModel, index: number) => (
                                        <div className='d-flex align-items-sm-center' key={index}>
                                            <div className='summary-color'>
                                                <span className='symbol-label' style={{ backgroundColor: chartColor[index] }}>
                                                </span>
                                            </div>
                                            <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                                                <div className='flex-grow-1 me-2'>
                                                    <span className='text-muted fw-bold d-block fs-7'>{item.type}</span>
                                                </div>
                                                <span className='my-2'>{item.total}</span>
                                            </div>
                                        </div>

                                    ))
                                    }
                                    {/* end::Item */}

                                    <div className='d-flex flex-wrap justify-content-center my-5'>
                                        <div className="d-flex align-items-center mr-10">
                                            <div className="mx-2">
                                                <div className="fw-bold mb-2">Active</div>
                                                <span className="btn btn-sm btn-text btn-light-primary text-uppercase fw-bold w-100">{customersData.cusGroup.total_active || 0}</span>
                                            </div>
                                            <div className="mx-2">
                                                <div className="fw-bold mb-2">In-Active</div>
                                                <span className="btn btn-sm btn-text btn-light-danger text-uppercase fw-bold w-100">{customersData.cusGroup.total_inactive || 0}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* <div className="separator separator-solid my-7"></div>
                    <Widgets
                        predefined_date={_.find(definedDates, ['value', search.predefined_date])?.label ?? 'Today'}
                        booking_count={dataPaginate?.total}
                        check_in_count={widgetsData?.check_in}
                        check_out={widgetsData?.check_out}
                    />              */}
                    <div className="mt-7"></div>

                </div>
            </div>
            <div className='row'>
                <div className='col-lg-8'>
                    <div className="card mb-10">
                        <div className="card-header py-4">
                            <div className="col-md-12 d-flex mb-4 ">
                                <div className="col-md-4">
                                    <label className="form-label">Predefined Dates</label>
                                    <select
                                        className="form-select"
                                        name="predefined_date"
                                        value={search.predefined_date}
                                        onChange={(e) => {
                                            handleSearch(e)
                                        }}
                                    >
                                        {definedDates.map((item, index) => (
                                            <option key={index} value={item?.value}>{item?.label}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-auto ms-auto">
                                    <div
                                        className="btn btn-success mt-8"
                                    >
                                        <i className="bi bi bi-plus-lg fs-4"></i><span>CSV Export</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <h3>New Booking {_.find(definedDates, ['value', search.predefined_date])?.label ?? 'Today'}</h3>
                            <BookingTable
                                data={dataPaginate?.data || []}
                            />
                        </div>
                        <div className="card-footer d-flex justify-content-between">
                            <PaginationLength selected={search.per_page || DATA_PAGINATION_LENGTH[0]} onChange={handlePageLengthChange} />
                            <Paginate
                                pageCount={dataPaginate?.last_page || 0}
                                onPageChange={handlePageChange}
                                forcePage={(search.page || 1) - 1}
                            />
                        </div>
                    </div>
                </div>
                <div className='col-lg-4'>
                    <div className="card mb-10">
                            <div className="card-body">
                                <h3>Last 7 days bookings</h3>
                                <Last7DayBookingsChart
                                    chartColor={chartColor}
                                    bookingData={last7DChartData}
                                />
                            </div>
                        </div>
                </div>
            </div>
        </>
    )
}

export { Dashboard }
