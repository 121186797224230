import React, { FC,useMemo } from 'react';
//import { connect } from 'react-redux'


const Background: FC = () => {
  return (
    <div className='background'>
      <div className='background-image' style={{backgroundImage:"url('/media/background.jpg')"}} />
      <div className='background-overlay' />
      <div className='background-footer' />
    </div>
  );
};


export default Background;
