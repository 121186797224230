import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import { CHECKIN_SCREEN_ROUTE } from '../constants/routes'
import CheckinScreen from '../modules/checkinscreen/CheckinScreen'

export function CheckinRoutes() {

  return (
    <Switch>
        <Route path={CHECKIN_SCREEN_ROUTE} component={CheckinScreen} />
        <Redirect to='/' />
    </Switch>
  )
}
